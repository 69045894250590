<template>
    <div class="settings-metaquery">
        <div class="mb-8">
            <v-btn
            text
            small
            plain
            @click="$router.go(-1)">
                <v-icon left>chevron_left</v-icon>
                <span>Indietro</span>
            </v-btn>
            <h2>Meta query della risorsa {{ $route.params.model }}</h2>
            <v-divider class="my-8"></v-divider>
        </div>

        <v-card class="pa-0">
            <bxs-data-table
            ref="table"
            model-name="MetaQuery"
            resource="metaqueries"
            :to-create-item="`/settings/metaqueries/${$route.params.model}/new`"
            :to-update-item="`/settings/metaqueries/${$route.params.model}`"
            id="table-settings-metaqueries-view"
            :headers="[
                { text: 'Nome', value: 'name' },
                { text: 'Entità', value: 'model' },
                { text: 'Campo', value: 'field' },
                { text: 'Condizione', value: 'operator' },
                { text: 'Entità legata', value: 'comparison.model' },
                { text: 'Dove è usato', value: '' }
            ]"
            :static-filters="{
                model: '=|' + $route.params.model
            }">
                <template #item.field="{ value }">{{ value || '--' }}</template>
                <template #item.operator="{ item }">{{ item.operator }} {{ item.comparison.value }}</template>
            </bxs-data-table>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'settings_metaquery'
}
</script>
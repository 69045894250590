var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['category-field', {
    'mb-3': !_vm.hideDetails,
    'theme--dark': _vm.$vuetify.theme.isDark,
    'theme--light': !_vm.$vuetify.theme.isDark
}]},[_c('p',{staticClass:"bxs-input-label mb-1"},[_vm._v(_vm._s(_vm.label))]),_c('tree-select-field',{attrs:{"options":_vm.tree,"placeholder":_vm.placeholder,"name":'tree_' + Math.random(),"disabled":_vm.disabled,"required":_vm.required,"no-results-text":"Nessun elemento trovato","no-options-text":"Nessun elemento disponibile al momento","no-children-text":"Nessuna sottocategoria disponibile al momento","show-count":"","searchable":false,"flat":"","multiple":_vm.multiple,"clearable":_vm.clearable},scopedSlots:_vm._u([(!_vm.hideCreate)?{key:"before-list",fn:function(){return [_c('v-divider'),_c('div',[_c('v-btn',{attrs:{"text":"","block":"","color":"info"},on:{"click":function($event){return _vm.setForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_c('span',[_vm._v("Aggiungi nuova categoria")])],1)],1),_c('v-divider')]},proxy:true}:null,{key:"value-label",fn:function(ref){
var node = ref.node;
return [_c('span',[_vm._v(_vm._s(node.raw.path))])]}},{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
var countClassName = ref.countClassName;
return [_c('label',{class:labelClassName},[_c('span',[_vm._v(_vm._s(node.raw.name))]),(shouldShowCount)?_c('span',{class:countClassName},[_vm._v("("+_vm._s(count)+")")]):_vm._e()])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card class="mb-4 pa-0 truncated">
        <slot name="prepend" />

        <div class="d-flex flex-nowrap pa-4">
            <div class="pr-2">
                <v-avatar
                rounded
                size="50">
                    <v-img :src="local_src || '/img/no-photo.png'" />
                </v-avatar>
            </div>

            <div class="truncated">
                <p
                v-if="docName"
                class="mb-1">{{ docName }}</p>

                <div>
                    <a
                    v-if="permalink"
                    :href="permalink"
                    target="_blank"
                    class="ma-0 truncated"
                    style="display: block; color: grey; text-decoration: none">{{ permalink }}</a>

                    <a
                    v-if="permalink"
                    role="button"
                    class="ma-0 pa-0 info--text"
                    style="display: block; text-decoration: none"
                    @click="copy">
                        <span>Copia link</span>
                        <v-icon right color="info" size="15">content_copy</v-icon>
                    </a>
                </div>

                <div>
                    <p class="ma-0 truncated">
                        <span>{{ title || '' }}</span>
                    </p>

                    <p class="ma-0 truncated">
                        <span>{{ description || '' }}</span>
                    </p>
                </div>

                <slot />
            </div>
        </div>

        <slot name="append" />
    </v-card>
</template>

<script>
export default {
    name: 'bxs_page_details',
    props: {
        'doc-name': {
            type: String,
            required: false,
            default: null
        },
        src: {
            type: String,
            required: false,
            default: null
        },
        permalink: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        description: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            local_src: null
        }
    },
    created () {
        this.getSrc()
    },
    watch: {
        src () {
            this.getSrc()
        }
    },
    methods: {
        getSrc () {
            console.log('PageDetailsComp, getSrc()', this.src)

            if (this.src && this.src.startsWith('file_')) {
                this.$api.files.doc(this.src).then((doc) => {
                    this.local_src = doc.url
                })
            } else {
                this.local_src = this.src
            }
        },
        copy () {
            if (!this.permalink) return
            navigator.clipboard.writeText(this.permalink)
            this.$toast.success('Link copiato!')
        }
    }
}
</script>
<template>
    <div>
        <v-autocomplete
        v-model="selected"
        :disabled="disabled"

        :item-text="itemText"
        :item-value="itemValue"
        :cache-items="false"
        :items="items"
        return-object

        :search-input.sync="keywords"

        :label="required ? '* ' + label : label"
        :placeholder="placeholder"

        :menu-props="{
            closeOnClick: false,
            closeOnContentClick: false,
            disableKeys: true,
            openOnClick: false,
            maxHeight: 200,
            offsetY: true,
            offsetOverflow: true,
            transition: false,
            top: false,
            bottom: true
        }"

        :hide-selected="false"
        :hide-no-data="false"
        :no-filter="true"
        :hide-details="hideDetails"

        :loading="loading"
        allow-overflow
        :clearable="clearable"
        filled
        dense
        :rules="rules"

        @change="$emit('change', $event)" />
    </div>
</template>

<script>
export default {
    name: 'customer-field',
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        'item-text': {
            type: String,
            required: false,
            default: 'title'
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            selected: null,
            items: [],
            keywords: null,
            loading: false,

            contact: {
                on: null,
                id: null
            }
        }
    },
    watch: {
        keywords (newVal) {
            this.getItems()
        },
        selected () {
            this.$emit('input', this.selected ? this.selected.id : null)
        }
    },
    created () {
        this.start()
    },
    methods: {
        async start () {
            if (this.value) {
                const doc = await this.$api.works.doc(this.value)

                if (doc) {
                    this.selected = doc
                    this.items = [this.selected]
                }
            }
        },
        async getItems () {
            if (
                !this.keywords ||
                !this.keywords.length ||
                // (this.keywords && this.keywords.length <= 1) ||
                (this.selected && this.keywords === this.selected[this.itemText])
            ) {
                return
            }

            this.items = []
            this.loading = true
            const filters = {
                keywords: this.keywords
            }

            try {
                this.items = await this.$api.works.list({
                    pagination: false,
                    filters: filters
                })
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
</style>
<template>
    <bxs-data-lazy
    :promises="() => $api.history.list({ pagination: false, limit: 5, sort: { created_at: -1 }, filters: { model: model, ref: Array.isArray(id) ? '[]|' + id.join(',') : id } })"
    class="mt-4">
        <template #default="{ data }">
            <v-timeline
            v-if="data.length > 0"
            align-top
            small
            dense>
                <v-timeline-item
                v-for="message in data"
                :key="message.id"
                :color="message.http_method === 'post' ? 'success' : 'info'"
                small>
                    <div>
                        <strong
                        v-if="message.user"
                        class="mr-2">{{ message.user.name }}</strong>
                        <small>@ {{ message.created_at | toHumanDate }}</small>
                    </div>
                    <div>{{ $api.enums.$t('resource.http_methods', message.http_method) }}</div>
                </v-timeline-item>
            </v-timeline>
        </template>
    </bxs-data-lazy>
</template>

<script>
export default {
    name: 'document_history',
    props: {
        model: {
            type: String,
            required: true
        },
        id: {
            type: [String, Array],
            required: true
        }
    }
}
</script>
<template>
    <div class="magazzino-filtri py-2">
        <v-row
        no-gutters
        align="center"
        class="ma-0 pa-0">
            <v-col
            v-for="(item, i) in local_items"
            :key="i"
            cols="2"
            class="ma-0 pa-0 px-2 mb-1">
                <small class="mb-1">{{ item.label }}</small>

                <v-autocomplete
                v-if="item.type === 'Array'"
                v-model="item.model"
                :items="item.items"
                :item-text="item.item_text || 'text'"
                :item-value="item.item_value || 'value'"
                :multiple="item.multiple"
                clearable
                solo
                hide-details
                dense />

                <v-text-field
                v-else
                v-model="item.model"
                dense
                filled
                solo
                hide-details
                clearable>
                    <template #append>
                        <v-btn
                        icon
                        :disabled="item.operators.length <= 1"
                        @click="changeOperator(item)">{{ item.operators[item.operator_index] }}</v-btn>
                    </template>
                </v-text-field>
            </v-col>

            <v-col
            v-if="!hideActions"
            class="px-1 mt-1">
                <div class="d-flex">
                    <v-spacer></v-spacer>

                    <v-btn
                    color="info"
                    small
                    class="mr-1"
                    @click="clear">Pulisci filtri</v-btn>

                    <v-btn
                    color="primary"
                    small
                    :disabled="!has_filters"
                    @click="$emit('change')">Applica filtri</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'table-filtri',
    props: {
        value: {
            type: [Object, Array],
            required: false,
            default: () => []
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        'hide-actions': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            local_items: this.items.map(i => ({ ...i, model: null, operator_index: 0 }))
        }
    },
    computed: {
        has_filters () {
            return this.local_items.some(i => !!i.model)
        }
    },
    watch: {
        local_items: {
            deep: true,
            handler (newVal) {
                this.gen()
            }
        }
    },
    methods: {
        gen () {
            const filters = {}

            if (this.local_items.some(i => i.model && i.model.length > 0)) {
                this.local_items.forEach((item) => {
                    if (item.model && item.model.length > 0) {
                        filters[item.field] = item.operators[item.operator_index] + '|' + item.model
                    }
                })
            }

            this.$emit('input', filters)
        },
        changeOperator (item) {
            if (item.operator_index >= item.operators.length - 1) item.operator_index = 0
            else item.operator_index += 1
        },
        clear () {
            this.local_items.forEach((item) => {
                item.model = null
            })

            this.$emit('input', [])
            this.$emit('clear')
            this.$emit('change')
        }
    }
}
</script>
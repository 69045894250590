import Vue from 'vue'
import moment from 'moment'
import { slugify, parseJwt } from './assets/libs/utils'
import dot from 'dot-object'

moment.locale('it')

const c_formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
})

Vue.prototype.$moment = moment

Vue.filter('toHumanPrice', (val, suffix = '€', fixed = 2) => {
    const c = c_formatter.format(Number(val).toFixed(fixed)).replace('€', '') // .replace('.', '*').replace(',', '.').replace('*', ',')
    return `${c} ${suffix}`
})

Vue.filter('slugify', v => v ? slugify(v) : v)
Vue.filter('valueFromJwt', (v, k) => v ? dot.pick(k, parseJwt(v)) : v)
Vue.filter('toHumanDate', (date, type = 'llll') => date ? moment(date).format(type) : '--')
Vue.filter('toHumanDateUnix', (date, type = 'llll') => date ? moment.unix(date).format(type) : '--')
Vue.filter('toHumanCalendar', date => date ? moment(date).calendar() : '--')
Vue.filter('toHumanHttpMethod', (v) => {
    if (v === 'post') return 'Crea'
    else if (v === 'put' || v === 'patch') return 'Modifica'
    else if (v === 'delete') return 'Elimina'
    return v
})

Vue.filter('toThumb', (url, v = 160) => {
    // http://localhost:5000/uploads/commons/pexels-anni-roenkae-2832382.jpg
    // console.log(url)
    if (!url) return url
    const splitted = url.split('/')
    return url.replace(splitted[splitted.length - 1], `thumbs/${v}/${splitted[splitted.length - 1]}`)
})

Vue.filter('toHumanImage', (arr) => {
    if (!arr) return '/img/no-photo.png'

    const api = Vue.prototype.$api

    arr = Array.isArray(arr) ? arr : [arr]
    const val = []

    arr.forEach((id) => {
        const file = api.enums.files.find(img => img.id === id)
        if (file) val.push(file)
    })

    if (!val.length) return '/img/no-photo.png'

    return val[0].url
})
<template>
    <div class="contacts">
        <div class="d-flex align-center mb-8">
            <h1>Contatti</h1>

            <v-spacer></v-spacer>

            <v-btn
            small
            color="primary"
            @click="setForm(null)">Aggiungi contatto</v-btn>
        </div>

        <bxs-data-table
        ref="table"
        id="page_contacts"
        resource="contacts"
        :headers="[
            { value: 'type' },
            { value: 'nome' },
            { value: 'email' },
            { value: 'telefono' },
            { value: 'note' }
        ]"
        model-name="Contact">
            <template #prepend-toolbar>
                <bxs-btn
                icon
                tooltip="Aggiungi nuovo contatto rapido"
                @click="setForm(null)">
                    <v-icon>post_add</v-icon>
                </bxs-btn>
            </template>

            <template #item-actions="{ item, id }">
                <v-list-item @click="setForm(id)">
                    <v-list-item-title>Modifica</v-list-item-title>
                </v-list-item>

                <v-list-item @click="duplicate(item)">
                    <v-list-item-title>Duplica</v-list-item-title>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- contact --------------------------------------------------------------------------------------------------------------- -->
        <bxs-modal
        v-model="contact.on"
        title="Contatto"
        width="768">
            <div class="pa-4">
                <contact
                ref="contact"
                v-if="contact.on"
                :id="contact.id"
                hide-header
                hide-footer
                fluid
                @submit="submitContact" />
            </div>

            <template #actions>
                <bxs-btn
                color="primary"
                @click="$refs.contact.submit()">Salva</bxs-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import Contact from '@/components/forms/Contact.vue'

export default {
    name: 'contacts',
    components: {
        contact: Contact
    },
    data () {
        return {
            contact: {
                on: null,
                id: null
            }
        }
    },
    methods: {
        setForm (id) {
            this.contact.id = id
            this.contact.on = !this.contact.on
        },
        async submitContact (method, model, body, id, isUndo, undo) {
            this.$store.commit('loading', true)

            try {
                await this.$api.contacts.save(id, body)
                this.contact.on = false
                this.$refs.table.getItems()
            } catch (err) {
                alert(err)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
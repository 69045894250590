<template>
    <div class="settings boxed boxed-l">
        <v-row>
            <v-col cols="3">
                <v-card class="pa-0 overflow-hidden">
                    <div
                    class="d-flex flex-column justify-center"
                    style="height: 70px; padding: 0 1.5rem;">
                        <h4>Archivio MP</h4>
                        <p>{{ $api.auth.me.email }}</p>
                        <!-- <a :href="$api.enums.origins.app">{{ $api.enums.origins.app }}</a> -->
                    </div>

                    <v-divider></v-divider>

                    <v-list
                    nav
                    dense>
                        <v-list-item to="/settings/me">
                            <v-list-item-icon>
                                <v-icon>person</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Profilo</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="/settings/auths">
                            <v-list-item-icon>
                                <v-icon>people</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Team e autorizzazioni</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>

            <v-col cols="9">
                <router-view />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'settings',
    data () {
        return {
        }
    }
}
</script>
<template>
    <div
    :key="key"
    class="mov boxed">
        <formizer
        ref="formizer"
        model="Mov"
        undo="/movs"
        :id="$route.params.id"
        @submit="submit">
            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #header-toolbar="{ method, doc }">
                <div class="d-flex align-start">
                    <v-btn
                    icon
                    class="mr-2"
                    @click="$router.go(-1)">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>

                    <h2
                    v-if="method === 'post'"
                    class="mb-0">Aggiungi movimento</h2>
                    <h2
                    v-else
                    class="mb-0">{{ doc.title }}</h2>
                </div>
            </template>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                body
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #body="{ fields, card, doc, rules }">
                <v-alert
                v-if="errors"
                type="error"
                border="left"
                min-height="80"
                text
                outlined>
                    <div class="text-h6">Si è verificato 1 errore:</div>
                    <div>{{ errors }}</div>
                </v-alert>

                <v-row>
                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        col l
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="8">
                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            base
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Informazioni base</h2>
                            </div>

                            <v-text-field
                            v-model="fields.title.model"
                            label="* Titolo"
                            outlined
                            dense
                            :rules="[rules.required]" />

                            <v-combobox
                            v-model="fields.tipo.model"
                            :items="$api.enums.mov_tipologie"
                            label="Tipologia"
                            :return-object="false"
                            outlined
                            dense />

                            <v-textarea
                            v-model="fields.descrizione.model"
                            label="Descrizione"
                            outlined
                            dense />
                        </v-card>

                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Opere</h2>
                            </div>

                            <select-order-field
                            v-model="fields.works.model"
                            resource="works"
                            item-text="title"
                            :query-sort="{ title: 1 }"
                            label="Seleziona le opere" />
                        </v-card>

                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Location</h2>
                            </div>

                            <v-row>
                                <v-col>
                                    <v-combobox
                                    v-model="fields.location.from.model"
                                    :items="$api.enums.locations_from"
                                    label="Location da"
                                    outlined
                                    dense />
                                </v-col>

                                <v-col>
                                    <v-combobox
                                    v-model="fields.location.to.model"
                                    label="Location a"
                                    :items="$api.enums.locations_to"
                                    outlined
                                    dense />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <date-field
                                    v-model="fields.date.from.model"
                                    label="Data da"
                                    outlined
                                    dense />
                                </v-col>

                                <v-col>
                                    <date-field
                                    v-model="fields.date.to.model"
                                    label="Data a"
                                    outlined
                                    dense />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <contact-field
                                    v-model="fields.riferimento.from.model"
                                    label="Contatto da"
                                    outlined
                                    dense
                                    class="mb-0" />
                                </v-col>

                                <v-col>
                                    <contact-field
                                    v-model="fields.riferimento.to.model"
                                    label="Contatto a"
                                    outlined
                                    dense
                                    class="mb-0" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top background">
                            <page-details
                            :src="doc && doc.imgs && doc.imgs.length > 0 ? doc.imgs[0].url : '/img/no-photo.png'"
                            :title="doc ? doc.title : null"
                            :description="doc ? doc.description : null" />

                            <v-card>
                                <div>
                                    <p class="mb-1 bxs-input-label">Note</p>
                                    <v-textarea
                                    v-model="fields.note.model"
                                    outlined
                                    dense
                                    single-line
                                    hide-details
                                    class="mb-0" />
                                </div>
                            </v-card>
                        </div>

                        <!-- <document-history
                        v-if="method === 'put'"
                        model="Work"
                        :id="id" /> -->
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'mov',
    metaInfo: {
      title: 'Movimento'
    },
    data () {
        return {
            key: 1,
            errors: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            window.scrollTo(0, 0)

            this.$el.addEventListener('click', (evt) => {
                console.log('click page', evt)
                this.last_clicked_el = evt.target
            })
        })
    },
    methods: {
        async submit (method, model, body, id, is_undo, undo) {
            this.errors = null
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.movs.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') {
                    this.$router.push(`/movs/${doc_id}`)
                } else {
                    this.key++
                }
            } catch (err) {
                this.errors = err
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
<template>
    <div class="settings-metaquery">
        <div class="mb-8">
            <v-btn
            text
            small
            plain
            @click="$router.go(-1)">
                <v-icon left>chevron_left</v-icon>
                <span>Indietro</span>
            </v-btn>
            <h1>Team e autorizzazioni</h1>
            <v-divider class="my-4"></v-divider>
        </div>

        <bxs-data-table
        ref="table"
        model-name="User"
        resource="users"
        id="table-settings-auths-view"
        hide-default-delete-item
        hide-default-duplicate-item
        :headers="[
            { text: '', value: 'img' },
            { text: 'Nome', value: 'name.first' },
            { text: 'Cognome', value: 'name.last' },
            { text: 'Email', value: 'email' },
            { text: 'Ruolo', value: 'role' },
            { text: 'Stato', value: 'status' },
            { text: 'Blocchi lettura', value: 'scopes.reads' },
            { text: 'Blocchi scrittura', value: 'scopes.writes' }
        ]"
        :static-query="{
            spread: ['img']
        }"
        :static-filters="{
            role: '[]|' + $api.enums.toArray('auth.roles').join(',')
        }">
            <template #prepend-toolbar>
                <bxs-btn
                icon
                tooltip="Aggiungi nuovo elemento rapido"
                @click="setForm()">
                    <v-icon>post_add</v-icon>
                </bxs-btn>
            </template>

            <template #item.status="{ value }">
                <v-chip
                small
                label>{{ value || 'Actived' }}</v-chip>
            </template>

            <template #item-actions="{ item, id }">
                <v-list-item @click="setForm(id)">
                    <v-list-item-icon>
                        <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Modifica rapida</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="item.status !== 'banned'"
                @click="setStatus(item, 'banned')">
                    <v-list-item-icon>
                        <v-icon>person_off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Banna</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                v-else
                @click="setStatus(item, 'actived')">
                    <v-list-item-icon>
                        <v-icon>person_off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Attiva</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="$api.users.remove(id)">
                    <v-list-item-icon>
                        <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Elimina</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            sessions
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <div
        v-if="sessions.length > 0"
        class="mt-12">
            <div class="mb-8">
                <h1>Sessioni</h1>
                <v-divider class="my-4"></v-divider>
            </div>

            <v-card
            v-if="$api.auth.is_super"
            class="pa-0">
                <v-data-table
                :headers="[
                    { text: 'UID', value: 'session.user_id' },
                    { text: 'Email', value: 'email' },
                    { text: 'Scadenza sessione', value: 'expires' },
                    { text: 'Access token', value: 'session.access_token' },
                    { text: 'Refresh access token', value: 'session.refresh_access_token' },
                    { text: 'Scadenza accesso', value: 'access_token_expire' },
                    { text: 'Scadenza refresh accesso', value: 'refresh_access_token_expire', divider: true },
                    { text: '', value: 'id', width: '1%' }
                ]"
                :items="sessions">
                    <template #item.session.user_id="{ value }">
                        <router-link :to="`/users/${value}`">{{ value }}</router-link>
                    </template>

                    <template #item.email="{ item }">{{ item.session.access_token | valueFromJwt('email') }}</template>

                    <template #item.expires="{ value }">{{ value | toHumanDate('lll') }}</template>

                    <template #item.session.access_token="{ value }">...{{ value.slice(value.length - 5, value.length) }}</template>

                    <template #item.session.refresh_access_token="{ value }">...{{ value.slice(value.length - 5, value.length) }}</template>

                    <template #item.access_token_expire="{ item }">{{ item.session.access_token | toExpireDateFromJwt('exp') }}</template>

                    <template #item.refresh_access_token_expire="{ item }">{{ item.session.refresh_access_token | toExpireDateFromJwt('exp') }}</template>

                    <template #item.id="{ value }">
                        <bxs-dot-menu>
                            <v-list-item @click="$api.auth.revoke(value).then(getSessions)">
                                <v-list-item-icon>
                                    <v-icon>block</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Revoca accesso</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </bxs-dot-menu>
                    </template>
                </v-data-table>
            </v-card>
        </div>

        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------------------
            form
        ---------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <v-navigation-drawer
        v-model="form.on"
        app
        right
        clipped
        persistent
        width="400">
            <v-card
            height="calc(100vh - 60px)"
            class="d-flex flex-column pa-0">
                <div class="d-flex align-center pa-4">
                    <h2 class="mb-0">Utente</h2>

                    <v-spacer></v-spacer>

                    <v-btn
                    fab
                    depressed
                    x-small
                    color="error"
                    @click="form.on = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>

                <div
                class="py-4 px-8"
                style="flex-grow: 1; height: 100%; overflow: auto;">
                    <form-user
                    ref="form"
                    :key="form.on"
                    hide-header
                    hide-footer
                    fluid
                    :id="form.id"
                    @modified="form.modified = $event"
                    @submit="submit" />
                </div>

                <v-divider></v-divider>
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    @click="setForm()">Annulla e chiudi</v-btn>

                    <v-btn
                    color="primary"
                    :disabled="!form.modified"
                    @click="$refs.form.submit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { rapidForm } from '@/mixins'

export default {
    name: 'settings_users',
    mixins: [rapidForm('users')],
    data () {
        return {
            sessions: []
        }
    },
    created () {
        this.getSessions()
    },
    methods: {
        async getSessions () {
            const sessions = await this.$api.auth.list()
            this.sessions = sessions.app
        },
        setStatus (item, status) {
            this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                title: 'Attenzione!',
                text: `
                    Vuoi davvero ${status} questo utente
                    - ${item.email}?
                `,
                next: async () => {
                    await this.$api.users.setStatus(item.id, status)
                    this.$refs.table.getItems()
                }
            })
        },
        async submit (method, model, body, id) {
            this.$store.commit('loading', true)

            try {
                if (method === 'post') await this.$api.auth.signup(body)
                else await this.$api.users.save(id, body)

                this.setForm()
                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
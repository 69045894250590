<template>
    <div class="setting-profile-view">
        <div class="mb-6">
            <h2>Profilo</h2>
        </div>

        <bxs-data-lazy
        ref="dataLazy"
        v-model="data_page"
        :promises="{
            me: $api.users.doc($api.auth.me.id),
            access: $api.auth.getAccess()
        }">
            <template #default>
                <v-card class="pa-0 mb-4">
                    <v-img
                    height="200px"
                    src="/img/hub-account-3.jpeg">
                        <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)">
                            <v-toolbar-title class="text-h6 white--text">Profilo</v-toolbar-title>

                            <!-- <v-spacer></v-spacer> -->

                            <!-- <v-btn
                            color="white"
                            icon>
                                <v-icon>more_vert</v-icon>
                            </v-btn> -->
                        </v-app-bar>

                        <v-card-title class="white--text mt-8">
                            <uploader
                            v-model="data_page.me.img"
                            inline
                            @change="$api.users.modify(data_page.me.id, { img: $event })">
                                <template #activator="{ on, value }">
                                    <v-avatar
                                    v-on="on"
                                    size="56"
                                    class="pointer">
                                        <img :src="value.length ? value[0].url : '/img/no-photo.png'">
                                    </v-avatar>
                                </template>
                            </uploader>

                            <div class="pl-3">
                                <p>{{ data_page.me.name.first }} {{ data_page.me.name.last }}</p>
                                <p class="mute--text text--lighten-2">{{ data_page.me.email }}</p>
                            </div>
                        </v-card-title>
                    </v-img>
                </v-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card
                title="Dati di accesso"
                class="mb-4">
                    <v-row>
                        <v-col
                        cols="6"
                        class="mb-4">
                            <div>
                                <p class="mute--text">ID</p>
                                <p>{{ data_page.access.user.id || '--' }}</p>
                            </div>
                        </v-col>

                        <v-col
                        cols="6"
                        class="mb-4">
                            <div>
                                <p class="mute--text">Exp</p>
                                <p>{{ data_page.access.exp | toHumanDateUnix }}</p>
                            </div>
                        </v-col>

                        <v-col cols="6">
                            <div>
                                <p class="mute--text">Ruolo</p>
                                <p>{{ data_page.access.user.role || '--' }}</p>
                            </div>
                        </v-col>

                        <v-col cols="6">
                            <div>
                                <p class="mute--text">Stato</p>
                                <p>{{ data_page.access.user.status || '--' }}</p>
                            </div>
                        </v-col>

                        <!-- <v-col cols="6">
                            <div>
                                <p class="mute--text">Token accesso</p>
                                <p><b>{{ data_page.access.token || '--' }}</b></p>
                            </div>
                        </v-col> -->

                        <!-- <v-col cols="6">
                            <div>
                                <p class="mute--text">Refresh token accesso</p>
                                <p><b>{{ data_page.access.refresh_token || '--' }}</b></p>
                            </div>
                        </v-col> -->
                    </v-row>
                </bxs-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card
                title="I tuoi dati"
                class="mb-4">
                    <template #header-actions>
                        <bxs-btn
                        small
                        text
                        color="info"
                        @click="edit.data.on = !edit.data.on">{{ !edit.data.on ? 'Modifica' : 'Annulla' }}</bxs-btn>
                    </template>

                    <v-row v-if="!edit.data.on">
                        <v-col cols="6">
                            <div>
                                <p class="mute--text">Nome</p>
                                <p>{{ data_page.me.name.first || '--' }}</p>
                            </div>
                        </v-col>

                        <v-col cols="6">
                            <div>
                                <p class="mute--text">Cognome</p>
                                <p>{{ data_page.me.name.last || '--' }}</p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="6">
                            <v-text-field
                            v-model="edit.data.model.name.first"
                            label="Nome"
                            dense
                            outlined />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                            v-model="edit.data.model.name.last"
                            label="Cognome"
                            dense
                            outlined />
                        </v-col>
                    </v-row>

                    <template
                    v-if="edit.data.on"
                    #actions>
                        <v-btn
                        color="primary"
                        @click="save('data')">Salva</v-btn>
                    </template>
                </bxs-card>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <bxs-card title="La tua email">
                    <template #header-actions>
                        <bxs-btn
                        small
                        text
                        color="info"
                        @click="edit.email.on = !edit.email.on">{{ !edit.email.on ? 'Modifica' : 'Annulla' }}</bxs-btn>
                    </template>

                    <v-row v-if="!edit.email.on">
                        <v-col cols="6">
                            <div>
                                <p class="mute--text">Email</p>
                                <p>{{ data_page.me.email || '--' }}</p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="6">
                            <div v-if="edit.email.step === 'intent'">
                                <v-text-field
                                v-model="edit.email.model.email"
                                label="Inserisci la tua nuova email"
                                dense
                                outlined
                                :rules="[$api.enums.fields.rules.email]" />
                            </div>

                            <div v-if="edit.email.step === 'confirm'">
                                <p class="mb-1">Abbiamo inviato una email all'indirizzo {{ edit.email.model.email }}, verifica il codice auth ricevuto per completare la modifica della tua email.</p>

                                <v-otp-input
                                v-model="edit.email.model.code"
                                :disabled="edit.email.loading"
                                :loading="edit.email.loading"
                                label="Codice auth"
                                length="5"
                                @finish="resetEmail" />
                            </div>

                            <v-alert
                            v-if=" edit.email.error"
                            type="error"
                            dense
                            text
                            border="left"
                            class="mt-4">{{ edit.email.error }}</v-alert>
                        </v-col>
                    </v-row>

                    <template
                    v-if="edit.email.on"
                    #actions>
                        <v-btn
                        v-if="edit.email.step === 'intent'"
                        :loading="edit.email.loading"
                        :disabled="edit.email.loading"
                        color="primary"
                        @click="recoveryEmail">Continua</v-btn>

                        <v-btn
                        v-if="edit.email.step === 'confirm'"
                        :loading="edit.email.loading"
                        :disabled="edit.email.loading"
                        class="bxs-mr-ui"
                        @click="edit.email.step = 'intent'">Indietro</v-btn>

                        <!-- <v-btn
                        v-if="edit.email.step === 'confirm'"
                        :loading="edit.email.loading"
                        :disabled="!edit.email.model.email && !edit.email.model.code"
                        color="primary"
                        @click="resetEmail">Salva</v-btn> -->
                    </template>
                </bxs-card>
            </template>
        </bxs-data-lazy>
    </div>
</template>

<script>
import dotObject from 'dot-object'

export default {
    name: 'settings_profile',
    data () {
        return {
            data_page: null,
            is_edit: {
                data: false,
                email: false
            },
            edit: {
                data: {
                    on: false,
                    model: {
                        name: {
                            first: null,
                            last: null
                        }
                    }
                },
                email: {
                    on: false,
                    loading: false,
                    error: null,
                    step: 'intent',
                    model: {
                        email: null,
                        code: null
                    }
                }
            },
            is_edited: false
        }
    },
    methods: {
        async recoveryEmail () {
            this.edit.email.error = null
            this.edit.email.loading = true

            const verify_email = this.$api.enums.fields.rules.email(this.edit.email.model.email)
            console.log(verify_email)

            try {
                if (verify_email !== true) throw verify_email
                await this.$api.post('/v1/auths/email_recovery', this.edit.email.model)
                this.edit.email.step = 'confirm'
            } catch (err) {
                this.edit.email.error = err
            } finally {
                this.edit.email.loading = false
            }
        },
        async resetEmail () {
            this.edit.email.error = null
            this.edit.email.loading = true

            try {
                await this.$api.patch('/v1/auths/email_recovery', this.edit.email.model)
                await this.$api.auth.refreshAccess()

                // this.edit.email.on = false
                // this.edit.email.step = 'intent'
                // this.edit.model = { email: null, code: null }
                this.$router.go()
            } catch (err) {
                this.edit.email.error = err
            } finally {
                this.edit.email.loading = false
            }
        },
        async save (key, cb) {
            this.$store.commit('loading', true)

            try {
                await this.$api.users.modify(
                    this.data_page.me.id,
                    dotObject.dot(this.edit[key].model)
                )
                // {
                //     'name.first': this.data_page.me.name.first,
                //     'name.last': this.data_page.me.name.last,
                //     email: this.data_page.me.email,
                //     img: this.data_page.me.img
                // }

                this.edit[key].on = false

                // this.$refs.dataLazy.call()
                if (cb && typeof cb === 'function') cb()
            } catch (err) {
                this.error = err
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
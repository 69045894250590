import Vue from 'vue'

import BxsBtn from './BxsBtn.vue'
import BxsCard from './BxsCard.vue'
import BxsDataTable from './BxsDataTable.vue'
import BxsDateField from './BxsDateField.vue'
import BxsDotMenu from './BxsDotMenu.vue'
import BxsModal from './BxsModal.vue'
import BxsSelectResource from './BxsSelectResource.vue'
import BxsTreeSelect from './BxsTreeSelect.vue'
import BxsTreeView from './tree/View.vue'
import CommaToListField from './CommaToListField.vue'
import ContactField from './ContactField.vue'
import DataLazy from './DataLazy.vue'
import DateField from './DateField.vue'
import DocumentHistory from './DocumentHistory.vue'
// import FormMetaQuery from './forms/FormMetaQuery.vue'
import Formizer from './Formizer.vue'
import Loading from './Loading'
import PageDetails from './PageDetails.vue'
import PriceField from './CurrencyField.vue'
import QtyField from './QtyField.vue'
import SearchField from './SearchField.vue'
import SelectOrderField from './SelectOrderField.vue'
import TableFiltri from './TableFiltri.vue'
import Uploader from './storage/Uploader.vue'
import VueCurrencyInput from 'vue-currency-input'
import WysiwygField from './WysiwygField.vue'

import FormUser from './forms/User.vue'
import WorkField from './WorkField.vue'

// libs
import draggable from 'vuedraggable'
import { VTooltip } from 'floating-vue'
import Toast from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import 'floating-vue/dist/style.css'

Vue.use(VueCurrencyInput)
Vue.use(Toast, {
    position: 'top-right',
    timeout: 5500,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    newestOnTop: false,
    transition: 'Vue-Toastification__fade',
    bodyClassName: ['bxs-notify'],
    maxToasts: 3,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    draggable: false
})

Vue.directive('tooltip', VTooltip)

// Vue.component('storage', Storage)
Vue.component('bxs-btn', BxsBtn)
Vue.component('bxs-card', BxsCard)
Vue.component('bxs-data-lazy', DataLazy)
Vue.component('bxs-data-table', BxsDataTable)
Vue.component('bxs-date-field', BxsDateField)
Vue.component('bxs-dot-menu', BxsDotMenu)
Vue.component('bxs-modal', BxsModal)
Vue.component('bxs-select-resouce-field', BxsSelectResource)
Vue.component('bxs-tree-select', BxsTreeSelect)
Vue.component('bxs-tree-view', BxsTreeView)
Vue.component('comma-to-list-field', CommaToListField)
Vue.component('contact-field', ContactField)
Vue.component('currency-field', PriceField)
Vue.component('date-field', DateField)
Vue.component('document-history', DocumentHistory)
Vue.component('draggable', draggable)
// Vue.component('form-metaquery', FormMetaQuery)
Vue.component('formizer', Formizer)
Vue.component('loading', Loading)
Vue.component('page-details', PageDetails)
Vue.component('qty-field', QtyField)
Vue.component('search-field', SearchField)
Vue.component('select-order-field', SelectOrderField)
Vue.component('table-filtri', TableFiltri)
Vue.component('uploader', Uploader)
Vue.component('work-field', WorkField)
Vue.component('wysiwyg-field', WysiwygField)

Vue.component('form-user', FormUser)
<template>
    <div class="works">
        <div class="d-flex align-center mb-8">
            <h1>Opere</h1>

            <v-spacer></v-spacer>

            <v-btn
            to="/works/new"
            color="primary"
            class="bxs-mr-ui">Aggiungi opera</v-btn>

            <v-btn
            color="button-base"
            @click="openDialogCsv">Scarica lista opere</v-btn>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-data-table
        ref="table"
        id="page_works"
        resource="works"
        :headers="[
            { text: 'Fronte', value: 'front_img' },
            { text: 'Titolo', value: 'title' },
            { text: 'Anno', value: 'anno' },
            { text: 'Numero / ID', value: 'numero' },
            { text: 'Tecnica', value: 'tecnica' },
            { text: 'Supporto', value: 'supporto' },
            { text: 'Categoria', value: 'categoria' },
            { text: 'Collezione', value: 'collezione' },
            { text: 'Collocazione', value: 'collocazione' },
            { text: 'Tipo imballo', value: 'tipo_imballo' },
            { text: 'Altezza', value: 'dimensioni.height' },
            { text: 'Larghezza', value: 'dimensioni.width' },
            { text: 'Profondità', value: 'dimensioni.depth' },
            { text: 'Peso', value: 'dimensioni.weight' },
            { text: 'Autentica', value: 'autentica.è_presente' },
            { text: 'Richiedente autentica', value: 'autentica.richiedente' },
            { text: 'Data rilascio autentica', value: 'autentica.data_rilascio' },
            { text: 'Cassa/e', value: 'cassa.è_presente', filterable: false, sortable: false },
            { text: 'Quaderno serigrafie', value: 'in_quaderno_serigrafie' },
            { text: 'Quaderno veline', value: 'in_quaderno_veline' },
            { text: 'Firmata', value: 'è_firmata' },
            { text: 'Multiplo', value: 'è_multiplo' }
        ]"
        to-create-item="/works/new"
        to-update-item="/works"
        :static-query="{
            spread: ['front_img',  'back_img', 'imgs', 'autentica.imgs']
        }"
        model-name="Work"
        :sort-by="['anno', 'title']"
        :sort-dir="[false, false]"
        hide-default-duplicate-item
        @click:row="$router.push('/works/' + $event.id)">
            <template #toolbar-menu>
                <v-list-item @click="openDialogCsv">
                    <v-list-item-icon>
                        <v-icon>download</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Scarica lista opere</v-list-item-title>
                </v-list-item>
            </template>

            <template #item.front_img="{ value }">
                <v-avatar
                rounded
                size="40">
                    <v-img
                    v-if="value"
                    :src="value.url | toThumb"
                    lazy-src="/img/logo-s.png"
                    aspect-ratio="1" />

                    <v-img
                    v-else
                    src="/img/no-photo.png"
                    aspect-ratio="1" />
                </v-avatar>
            </template>

            <template #item.back_img="{ value }">
                <v-avatar
                rounded
                size="40">
                    <v-img
                    v-if="value"
                    :src="value.url | toThumb"
                    lazy-src="/img/logo-s.png"
                    aspect-ratio="1" />

                    <v-img
                    v-else
                    src="/img/no-photo.png"
                    aspect-ratio="1" />
                </v-avatar>
            </template>

            <template #item.categoria="{ value }">{{ value }}</template>
            <template #item.dimensioni.height="{ value }">{{ value }} cm</template>
            <template #item.dimensioni.width="{ value }">{{ value }} cm</template>
            <template #item.dimensioni.depth="{ value }">{{ value }} cm</template>
            <template #item.dimensioni.weight="{ value }">{{ value }} kg</template>
            <template #item.valore="{ value }">{{ value | toHumanPrice }}</template>
            <template #item.valore_assicurativo="{ value }">{{ value | toHumanPrice }}</template>

            <template #item.autentica.data_rilascio="{ value }">{{ value | toHumanDate('l') }}</template>

            <template #item.cassa.è_presente="{ item, value }">
                <span v-if="!value">No</span>

                <ul
                v-else
                class="py-2">
                    <li
                    v-for="(c, i) in item.cassa.value"
                    :key="i">
                        <p>{{ c.titolo }}</p>
                        <p>{{ c.numero_riferimento }} - {{ c.peso }} Kg</p>
                        <v-divider
                        v-if="i < item.cassa.value.length - 1"
                        class="my-1"></v-divider>
                    </li>
                </ul>
            </template>

            <!--  -->
            <template #item-actions="{ item, id }">
                <v-list-item :to="`/works/${id}/sheet`">
                    <v-list-item-icon>
                        <v-icon>open_in_new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Visualizza scheda</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item @click="showScheda(id)">
                    <v-list-item-title>Visualizza scheda rapida</v-list-item-title>
                </v-list-item> -->

                <v-list-item @click="duplicate(item)">
                    <v-list-item-icon>
                        <v-icon>content_copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Duplica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="$api.auth.is_dev"
                @click="getHistory(item)">
                    <v-list-item-icon>
                        <v-icon>history</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Logs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- ------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="history.on"
        width="768"
        :title="`Logs - ${history.item ? history.item.title : ''}`">
            <document-history
            v-if="history.item"
            :key="history.item.id"
            model="Work"
            :id="history.item.id" />
        </bxs-modal>

        <!-- ------------------------------------------------------------------------------------------------------------
            csv
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="csv.on"
        max-width="768"
        title="Scarica lista opere"
        :scrollable="false">
            <v-stepper
            v-model="csv.step"
            style="position: relative;">
                <v-stepper-header class="mb-4 sticky">
                    <v-stepper-step
                    :complete="csv.step > 1"
                    step="1">Selezione campi</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                    :complete="csv.step > 2"
                    step="2">Posizione colonne</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                    :complete="csv.step > 3"
                    step="3">Ordine opere</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <div class="mb-3">
                            <v-chip
                            outlined
                            @click="csv.selected_fields = JSON.parse(JSON.stringify(csv.fields))">Seleziona tutti</v-chip>

                            <v-chip
                            v-if="csv.selected_fields.length > 0"
                            outlined
                            class="ml-1"
                            @click="csv.selected_fields = []">Deseleziona tutti</v-chip>
                        </div>

                        <v-chip-group
                        v-model="csv.selected_fields"
                        multiple
                        clearable
                        active-class="primary"
                        column>
                            <v-chip
                            v-for="v in csv.fields"
                            :key="v"
                            filter
                            :value="v">{{ $t(v) }}</v-chip>
                        </v-chip-group>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <draggable
                        v-if="csv.selected_fields.length > 0"
                        v-model="csv.selected_fields"
                        ghost-class="draggable-ghost"
                        tag="ul">
                            <li
                            v-for="(v, i) in csv.selected_fields"
                            :key="v"
                            class="d-flex align-center pa-2 dragging mb-1">
                                <v-icon class="mr-1">drag_indicator</v-icon>
                                <span>{{ $t(v) }}</span>

                                <v-spacer></v-spacer>

                                <v-chip x-small>
                                    <span>colonna n. {{ i + 1 }}</span>
                                    <v-icon
                                    right
                                    @click="csv.selected_fields.splice(i, 1)">close</v-icon>
                                </v-chip>
                            </li>
                        </draggable>

                        <p v-else>Nessun campo selezionato, il csv scaricato conterrà solo il campo "titolo".</p>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-select
                        v-if="csv.selected_fields.length > 0"
                        v-model="csv.sort_by"
                        :items="csv.selected_fields"
                        filled
                        dense
                        label="Ordina per" />

                        <p
                        v-else
                        class="mb-6">Nessun campo selezionato, il csv scaricato sarà ordinato per il campo "titolo".</p>

                        <v-select
                        v-model="csv.sort_dir"
                        filled
                        dense
                        :items="[{ text: 'Asc - A/Z - 0/9', value: 'asc' }, { text: 'Desc - Z/A - 9/0', value: 'desc' }]"
                        label="Tipologia ordine" />
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

            <template #actions>
                <bxs-btn
                v-if="csv.step > 1"
                @click="csv.step === 3 ? (csv.step = 2) : (csv.step = 1)">Indietro</bxs-btn>

                <bxs-btn
                v-if="csv.step < 3"
                color="primary"
                @click="csv.step === 1 ? (csv.step = 2) : (csv.step = 3)">Continua</bxs-btn>

                <v-btn
                v-if="csv.step >= 3"
                color="primary"
                @click="downloadCsv()">Scarica</v-btn>
            </template>
        </bxs-modal>

        <!-- ------------------------------------------------------------------------------------------------------------
            imgs upload
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="uploader.on"
        title="Immagini">
            <span
            v-if="uploader.item"
            slot="title"> {{ uploader.item.title }}</span>

            <uploader
            v-if="uploader.item"
            ref="uploader"
            v-model="uploader.imgs"
            multiple
            show-files />

            <template #actions>
                <v-btn
                color="primary"
                :disabled="$refs.uploader && $refs.uploader.loading"
                @click="saveImgs">Salva</v-btn>
            </template>
        </bxs-modal>

        <!-- ------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="scheda.on"
        width="768">
            <span slot="title">{{ scheda.item ? scheda.item.title : '--' }}</span>

            <v-row>
                <v-col>
                </v-col>
            </v-row>
        </bxs-modal>
    </div>
</template>

<script>
export default {
    name: 'works',
    metaInfo: {
      title: 'Opere'
    },
    data () {
        return {
            csv: {
                on: false,
                filename: null,
                fields: [],
                selected_fields: [],
                sort_by: 'title',
                sort_dir: 'asc',
                step: 1
            },
            history: {
                on: false,
                item: null
            },
            uploader: {
                on: false,
                item: null,
                imgs: []
            },
            scheda: {
                on: false,
                item: null,
                movs: []
            }
        }
    },
    watch: {
        'uploader.on' (newVal) {
            if (!newVal) {
                this.uploader.imgs = []
                this.uploader.item = null
            }
        },
        'csv.on' (newVal) {
            if (!newVal) {
                this.csv.selected_fields = []
                this.csv.sort_by = null
                this.sort_dir = 'asc'
                this.csv.step = 1
            }
        }
    },
    methods: {
        async showScheda (id) {
            this.$store.commit('loading', true)

            try {
                this.scheda.item = await this.$api.works.doc(id, {
                    populate: 'imgs'
                })
                this.scheda.movs = await this.$api.movs.list({
                    pagination: false,
                    sort: { created_at: 1 },
                    filters: {
                        works: '[]|' + id
                    }
                })

                this.scheda.on = true
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        setUploader (item, on = true) {
            this.uploader.item = item ? JSON.parse(JSON.stringify(item)) : null
            this.uploader.imgs = item ? JSON.parse(JSON.stringify(item.imgs)) : []
            this.uploader.on = on
        },
        async saveImgs () {
            this.$store.commit('loading', true)

            try {
                await this.$api.works.modify(this.uploader.item.id, {
                    imgs: this.uploader.imgs
                })
                this.uploader.on = false
                this.uploader.item = null
                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async openDialogCsv () {
            this.$store.commit('loading', true)

            try {
                const fields = await this.$api.getSchema('Work')
                delete fields._id
                delete fields.uid
                delete fields.__v
                delete fields.status
                delete fields.id
                delete fields.created_at
                delete fields.updated_at
                delete fields.updated_user
                delete fields.created_user
                delete fields.old_id
                delete fields.number_id
                delete fields.imgs
                delete fields.movs
                delete fields['autentica.imgs']

                this.csv.fields = Object.keys(fields).sort()
                this.csv.selected_fields = this.csv.fields
                this.csv.on = true
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async downloadCsv () {
            this.$store.commit('loading', true)
            const query = `?access_token=${this.$api.auth.access_token}&fields=${this.csv.selected_fields}&sort_by=${this.csv.sort_by}&sort_dir=${this.csv.sort_dir}`

            try {
                window.open(
                    `${this.$api.enums.origins.api}/v1/works/downloads/csv${query}`,
                    '_blank'
                )

                // const doc = await this.$api.get('/v1/works/downloads/csv', {
                //     filename: this.csv.filename,
                //     fields: this.csv.selected_fields,
                //     sort_by: this.csv.sort_by,
                //     sort_dir: this.csv.sort_dir
                // })

                // const blob = new Blob(['\ufeff', doc])
                // const url = URL.createObjectURL(blob)
                // const link = document.createElement('a')
                // link.setAttribute('href', url)
                // link.setAttribute('download', `${this.csv.filename || 'opere'}-${new Date().getTime()}.xlsx`)
                // document.body.appendChild(link)
                // link.click()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async duplicate (from_item) {
            this.$store.commit('loading', true)

            const item = JSON.parse(JSON.stringify(from_item))
            delete item.id
            delete item.movs
            item.title = item.title + ' - ' + Date.now()

            try {
                await this.$api.works.create(item)
                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async getHistory (item) {
            this.history.on = true
            this.history.item = item

            // try {
            //     this.history.items = await this.$api.history.list({
            //         limit: 120,
            //         filters: {
            //             model: 'Work',
            //             ref: item.id
            //         }
            //     })
            // } catch (err) {
            //     throw new Error(err.message || err.name)
            // }
        }
    }
}
</script>
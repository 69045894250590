<template>
    <div class="files">
        <div class="mb-8">
            <h1>Logs</h1>
        </div>

        <bxs-data-table
        ref="table"
        model-name="History"
        resource="history"
        id="table-files-view"
        :headers="[
            { value: 'event' },
            { value: 'created_at' },
            { value: 'model' },
            { value: 'api' },
            { value: 'ref' },
            { value: 'event_data' },

            { value: 'params' },
            { value: 'query' },
            { value: 'body', },

            { value: 'user.id' },
            { value: 'user.email' },

            { value: 'controller' },
            { value: 'function' },

            { value: 'agent' }
        ]"
        :hide-default-delete-item="$api.auth.is_admin"
        :static-filters="events.length > 0 ? { event: '[]|' + events.join(',') } : null">
            <template #append-toolbar>
                <v-chip-group
                v-model="events"
                multiple
                active-class="success">
                    <v-chip
                    value="default"
                    small
                    filter
                    outlined>Default</v-chip>

                    <v-chip
                    value="exception"
                    small
                    filter
                    outlined>Exception</v-chip>

                    <v-chip
                    value="error"
                    small
                    filter
                    outlined>Error</v-chip>
                </v-chip-group>
            </template>

            <template #item.created_at="{ item }">{{ item.created_at | toHumanDate('lll') }}</template>

            <template #item.api="{ item }">
                <span>{{ item.http_method }}:{{ item.endpoint }}</span>
            </template>

            <template #item.ref="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value }}</span>
            </template>

            <template #item.params="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value }}</span>
            </template>

            <template #item.query="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value }}</span>
            </template>

            <template #item.body="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value }}</span>
            </template>

            <template #item.event_data="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value }}</span>
            </template>

            <template #item.agent="{ value }">
                <span v-tooltip="JSON.stringify(value)">{{ value }}</span>
            </template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'history',
    data () {
        return {
            events: []
        }
    },
    watch: {
        events () {
            this.$refs.table.getItems()
        }
    }
}
</script>

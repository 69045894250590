<template>
    <div class="signin">
        <div>
            <v-card
            height="440"
            width="360">
                <!-- <div class="mb-8">
                    <v-img
                    src="/img/infinite.gif"
                    max-width="140" />
                </div> -->

                <div class="mb-8">
                    <h1>Accedi</h1>
                </div>

                <div>
                    <v-text-field
                    v-model="email"
                    :disabled="loading"
                    label="Email"
                    dense
                    outlined
                    required />

                    <v-text-field
                    v-model="password"
                    :disabled="loading"
                    label="Password"
                    dense
                    outlined
                    :append-icon="showPass ? 'visibility_off' : 'visibility'"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass" />

                    <v-btn
                    :disabled="!email || !password"
                    block
                    color="primary"
                    :loading="loading"
                    @click="signin">Accedi</v-btn>

                    <v-alert
                    v-if="error"
                    border="left"
                    text
                    dense
                    type="error"
                    class="ma-0 mt-2">
                        <small>{{ error }}</small>
                    </v-alert>
                </div>

                <v-btn
                absolute
                left
                bottom
                text
                x-small
                to="/signin/recovery">Password dimenticata?</v-btn>

                <v-btn
                href="mailto:simon3fusco@gmail.com"
                text
                absolute
                right
                bottom
                x-small>Assistenza</v-btn>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'signin',
    data () {
        return {
            email: '',
            password: '',
            error: null,
            loading: false,
            showPass: false
        }
    },
    methods: {
        async signin () {
            this.loading = true
            this.error = null

            try {
                await this.$api.auth.signInWithEmailAndPassword(this.email, this.password)
                await this.$api.getEnums()
                this.$router.replace('/')
                this.$emit('signin')
            } catch (err) {
                this.error = err.message || err.name || err
                throw new Error(this.error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
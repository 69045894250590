var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts"},[_c('div',{staticClass:"d-flex align-center mb-8"},[_c('h1',[_vm._v("Contatti")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setForm(null)}}},[_vm._v("Aggiungi contatto")])],1),_c('bxs-data-table',{ref:"table",attrs:{"id":"page_contacts","resource":"contacts","headers":[
        { value: 'type' },
        { value: 'nome' },
        { value: 'email' },
        { value: 'telefono' },
        { value: 'note' }
    ],"model-name":"Contact"},scopedSlots:_vm._u([{key:"prepend-toolbar",fn:function(){return [_c('bxs-btn',{attrs:{"icon":"","tooltip":"Aggiungi nuovo contatto rapido"},on:{"click":function($event){return _vm.setForm(null)}}},[_c('v-icon',[_vm._v("post_add")])],1)]},proxy:true},{key:"item-actions",fn:function(ref){
    var item = ref.item;
    var id = ref.id;
return [_c('v-list-item',{on:{"click":function($event){return _vm.setForm(id)}}},[_c('v-list-item-title',[_vm._v("Modifica")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.duplicate(item)}}},[_c('v-list-item-title',[_vm._v("Duplica")])],1)]}}])}),_c('bxs-modal',{attrs:{"title":"Contatto","width":"768"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('bxs-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.contact.submit()}}},[_vm._v("Salva")])]},proxy:true}]),model:{value:(_vm.contact.on),callback:function ($$v) {_vm.$set(_vm.contact, "on", $$v)},expression:"contact.on"}},[_c('div',{staticClass:"pa-4"},[(_vm.contact.on)?_c('contact',{ref:"contact",attrs:{"id":_vm.contact.id,"hide-header":"","hide-footer":"","fluid":""},on:{"submit":_vm.submitContact}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
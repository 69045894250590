import dot from 'dot-object'

const ts = {
    categories: 'Categoria/e',
    'date.from.value': 'Data inizio',
    'date.to.value': 'Data fine',
    'dimensioni.depth': 'Profondità',
    'dimensioni.height': 'Altezza',
    'dimensioni.weight': 'Peso',
    'dimensioni.width': 'Larghezza',
    'location.from.value': 'Location a',
    'location.to.value': 'Location da',
    'name.first': 'Nome',
    'name.last': 'Cognome',
    'riferimento.from.value': 'Contatto da',
    'riferimento.to.value': 'Contatto a',
    'scopes.reads': 'Permessi lettura',
    'scopes.writes': 'Permessi scrittura',
    anno: 'Anno',
    created_at: 'Data creazione',
    false: 'No',
    id: 'Identificativo interno',
    img: 'Immagine',
    imgs: 'Immagini',
    movs: 'movimenti',
    numero: 'Numero',
    role: 'Ruolo',
    roles: 'Ruoli',
    tipo: 'Tipologia',
    title: 'Titolo',
    true: 'Si',
    types: 'Tipo',
    updated_at: 'Data aggiornamento',
    work: 'Opera',
    works: 'Opere',
    è_firmata: 'Firmata',
    è_multiplo: 'Multiplo',
    ststus: 'Stato',
    front_img: 'Fronte',
    back_img: 'Retro'
}

const t = (k) => {
    if (typeof k === 'object' || typeof k === 'number') return k

    const vk = String(k)
    return ts[vk] || dot.pick(vk, ts) || k
}

export default t
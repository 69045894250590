<template>
    <div class="signin signin-reset">
        <div>
            <v-card
            height="440"
            width="360">
                <!-- <div class="mb-8">
                    <v-img
                    src="/img/logo.png"
                    max-width="140" />
                </div> -->

                <div class="mb-8">
                    <h1>Reset password</h1>
                    <p v-if="!is_verified">Inserisci il codice di verifica che ti è stato inviato al tuo indirizzo e-mail</p>
                    <p v-else>Crea una nuova password</p>
                </div>

                <v-card-text class="ma-0 pa-0">
                    <v-otp-input
                    v-if="!is_verified"
                    v-model="code"
                    :disabled="otp_loading"
                    label="Codice auth"
                    length="5"
                    @finish="handleFinishOTP"></v-otp-input>

                    <v-text-field
                    v-else
                    v-model="password"
                    :disabled="loading"
                    label="Password"
                    dense
                    outlined
                    hide-details
                    :append-icon="showPass ? 'visibility_off' : 'visibility'"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass" />

                    <v-btn
                    v-if="is_verified"
                    :disabled="!password && !code"
                    block
                    color="primary"
                    :loading="loading"
                    @click="recovery">Invia</v-btn>

                    <v-alert
                    v-if="error"
                    border="left"
                    text
                    dense
                    type="error"
                    class="ma-0 my-4">
                        <small>{{ error }}</small>
                    </v-alert>
                </v-card-text>

                <v-btn
                text
                x-small
                absolute
                bottom
                left
                exact
                to="/signin"
                class="mr-4">Accedi</v-btn>

                <v-btn
                text
                absolute
                bottom
                right
                x-small
                exact
                to="/signin/recovery">Non hai ricevuto il codice? Recupera</v-btn>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'signin_reset',
    data () {
        return {
            code: null,
            password: null,
            error: null,
            loading: false,
            showPass: false,
            otp_loading: false,
            is_verified: false
        }
    },
    created () {
        if (sessionStorage.getItem('last_pwd_recovery_otp')) {
            this.code = sessionStorage.getItem('last_pwd_recovery_otp')
            this.handleFinishOTP()
        }
    },
    methods: {
        async recovery () {
            this.loading = true
            this.error = null

            try {
                await this.$api.auth.passwordReset(this.code, this.password)
                this.$router.push('/signin')
                this.$toast.success('Password aggiornata con successo')
            } catch (err) {
                this.error = err.message || err.name || err
                throw new Error(this.error)
            } finally {
                this.loading = false
            }
        },
        async handleFinishOTP (code) {
            this.otp_loading = true
            this.error = null

            try {
                await this.$api.auth.verifyPasswordRecovery(code || this.code)
                this.is_verified = true
                sessionStorage.setItem('last_pwd_recovery_otp', code || this.code)
            } catch (err) {
                if (err === 'auth.recovery_password.verify') {
                    this.error = 'Inserisci il codice valido'
                } else {
                    this.error = err.message || err.name || err
                    throw new Error(this.error)
                }
            } finally {
                this.otp_loading = false
            }
        }
    },
    beforeDestroy () {
        sessionStorage.removeItem('last_pwd_recovery_otp')
    }
}
</script>
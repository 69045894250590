<template>
    <div class="settings-metaqueries">
        <div>
            <h2 class="mb-1">Metarules</h2>
            <p>Condizioni personalizzate tra le risorse o i campi delle risorse</p>
            <v-divider class="my-8"></v-divider>
        </div>

        <bxs-data-lazy
        v-model="items"
        :promises="() => $api.enum('metaquery.models')">
            <v-card class="pa-0">
                <v-card-title>Risorse</v-card-title>
                <v-card-subtitle>Scegli la risorsa su cui creare una nuova condizione</v-card-subtitle>

                <v-divider></v-divider>

                <v-list class="pa-0 ma-0">
                    <v-list-item
                    v-for="item in items"
                    :key="item.value"
                    :to="`/settings/metaqueries/${item.value}`">
                        <v-list-item-icon>
                            <v-icon>widgets</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            <v-list-item-subtitle>{{ counts[item.value] || 0 }} rules</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>
                </v-list>
            </v-card>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'settings_metaqueries',
    data () {
        return {
            items: [],
            counts: {
                User: 0,
                Product: 0,
                Order: 0,
                Promo: 0,
                Dep: 0,
                Cart: 0
            }
        }
    },
    created () {
        this.$api.metaqueries.list({
            group: 'model'
        }).then((docs) => {
            docs.forEach((item) => {
                this.counts[item.id] = item.count
            })
        })
    }
}
</script>
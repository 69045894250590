var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['select-resource-field', {
    'mb-0': _vm.hideDetails
}]},[_c('v-select',{attrs:{"item-text":_vm.itemText,"item-value":_vm.itemValue,"items":_vm.items,"label":_vm.required ? '* ' + (_vm.label || '') : (_vm.label || ''),"placeholder":_vm.placeholder,"return-object":_vm.returnObject,"disabled":_vm.disabled,"rules":_vm.rules,"multiple":_vm.multiple,"loading":_vm.loading,"dense":_vm.dense,"hide-details":_vm.hideDetails,"clearable":_vm.clearable,"outlined":_vm.outlined,"filled":_vm.filled,"flat":_vm.flat,"solo":_vm.solo,"single-line":_vm.singleLine,"readonly":_vm.readonly,"menu-props":{ top: false, bottom: true, offsetY: true }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.multiple)?_c('span',{staticClass:"py-1 select-resource-field-selection"},[_vm._v(_vm._s(_vm.dot.pick(_vm.itemText, item)))]):(_vm.multiple && !index)?_c('span',{staticClass:"py-1"},[_vm._v(_vm._s(_vm.selected.length)+" "+_vm._s(_vm.multiple && _vm.selected.length > 1 ? 'elementi selezionati' : 'elemento selezionato'))]):_vm._e()]}},(!_vm.hideCreate)?{key:"prepend-item",fn:function(){return [(_vm.formComponent)?_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.setForm()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Aggiungi nuova/o "+_vm._s(_vm.formTitle))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e(),(_vm.formComponent)?_c('v-divider'):_vm._e(),_c('v-list-item',{staticClass:"sticky white",on:{"mousedown":function($event){$event.preventDefault();}}},[_c('v-list-item-content',[_c('search-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Cerca","dense":"","filled":"","clearable":"","hide-details":"","single-line":""},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}),(_vm.multiple && _vm.items.length > 0)?_c('div',{staticClass:"text-right mt-1",on:{"click":_vm.selectAll}},[_c('v-btn',{attrs:{"text":"","color":"info","x-small":""}},[_vm._v("Seleziona tutti")])],1):_vm._e()],1)],1)]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._t("item-icon",null,{"item":item}),_vm._t("item-avatar",null,{"item":item}),(!_vm.$scopedSlots['item-icon'] && _vm.multiple)?_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.selected && _vm.selected.includes(item.id) ? 'check_box' : 'check_box_outline_blank'))])],1):_vm._e(),_c('v-list-item-content',[(!_vm.$scopedSlots['item-content'])?_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.dot.pick(_vm.itemText, item))}}):_vm._e(),_vm._t("item-content",null,{"item":item})],2),(!_vm.hideEdit)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setForm(item.id)}}},[_c('v-icon',[_vm._v("edit")])],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.formComponent)?_c('bxs-modal',{attrs:{"title":!_vm.form.id ? 'Nuova/o ' + _vm.formTitle : 'Modifica ' + _vm.formTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.form.submit()}}},[_vm._v("Salva")])]},proxy:true}],null,false,3908835863),model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[_c(_vm.formComponent,_vm._b({key:_vm.form.on,ref:"form",tag:"component",staticClass:"py-4",attrs:{"id":_vm.form.id,"hide-header":"","hide-footer":"","fluid":""},on:{"submit":_vm.submit}},'component',_vm.formComponentProps,false))],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"disabled":_vm.disabled,"item-text":_vm.itemText,"item-value":_vm.itemValue,"cache-items":false,"items":_vm.items,"return-object":"","search-input":_vm.keywords,"label":_vm.required ? '* ' + _vm.label : _vm.label,"placeholder":_vm.placeholder,"menu-props":{
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 200,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
        top: false,
        bottom: true
    },"hide-selected":false,"hide-no-data":false,"no-filter":true,"hide-details":_vm.hideDetails,"loading":_vm.loading,"allow-overflow":"","clearable":_vm.clearable,"filled":"","dense":"","rules":_vm.rules},on:{"update:searchInput":function($event){_vm.keywords=$event},"update:search-input":function($event){_vm.keywords=$event},"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div></div>
</template>

<script>
export default {
    name: 'cache_clear',
    mounted () {
        this.start()
    },
    methods: {
        start () {
            const pwd = prompt('Password Please')

            if (pwd === '8!T72fzr4lDr') {
                localStorage.clear()
                sessionStorage.clear()

                alert('OK')

                this.$router.go('/')
            } else {
                this.$router.go()
            }
        }
    }
}
</script>
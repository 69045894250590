var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"movs"},[_c('div',{staticClass:"d-flex align-center mb-8"},[_c('h1',[_vm._v("Movimenti")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/movs/new","small":"","color":"primary"}},[_vm._v("Aggiungi movimento")])],1),_c('bxs-data-table',{ref:"table",attrs:{"id":"page_movs","resource":"movs","headers":[
        { value: 'title' },
        { value: 'tipo' },
        { value: 'descrizione' },
        { value: 'location.from' },
        { value: 'location.to' },
        { value: 'date.from' },
        { value: 'date.to' },
        { value: 'contact.from' },
        { value: 'contact.to' },
        { value: 'note' }
    ],"model-name":"Mov","to-create-item":"/movs/new","to-update-item":"/movs"},on:{"click:row":function($event){return _vm.setForm($event.id)}},scopedSlots:_vm._u([{key:"prepend-toolbar",fn:function(){return [_c('bxs-btn',{attrs:{"icon":"","tooltip":"Aggiungi nuovo movimento rapido"},on:{"click":function($event){return _vm.setForm(null)}}},[_c('v-icon',[_vm._v("post_add")])],1)]},proxy:true},{key:"item.works",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(value.length)+" ")]}},{key:"item.categories",fn:function(ref){
    var value = ref.value;
return [_vm._v(_vm._s(value.join(',')))]}},{key:"item-actions",fn:function(ref){
    var item = ref.item;
return [(item.works.length > 0)?_c('v-list-item',{on:{"click":function($event){return _vm.showWorks(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("open_in_new")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Visualizza opere ("+_vm._s(item.works.length)+")")])],1)],1):_vm._e(),(item.works.length > 0)?_c('v-list-item',{on:{"click":function($event){return _vm.downloadCsv(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Scarica lista opere")])],1)],1):_vm._e()]}}])}),_c('bxs-modal',{attrs:{"title":"Movimento","width":"768"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('bxs-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.form_mov.submit()}}},[_vm._v("Salva")])]},proxy:true}]),model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[_c('div',{staticClass:"pa-4"},[_c('form-mov',{key:_vm.form.on,ref:"form_mov",staticClass:"py-4",attrs:{"id":_vm.form.id,"hide-header":"","hide-footer":""},on:{"submit":_vm.submitForm}})],1)]),_c('bxs-modal',{attrs:{"title":("Opere nel movimento " + (_vm.works.mov ? _vm.works.mov.title : '')),"width":"768"},model:{value:(_vm.works.on),callback:function ($$v) {_vm.$set(_vm.works, "on", $$v)},expression:"works.on"}},[_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.works.items),function(work){return _c('v-list-item',{key:work.id},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":work.imgs.length > 0 ? work.imgs[0].url : '/img/no-photo.png'}})],1),_c('v-list-item-content',[_c('p',[_vm._v(_vm._s(work.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(work.anno))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
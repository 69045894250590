import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import it from 'vuetify/lib/locale/it'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { it },
        current: 'it'
    },
    icons: {
        iconfont: 'md'
    },
    theme: {
        dark: localStorage.getItem('fpl-manager-theme') === 'dark',
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#651FFF',
                secondary: '#673AB7',
                tertiary: '#311B92',
                background: '#f6f6f7',
                aside: '#fff',
                navbar: '#fff',
                info: '#0096ff',
                mute: '#666666',
                black: '#30302F',
                'button-base': '#e3e3e3'
            },
            dark: {
                primary: '#054a91',
                secondary: '#3e7cb1',
                tertiary: '#81a4cd',
                background: '#121212',
                aside: '#000',
                navbar: '#000',
                info: '#0096ff',
                mute: '#666666',
                black: '#30302F',
                'button-base': '#e3e3e3'
            }
            // light: {
            //     primary: '#651FFF',
            //     secondary: '#673AB7',
            //     tertiary: '#311B92',
            //     accent: '#0097A7',
            //     info: '#009688',
            //     error: '#FF5252',
            //     warning: '#F9A825',
            //     mute: '#666666'
            //     // success: 'teal',
            //     // warning: '#FFC107'
            // },
            // dark: {
            //     primary: '#054a91',
            //     secondary: '#3e7cb1',
            //     tertiary: '#81a4cd',
            //     accent: '#80deea',
            //     info: '#80deea ',
            //     error: '#FF5252',
            //     warning: '#F9A825',
            //     mute: '#666666'
            //     // success: 'teal',
            //     // warning: '#FFC107'
            // }
        }
    }
})

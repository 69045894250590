<template>
    <div class="movs">
        <div class="d-flex align-center mb-8">
            <h1>Movimenti</h1>

            <v-spacer></v-spacer>

            <v-btn
            to="/movs/new"
            small
            color="primary">Aggiungi movimento</v-btn>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------
            TABLE
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-data-table
        ref="table"
        id="page_movs"
        resource="movs"
        :headers="[
            { value: 'title' },
            { value: 'tipo' },
            { value: 'descrizione' },
            { value: 'location.from' },
            { value: 'location.to' },
            { value: 'date.from' },
            { value: 'date.to' },
            { value: 'contact.from' },
            { value: 'contact.to' },
            { value: 'note' }
        ]"
        model-name="Mov"
        to-create-item="/movs/new"
        to-update-item="/movs"
        @click:row="setForm($event.id)">
            <template #prepend-toolbar>
                <bxs-btn
                icon
                tooltip="Aggiungi nuovo movimento rapido"
                @click="setForm(null)">
                    <v-icon>post_add</v-icon>
                </bxs-btn>
            </template>

            <template #item.works="{ value }">
                {{ value.length }}
                <!-- <span @click="showWorks(item)">{{ value.map(i => i.title).join(',') }}</span> -->
            </template>

            <template #item.categories="{ value }">{{ value.join(',') }}</template>

            <!--  -->
            <template #item-actions="{ item }">
                <v-list-item
                v-if="item.works.length > 0"
                @click="showWorks(item)">
                    <v-list-item-icon>
                        <v-icon>open_in_new</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Visualizza opere ({{ item.works.length }})</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="item.works.length > 0"
                @click="downloadCsv(item)">
                    <v-list-item-icon>
                        <v-icon>download</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Scarica lista opere</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item @click="duplicate(id)">
                    <v-list-item-title>Duplica</v-list-item-title>
                </v-list-item> -->
            </template>
        </bxs-data-table>

        <!-- ------------------------------------------------------------------------------------------------------------
            rapid mov
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="form.on"
        title="Movimento"
        width="768">
            <div class="pa-4">
                <form-mov
                :key="form.on"
                ref="form_mov"
                :id="form.id"
                hide-header
                hide-footer
                class="py-4"
                @submit="submitForm" />
            </div>

            <template #actions>
                <bxs-btn
                color="primary"
                @click="$refs.form_mov.submit()">Salva</bxs-btn>
            </template>
        </bxs-modal>

        <!-- ------------------------------------------------------------------------------------------------------------
            opere
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="works.on"
        :title="`Opere nel movimento ${works.mov ? works.mov.title : ''}`"
        width="768">
            <v-list nav>
                <v-list-item
                v-for="work in works.items"
                :key="work.id">
                    <v-list-item-avatar>
                        <v-img :src="work.imgs.length > 0 ? work.imgs[0].url : '/img/no-photo.png'" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <p>{{ work.title }}</p>
                        <!-- <v-list-item-title>{{ work.title }}</v-list-item-title> -->
                        <v-list-item-subtitle>{{ work.anno }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </bxs-modal>
    </div>
</template>

<script>
import FormMov from '@/components/forms/Mov.vue'

export default {
    name: 'movs',
    metaInfo: {
      title: 'Movimenti opere'
    },
    components: {
        'form-mov': FormMov
    },
    data () {
        return {
            form: {
                on: null,
                id: null
            },
            works: {
                on: false,
                items: [],
                mov: null
            }
        }
    },
    methods: {
        setForm (id) {
            this.form.id = id || null
            this.form.on = !this.form.on
        },
        async submitForm (method, model, body, id) {
            this.$store.commit('loading', true)

            try {
                await this.$api.movs.save(id, body)

                this.form.id = null
                this.form.on = false

                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async duplicate (from_id) {
            this.$store.commit('loading', true)

            const item = await this.$api.movs.doc(from_id)
            delete item.created_at
            delete item.updated_at
            delete item.updated_user
            delete item.created_user
            delete item._id

            try {
                await this.$api.movs.create(item)
                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async downloadCsv (item) {
            this.$store.commit('loading', true)

            try {
                const fields = await this.$api.getSchema('Work')
                delete fields._id
                delete fields.uid
                delete fields.__v
                delete fields.status
                delete fields.id
                delete fields.created_at
                delete fields.updated_at
                delete fields.updated_user
                delete fields.created_user
                delete fields.old_id
                delete fields.number_id
                delete fields.imgs
                delete fields.movs
                delete fields['autentica.imgs']

                const query_fields = Object.keys(fields).sort()
                const query = `?access_token=${this.$api.auth.access_token}&mov_id=${item.id}&fields=${query_fields}`

                window.open(
                    `${this.$api.enums.origins.api}/v1/works/downloads/csv${query}`,
                    '_blank'
                )
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async showWorks (mov) {
            console.log('showWorks', mov)
            this.$store.commit('loading', true)

            try {
                this.works.mov = mov
                this.works.items = await this.$api.works.list({
                    pagination: false,
                    spread: 'imgs',
                    sort: { title: 1 },
                    filters: {
                        id: '[]|' + mov.works
                    }
                })

                this.works.on = true
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
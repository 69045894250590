<template>
    <v-menu
    v-model="show"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px">
        <template #activator="{ on, attrs }">
            <v-text-field
            :value="formattedVal"
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            :label="required ? '* ' + label : label" :placeholder="placeholder"
            readonly
            :filled="filled"
            :hide-details="hideDetails"
            :solo="solo"
            :dense="dense"
            outlined
            clearable
            :rules="computedRules"
            @click:clear="clear">
                <template #append>
                    <slot name="append" />
                </template>
            </v-text-field>
        </template>

        <v-date-picker
        v-model="cacheValue"
        :min="min"
        :max="max"
        no-title
        @input="handleInput" />
    </v-menu>
</template>

<script>
export default {
    name: 'date-field',
    props: {
        value: {
            type: [String, Date, Array],
            required: false,
            default: null
        },
        min: {
            type: [String, Date],
            required: false,
            default: null
        },
        max: {
            type: [String, Date],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        'value-type': {
            type: String,
            required: false,
            default: 'date',
            validator: v => [
                'schedule',
                'date'
            ].indexOf(v) !== -1
        }
    },
    computed: {
        formattedVal () {
            if (!this.cacheValue) return null

            let date = this.value
            if (this.valueType === 'schedule') date = new Date(...this.value)

            return this.$moment(date).format('LL')
        },
        computedRules () {
            let val = [v => !!v || 'Campo richiesto']
            if (!this.required) val = []
            return val
        }
    },
    data () {
        return {
            show: false,
            cacheValue: this.value
        }
    },
    methods: {
        handleInput () {
            this.show = false

            let val = this.$moment(new Date(this.cacheValue)).toDate()

            if (this.valueType === 'schedule') {
                val = this.$moment(new Date(this.cacheValue)).format('YYYY-M-DD')
                val = val.split('-')
                val = [
                    Number(val[0]),
                    Number(val[1] - 1),
                    Number(val[2])
                ]

                if (this.time) {
                    val = [...val, Number(this.time.split(':')[0]), Number(this.time.split(':')[1])]
                }
            }

            this.$emit('input', val)
        },
        clear () {
            this.cacheValue = null
            this.$emit('input', null)
        }
    }
}
</script>
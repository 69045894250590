<template>
    <div class="work-cassa-field">
        <div class="d-flex mb-4">
            <h4>Casse opera</h4>

            <v-spacer></v-spacer>

            <a
            rel="button"
            class="info--text"
            @click="add">Aggiungi riga</a>
        </div>

        <div
        v-if="!local_value.length"
        class="text-center">
            <p>Aggiungi una una riga per la cassa opera</p>

            <v-btn
            text
            color="info"
            @click="add">Aggiungi riga</v-btn>
        </div>

        <!--  -->
        <draggable
        v-else
        v-model="local_value"
        tag="ul"
        :disabled="local_value.length <= 1"
        ghost-class="draggable-ghost">
            <li
            v-for="(item, i) in local_value"
            :key="i"
            :class="['mb-1', {
                'dragging': local_value.length > 1
            }]">
                <div class="d-flex flex-nowrap">
                    <div v-if="local_value.length > 1">
                        <v-icon>drag_indicator</v-icon>
                    </div>

                    <v-spacer></v-spacer>

                    <v-btn
                    text
                    small
                    @click="remove(i)">Rimuovi</v-btn>
                </div>

                <div class="d-flex flex-nowrap">
                    <v-row>
                        <v-col cols="4">
                            <uploader
                            v-model="item.img"
                            item-max-width="60"
                            solo />
                        </v-col>

                        <v-col cols="8">
                            <v-text-field
                            v-model="item.titolo"
                            outlined
                            dense
                            hide-details
                            label="Titolo"
                            class="mb-2" />

                            <v-text-field
                            v-model="item.numero_riferimento"
                            outlined
                            dense
                            hide-details
                            label="Codice"
                            class="mb-2" />

                            <currency-field
                            v-model="item.peso"
                            :currency="null"
                            label="Peso"
                            suffix="Kg"
                            hide-details
                            class="mb-0" />
                        </v-col>
                    </v-row>
                </div>
            </li>
        </draggable>
    </div>
</template>

<script>
export default {
    name: 'work_cassa_field',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            local_value: this.value
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        value (newVal) {
            this.local_value = newVal
        },
        local_value (newVal) {
            this.$emit('input', newVal)
        }
    },
    methods: {
        start () {
            // if (!this.value.length) {
            //     this.add()
            // }
        },
        add () {
            this.local_value.push({
                titolo: null,
                numero_riferimento: null,
                peso: null,
                img: null
            })
        },
        remove (i) {
            this.local_value.splice(i, 1)
        }
    }
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'

import {
    auth,
    nextFactory
} from './middlewares'

import Home from '../views/Home.vue'
import Signin from '../views/Signin.vue'
import SigninRecovery from '../views/SigninRecovery.vue'
import SigninReset from '../views/SigninReset.vue'
import History from '../views/History.vue'
import ClearCache from '../views/ClearCache.vue'

import Works from '../views/Works.vue'
import Work from '../views/Work.vue'
import WorkSheet from '../views/WorkSheet.vue'
import Storage from '../views/Storage.vue'
import Contacts from '../views/Contacts.vue'
import Movs from '../views/Movs.vue'
import Mov from '../views/Mov.vue'
import File from '../views/File.vue'
import Logs from '../views/Logs.vue'
import Users from '../views/Users.vue'

import Settings from '../views/Settings.vue'
import SettingsProfile from '../views/children/settings/SettingsProfile.vue'
import SettingsAuths from '../views/children/settings/SettingsAuths.vue'
import SettingsMetaqueries from '../views/children/settings/SettingsMetaqueries.vue'
import SettingsMetaquery from '../views/children/settings/SettingsMetaquery.vue'
import SettingsMetaqueryEdit from '../views/children/settings/SettingsMetaqueryEdit.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/signin/recovery',
        name: 'signin_recovery',
        component: SigninRecovery
    },
    {
        path: '/signin/reset',
        name: 'signin_reset',
        component: SigninReset
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/cache/clear',
        name: 'cache_clear',
        component: ClearCache
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        meta: {
            middlewares: [auth]
        }
    },
    // ------------------------------------------------------------------------------
    {
        path: '/settings',
        // name: 'settings',
        component: Settings,
        meta: {
            middlewares: [auth]
        },
        children: [
            {
                path: '',
                redirect: 'me'
            },
            {
                path: 'me',
                name: 'settings_profile',
                component: SettingsProfile,
                meta: {
                    middlewares: [auth]
                }
            },
            {
                path: 'auths',
                name: 'settings_auths',
                component: SettingsAuths,
                meta: {
                    middlewares: [auth]
                }
            },
            {
                path: 'metaqueries',
                name: 'settings_metaqueries',
                component: SettingsMetaqueries,
                meta: {
                    middlewares: [auth]
                }
            },
            {
                path: 'metaqueries/:model',
                name: 'settings_metaquery',
                component: SettingsMetaquery,
                meta: {
                    middlewares: [auth]
                }
            },
            {
                path: 'metaqueries/:model/:id',
                name: 'settings_metaquery_edit',
                component: SettingsMetaqueryEdit,
                meta: {
                    middlewares: [auth]
                }
            }
        ]
    },
    // ------------------------------------------------------------------------------
    {
        path: '/works',
        name: 'works',
        component: Works,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/works/:id',
        name: 'work',
        component: Work,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/works/:id/sheet',
        name: 'work_sheet',
        component: WorkSheet,
        meta: {
            middlewares: [auth]
        }
    },
    // ------------------------------------------------------------------------------
    {
        path: '/movs',
        name: 'movs',
        component: Movs,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/movs/:id',
        name: 'mov',
        component: Mov,
        meta: {
            middlewares: [auth]
        }
    },
    // ------------------------------------------------------------------------------
    {
        path: '/storage',
        name: 'storage',
        component: Storage,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/files/:id',
        name: 'file',
        component: File,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/logs',
        name: 'logs',
        component: Logs,
        meta: {
            middlewares: [auth]
        }
    },
    // ------------------------------------------------------------------------------
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            middlewares: [auth]
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

const ctx = Vue.prototype

router.beforeEach((to, from, next) => {
    if (to.name !== 'signin') {
        localStorage.setItem('fp-app_last_page', to.fullPath)
    }

    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { ctx, from, to, next, router }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }
    return next()
})

export default router

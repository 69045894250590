<template>
    <div class="signin signin-recovery">
        <div>
            <v-card
            height="440"
            width="360">
                <!-- <div class="mb-8">
                    <v-img
                    src="/img/logo.png"
                    max-width="140" />
                </div> -->

                <div class="mb-8">
                    <h1>Recupero password</h1>
                    <p>Invia la tua mail per reimpostare la password</p>
                </div>

                <div>
                    <v-text-field
                    v-model="email"
                    :disabled="loading"
                    label="Email"
                    dense
                    hide-details
                    outlined
                    required
                    prepend-inner-icon="email" />

                    <v-btn
                    :disabled="!email"
                    block
                    color="primary"
                    :loading="loading"
                    @click="recovery">Invia</v-btn>

                    <v-alert
                    v-if="error"
                    border="left"
                    text
                    dense
                    type="error"
                    class="ma-0 my-4">
                        <small>{{ error }}</small>
                    </v-alert>
                </div>

                <v-btn
                text
                absolute
                bottom
                left
                x-small
                exact
                to="/signin">Accedi</v-btn>

                <v-btn
                text
                absolute
                bottom
                right
                x-small
                exact
                to="/signin/reset">Hai già un codice valido? Verifica</v-btn>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'signin_recovery',
    data () {
        return {
            email: '',
            error: null,
            loading: false,
            showPass: false
        }
    },
    methods: {
        async recovery () {
            this.loading = true
            this.error = null

            try {
                await this.$api.auth.passwordRecovery(this.email)
                this.$router.push('/signin/reset')
            } catch (err) {
                this.error = err.message || err.name || err
                throw new Error(this.error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
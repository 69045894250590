var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"files"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"model-name":"History","resource":"history","id":"table-files-view","headers":[
        { value: 'event' },
        { value: 'created_at' },
        { value: 'model' },
        { value: 'api' },
        { value: 'ref' },
        { value: 'event_data' },

        { value: 'params' },
        { value: 'query' },
        { value: 'body', },

        { value: 'user.id' },
        { value: 'user.email' },

        { value: 'controller' },
        { value: 'function' },

        { value: 'agent' }
    ],"hide-default-delete-item":_vm.$api.auth.is_admin,"static-filters":_vm.events.length > 0 ? { event: '[]|' + _vm.events.join(',') } : null},scopedSlots:_vm._u([{key:"append-toolbar",fn:function(){return [_c('v-chip-group',{attrs:{"multiple":"","active-class":"success"},model:{value:(_vm.events),callback:function ($$v) {_vm.events=$$v},expression:"events"}},[_c('v-chip',{attrs:{"value":"default","small":"","filter":"","outlined":""}},[_vm._v("Default")]),_c('v-chip',{attrs:{"value":"exception","small":"","filter":"","outlined":""}},[_vm._v("Exception")]),_c('v-chip',{attrs:{"value":"error","small":"","filter":"","outlined":""}},[_vm._v("Error")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toHumanDate")(item.created_at,'lll')))]}},{key:"item.api",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.http_method)+":"+_vm._s(item.endpoint))])]}},{key:"item.ref",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value))])]}},{key:"item.params",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value))])]}},{key:"item.query",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value))])]}},{key:"item.body",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value))])]}},{key:"item.event_data",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value))])]}},{key:"item.agent",fn:function(ref){
    var value = ref.value;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(JSON.stringify(value)),expression:"JSON.stringify(value)"}]},[_vm._v(_vm._s(value))])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h1',[_vm._v("Logs")])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_vm._m(0),_c('bxs-data-table',{ref:"table",attrs:{"id":"page_users","resource":"users","model-name":"User","hide-default-delete-item":"","headers":[
        { value: 'img' },
        { value: 'name.last' },
        { value: 'name.first' },
        { value: 'email' },
        { value: 'username' },
        { value: 'roles' }
    ],"static-query":{
        spread: 'img'
    },"show-select":false,"remove-fields":[
        'password',
        'pp_accepted',
        'cgv_accepted',
        'reset_password_code',
        'tokens.reset_password'
    ]},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('bxs-btn',{attrs:{"icon":"","tooltip":"Aggiungi nuovo utente"},on:{"click":function($event){return _vm.setForm(null)}}},[_c('v-icon',[_vm._v("add")])],1)]},proxy:true},{key:"item.img",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [_c('div',{staticClass:"py-2"},[_c('v-avatar',{attrs:{"size":"40","color":"secondary"}},[(value)?_c('v-img',{attrs:{"src":value.url}}):_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.name.first[0]))])],1)],1)]}},{key:"item-actions",fn:function(ref){
    var item = ref.item;
    var id = ref.id;
return [_c('v-list-item',{attrs:{"disabled":_vm.$api.auth.me.level < 5 && item.roles.includes('super_5')},on:{"click":function($event){return _vm.setForm(id)}}},[_c('v-list-item-title',[_vm._v("Modifica")])],1)]}}])}),_c('bxs-modal',{attrs:{"title":"Utente"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('bxs-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.user.submit()}}},[_vm._v("Salva")])]},proxy:true}]),model:{value:(_vm.form.on),callback:function ($$v) {_vm.$set(_vm.form, "on", $$v)},expression:"form.on"}},[(_vm.form.on)?_c('user',{ref:"user",attrs:{"id":_vm.form.id,"hide-header":"","hide-footer":""},on:{"submit":_vm.submit}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('h1',[_vm._v("Utenti")])])}]

export { render, staticRenderFns }
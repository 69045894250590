<template>
    <div class="settings-metaquery-edit">
        <form-metaquery
        ref="form"
        :id="$route.params.id"
        @submit="submit" />
    </div>
</template>

<script>
export default {
    name: 'settings_metaquery_edit',
    methods: {
        async submit(method, model, body, id, is_undo, undo) {
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.metaqueries.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') {
                    this.$router.push('/settings/metaqueries/' + this.$route.params.model + '/' + doc_id)
                }
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
<template>
    <div
    :class="['bxs-currency-field', {
        'currency-field-right': textAlign === 'right',
        'mb-0': hideDetails
    }]">
        <v-text-field
        ref="field"
        :value="formattedValue"
        v-currency="{
            locale: 'de-DE',
            currency: currency,
            autoDecimalMode: false,
            precision: parseInt(precision),
            valueAsInteger: valueIsInteger,
            allowNegative: allowNegative,
            distractionFree: true,
            valueRange: range
        }"
        type="text"
        :label="required && label ? '* ' + label : label"
        :placeholder="placeholder"
        append-icon=""
        :prepend-inner-icon="prependInnerIcon"
        :required="required"
        :rules="required ? [rules.required] : []"
        :persistent-hint="hint !== null"
        :clearable="clearable"
        :hint="hint"
        :single-line="singleLine"
        :suffix="suffix"
        :outlined="outlined"
        :filled="filled"
        :dense="dense"
        :solo="solo"
        :hide-details="hideDetails"
        :return-object="false"
        :flat="flat"
        :disabled="disabled"
        class="mb-0"
        @click:clear="clear"
        @input="handleInputGhost"
        @focus="handleFocus"
        @blur="handleBlur"
        @keyup="handleInput">
            <template #prepend>
                <slot name="prepend" />
            </template>

            <template #prepend-inner>
                <slot name="prepend-inner" />
            </template>

            <template #append>
                <slot name="append" />
            </template>

            <template #append-inner>
                <slot name="append-inner" />
            </template>
        </v-text-field>
    </div>
</template>

<script>
import { setValue, getValue } from 'vue-currency-input'

export default {
    name: 'bxs_currency_field',
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: 0
        },
        'emit-immediately': {
            type: Boolean,
            required: false,
            default: true
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        'prepend-inner-icon': {
            type: String,
            required: false,
            default: null
        },
        hint: {
            type: String,
            required: false,
            default: null
        },
        'allow-negative': {
            type: Boolean,
            required: false,
            default: true
        },
        suffix: {
            type: String,
            required: false,
            default: '€'
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        'single-line': {
            type: Boolean,
            required: false,
            default: false
        },
        precision: {
            type: [String, Number],
            required: false,
            default: 2
        },
        range: {
            type: Object,
            required: false,
            default: undefined
        },
        'value-is-integer': {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        currency: {
            type: String,
            required: false,
            default: 'EUR'
        },
        'text-align': {
            type: String,
            required: false,
            default: null
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            key: 0,
            formattedValue: null,
            local_is_focused: false,
            local_suggestions: [],
            rules: {
                required: (v) => !!v || 'Campo richiesto'
            }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        value (newVal, oldVal) {
            if (this.local_is_focused) return
            if (this.debug) console.log(`currency field ${this.name} watch value:`, newVal, oldVal)
            this.setValue(newVal)
        },
        precision () {
            this.setValue(this.value)
        }
    },
    methods: {
        start () {
            if (this.debug) console.log(`currency field ${this.name} start:`, this.value)

            this.setValue(this.value)
            this.$refs.field.$el.addEventListener('change', this.handleChange)

            // if (
            //     this.suggestions !== null &&
            //     typeof this.suggestions === 'object' &&
            //     typeof this.suggestions.then === 'function' &&
            //     typeof this.suggestions.catch === 'function'
            // ) {
            //     this.suggestions().then((val) => {
            //         this.local_suggestions = val
            //     })
            // } else {
            //     this.local_suggestions = this.suggestions
            // }
        },
        clear () {
            this.$emit('clear', null)
            this.formattedValue = null
            this.setValue(null)
            this.handleInput()
            this.handleChange()
        },
        handleInput () {
            const value = getValue(this.$refs.field)
            if (this.debug) console.log(`currency field ${this.name} handleInput:`, value)
            if (value === null) this.formattedValue = null
            this.$emit('input', value)
        },
        handleInputGhost (val) {
            this.formatLocalValue(val)
        },
        formatLocalValue (str_value) {
            if (this.debug) console.log(`currency field ${this.name} formatLocalValue:`, str_value)

            if (!str_value) {
                this.formattedValue = null
                return
            }

            this.formattedValue = String(str_value).replace(/\./g, '')

            /* eslint-disable-next-line */
            // const match = new RegExp(`/^\d+,\d{${this.precision - 1},${this.precision}}$/`).test(v)

            // if (this.precision && this.precision >= 4 && match) {
            //     this.formattedValue = v.substring(0, v.length - this.precision)
            // } else {
            //     this.formattedValue = v
            // }
        },
        handleFocus () {
            if (this.debug) console.log(`currency field ${this.name}: handleFocus`)
            this.local_is_focused = true
        },
        handleBlur () {
            if (this.debug) console.log(`currency field ${this.name}: handleBlur`)
            this.local_is_focused = false
            this.handleInput()
        },
        handleChange () {
            if (this.debug) console.log(`currency field ${this.name} handleChange:`, getValue(this.$refs.field))
            this.$emit('change', getValue(this.$refs.field))
        },
        setValue (value) {
            if (this.debug) console.log(`currency field ${this.name} setValue`, value)
            setValue(this.$refs.field, value)
            if (this.emitImmediately) this.handleInput()
        }
    }
}
</script>
import Resource from './BaseResource'

class File extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }

    pdf(template, id, query_string = '') {
        window.open(`${process.env.VUE_APP_API}${this.api}/${id}/${template}/pdf` + query_string, '_blank')
    }

    async csv(model, q = '') {
        // return this.tools.http.get(`${this.api}/${model}/csv`, q)
        window.open(`${process.env.VUE_APP_API}${this.api}/${model}/csv` + q, '_blank')
    }

    async share (id) {
        return this.tools.http.post(`${this.api}/${id}/access_token`)
    }
}

export default File

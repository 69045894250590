<template>
    <v-card class="pa-0">
        <div>
            <div class="d-flex align-center nowrap bxs-card-pa">
                <h3 class="mb-0">{{ title }}</h3>

                <v-spacer></v-spacer>

                <slot name="header-actions" />
            </div>

            <v-divider></v-divider>
        </div>

        <div class="bxs-card-pa py-8">
            <slot />
        </div>

        <div
        v-if="$scopedSlots.actions"
        class="bxs-card--actions bxs-card-pa">
            <slot name="actions"></slot>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'bxs_card',
    props: {
        title: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>
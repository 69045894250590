<template>
    <div class="file">
        <div v-if="loading">
            <loading />
        </div>

        <div v-else-if="!loading && item">
            <v-toolbar class="mb-6">
                <h1>{{ item.name }}</h1>

                <v-spacer></v-spacer>

                <bxs-btn
                disabled
                @click="download">
                    <v-icon left>download</v-icon>
                    <span>Download</span>
                </bxs-btn>
            </v-toolbar>

            <v-row>
                <v-col cols="4">
                    <v-img :src="item.url" />
                </v-col>

                <v-col cols="8">
                    <div
                    class="mb-6"
                    style="max-width: 320px;">
                        <div class="d-flex mb-2">
                            <h3>Dimensioni (px)</h3>
                            <v-spacer></v-spacer>
                            <h3>{{ item.dimension.w }} * {{ item.dimension.h }}</h3>
                        </div>

                        <div class="d-flex mb-2">
                            <h3>Tipologia</h3>
                            <v-spacer></v-spacer>
                            <h3>{{ item.mimetype }}</h3>
                        </div>

                        <div class="d-flex mb-2">
                            <h3>Rapporto</h3>
                            <v-spacer></v-spacer>
                            <h3>{{ item.aspect_ratio }}</h3>
                        </div>

                        <div class="d-flex">
                            <h3>Peso</h3>
                            <v-spacer></v-spacer>
                            <h3>{{ item.size.human }}</h3>
                        </div>
                    </div>

                    <v-row>
                        <v-col>
                            <v-text-field
                            v-model="item.name"
                            filled
                            dense
                            label="Nome"
                            @change="$api.files.modify(item.id, { name: $event })" />

                            <!-- <v-select
                            v-model="item.types"
                            :items="$api.enums.file.types"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            filled
                            dense
                            label="Tipologia"
                            @change="$api.files.modify(item.id, { types: $event })" /> -->

                            <v-combobox
                            v-model="item.fotografo"
                            :items="$api.enums.fotografi"
                            filled
                            dense
                            label="Fotografo"
                            @change="$api.files.modify(item.id, { fotografo: $event })" />

                            <v-combobox
                            v-model="item.luogo_scatto"
                            :items="$api.enums.luoghi_scatto"
                            filled
                            dense
                            label="Luogo scatto"
                            @change="$api.files.modify(item.id, { luogo_scatto: $event })" />

                            <date-field
                            v-model="item.data_scatto"
                            filled
                            dense
                            label="Data scatto"
                            @input="$api.files.modify(item.id, { data_scatto: $event })" />
                        </v-col>

                        <v-col>
                            <!-- <v-textarea
                            v-model="item.info"
                            filled
                            dense
                            label="Info"
                            @change="$api.files.modify(item.id, { info: $event })" /> -->

                            <v-textarea
                            v-model="item.note"
                            filled
                            dense
                            label="Note"
                            @change="$api.files.modify(item.id, { note: $event })" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <div v-else>
            <v-icon
            size="120"
            class="mb-2">image</v-icon>
            <h1 class="mb-1">404</h1>
            <p>Nessuna immagine trovata {{ $route.params.id }}.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'file',
    metaInfo: {
      title: 'Dettagli file'
    },
    data () {
        return {
            item: null,
            loading: true
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            this.item = await this.$api.files.doc(this.$route.params.id)
            this.loading = false
        },
        download () {
        }
    }
}
</script>
<template>
    <div class="home">
        <loading v-if="loading" />

        <div v-else>
            <div class="mb-8">
                <h2>Dashboard</h2>

                <!-- <v-btn
                v-if="$api.env === 'development'"
                @click="getStatus">getStatus()</v-btn> -->
                <!--
                    md  960px > < 1264px
                    lg  1264px > < 1904px
                    xl  4k and ultra-wide > 1904px
                -->
            </div>

            <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
            -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- <v-row>
                <v-col cols="4">
                    <v-card>
                        <div class="mb-8">
                            <p class="mute--text">Opere</p>
                        </div>

                        <v-row>
                            <v-col>
                                <h1>{{ status.works_count }}</h1>
                            </v-col>

                            <v-col class="text-right">
                                <p class="success--text">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="currentColor"
                                    class="mr-1">
                                        <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
                                        <circle cx="1" cy="8" r="1"></circle>
                                        <rect x="0" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)" width="7.1" height="2"></rect>
                                    </svg>
                                    <span>00,0%</span>
                                </p>

                                <small class="mute--text">Compared to April 2023</small>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="4">
                    <v-card>
                        <div class="mb-8">
                            <p class="mute--text">Dato 2</p>
                        </div>

                        <v-row>
                            <v-col>
                                <h1>{{ status.works_count }}</h1>
                            </v-col>

                            <v-col class="text-right">
                                <p class="success--text">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="currentColor"
                                    class="mr-1">
                                        <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
                                        <circle cx="1" cy="8" r="1"></circle>
                                        <rect x="0" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)" width="7.1" height="2"></rect>
                                    </svg>
                                    <span>00,0%</span>
                                </p>

                                <small class="mute--text">Compared to April 2023</small>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="4">
                    <v-card>
                        <div class="mb-8">
                            <p class="mute--text">Dato 3</p>
                        </div>

                        <v-row>
                            <v-col>
                                <h1>{{ status.works_count }}</h1>
                            </v-col>

                            <v-col class="text-right">
                                <p class="success--text">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="currentColor"
                                    class="mr-1">
                                        <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
                                        <circle cx="1" cy="8" r="1"></circle>
                                        <rect x="0" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)" width="7.1" height="2"></rect>
                                    </svg>
                                    <span>00,0%</span>
                                </p>

                                <small class="mute--text">Compared to April 2023</small>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->

            <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
            -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- <v-row>
                <v-col cols="3">
                    <v-alert
                    border="left"
                    class="pt-2 pl-6">
                        <h1>12</h1>

                        <div class="d-flex">
                            <p>Opere in draft</p>

                            <v-spacer></v-spacer>

                            <router-link to="/orders">
                                <span>Visualizza</span>
                                <v-icon>chevron_right</v-icon>
                            </router-link>
                        </div>
                    </v-alert>
                </v-col>

                <v-col cols="3">
                    <v-alert
                    border="left"
                    class="pt-2 pl-6">
                        <h1>32</h1>

                        <div class="d-flex">
                            <p>Opere in draft</p>

                            <v-spacer></v-spacer>

                            <router-link to="/orders">
                                <span>Visualizza</span>
                                <v-icon>chevron_right</v-icon>
                            </router-link>
                        </div>
                    </v-alert>
                </v-col>

                <v-col cols="3">
                    <v-alert
                    border="left"
                    class="pt-2 pl-6">
                        <h1>44</h1>

                        <div class="d-flex">
                            <p>Opere in draft</p>

                            <v-spacer></v-spacer>

                            <router-link to="/orders">
                                <span>Visualizza</span>
                                <v-icon>chevron_right</v-icon>
                            </router-link>
                        </div>
                    </v-alert>
                </v-col>

                <v-col cols="3">
                    <v-alert
                    border="left"
                    class="pt-2 pl-6">
                        <h1>7</h1>

                        <div class="d-flex">
                            <p>Opere in draft</p>

                            <v-spacer></v-spacer>

                            <router-link to="/orders">
                                <span>Visualizza</span>
                                <v-icon>chevron_right</v-icon>
                            </router-link>
                        </div>
                    </v-alert>
                </v-col>
            </v-row> -->

            <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
            -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- <v-row class="mb-12">
                <v-col cols="6" sm="4" md="3" lg="2" xl="3">
                    <v-card class="text-center">
                        <h1 class="mb-3">{{ status.works_count }}</h1>
                        <p>Opere</p>
                    </v-card>
                </v-col>

                <v-col cols="6" sm="4" md="3" lg="2" xl="3">
                    <v-card class="text-center">
                        <h1 class="mb-3">{{ status.contacts_count }}</h1>
                        <p>Contatti</p>
                    </v-card>
                </v-col>

                <v-col cols="6" sm="4" md="3" lg="2" xl="3">
                    <v-card class="text-center">
                        <h1 class="mb-3">{{ status.users_count }}</h1>
                        <p>Utenti</p>
                    </v-card>
                </v-col>
            </v-row> -->

            <!-- <div class="mt-12">
                <v-toolbar
                dense
                class="mb-3">
                    <h2>Ultime opere caricate</h2>
                </v-toolbar>

                <v-row class="mb-12">
                    <v-col
                    v-for="item in status.last_created_works"
                    :key="item.id"
                    cols="6" sm="4" md="3" lg="2" xl="3"
                    class="text-center"
                    @click="$router.push(`/works/${item.id}/sheet`)">
                        <v-img
                        :src="item.imgs.length ? item.imgs[0].url : '/img/no-photo.png'"
                        class="mb-3" />

                        <p>{{ item.title }}</p>
                    </v-col>
                </v-row>
            </div> -->

            <!-- <div class="mt-12">
                <v-toolbar
                dense
                class="mb-3">
                    <h2>Ultime opere modificate</h2>
                </v-toolbar>

                <v-row class="mb-12">
                    <v-col
                    v-for="item in status.last_updated_works"
                    :key="item.id"
                    cols="6" sm="4" md="3" lg="2" xl="3"
                    class="text-center"
                    @click="$router.push(`/works/${item.id}/sheet`)">
                        <v-img
                        :src="item.imgs.length ? item.imgs[0].url : '/img/no-photo.png'"
                        class="mb-3" />

                        <p>{{ item.title }}</p>
                    </v-col>
                </v-row>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'home',
    data () {
        return {
            loading: true,
            status: null
        }
    },
    created () {
        this.start()
    },
    methods: {
        async start () {
            this.status = await this.$api.getStatus()
            this.loading = false
        },
        async getStatus () {
            this.status = await this.$api.getStatus()
        }
    }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-metaquery"},[_c('div',{staticClass:"mb-8"},[_c('v-btn',{attrs:{"text":"","small":"","plain":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("chevron_left")]),_c('span',[_vm._v("Indietro")])],1),_c('h2',[_vm._v("Meta query della risorsa "+_vm._s(_vm.$route.params.model))]),_c('v-divider',{staticClass:"my-8"})],1),_c('v-card',{staticClass:"pa-0"},[_c('bxs-data-table',{ref:"table",attrs:{"model-name":"MetaQuery","resource":"metaqueries","to-create-item":("/settings/metaqueries/" + (_vm.$route.params.model) + "/new"),"to-update-item":("/settings/metaqueries/" + (_vm.$route.params.model)),"id":"table-settings-metaqueries-view","headers":[
            { text: 'Nome', value: 'name' },
            { text: 'Entità', value: 'model' },
            { text: 'Campo', value: 'field' },
            { text: 'Condizione', value: 'operator' },
            { text: 'Entità legata', value: 'comparison.model' },
            { text: 'Dove è usato', value: '' }
        ],"static-filters":{
            model: '=|' + _vm.$route.params.model
        }},scopedSlots:_vm._u([{key:"item.field",fn:function(ref){
        var value = ref.value;
return [_vm._v(_vm._s(value || '--'))]}},{key:"item.operator",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.operator)+" "+_vm._s(item.comparison.value))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['bxs-currency-field', {
    'currency-field-right': _vm.textAlign === 'right',
    'mb-0': _vm.hideDetails
}]},[_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
        locale: 'de-DE',
        currency: _vm.currency,
        autoDecimalMode: false,
        precision: parseInt(_vm.precision),
        valueAsInteger: _vm.valueIsInteger,
        allowNegative: _vm.allowNegative,
        distractionFree: true,
        valueRange: _vm.range
    }),expression:"{\n        locale: 'de-DE',\n        currency: currency,\n        autoDecimalMode: false,\n        precision: parseInt(precision),\n        valueAsInteger: valueIsInteger,\n        allowNegative: allowNegative,\n        distractionFree: true,\n        valueRange: range\n    }"}],ref:"field",staticClass:"mb-0",attrs:{"value":_vm.formattedValue,"type":"text","label":_vm.required && _vm.label ? '* ' + _vm.label : _vm.label,"placeholder":_vm.placeholder,"append-icon":"","prepend-inner-icon":_vm.prependInnerIcon,"required":_vm.required,"rules":_vm.required ? [_vm.rules.required] : [],"persistent-hint":_vm.hint !== null,"clearable":_vm.clearable,"hint":_vm.hint,"single-line":_vm.singleLine,"suffix":_vm.suffix,"outlined":_vm.outlined,"filled":_vm.filled,"dense":_vm.dense,"solo":_vm.solo,"hide-details":_vm.hideDetails,"return-object":false,"flat":_vm.flat,"disabled":_vm.disabled},on:{"click:clear":_vm.clear,"input":_vm.handleInputGhost,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keyup":_vm.handleInput},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true},{key:"append-inner",fn:function(){return [_vm._t("append-inner")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
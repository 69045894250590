<template>
    <v-text-field
    v-model="cacheValue"
    :label="label"
    :dense="dense"
    :filled="filled"
    single-line
    :flat="flat"
    :solo="solo"
    :full-width="fullWidth"
    :hide-details="hideDetails"
    :clearable="clearable"
    :outlined="outlined"
    :loading="loading"
    :prepend-inner-icon="prependInnerIcon"
    @click:clear="updateValue(null)"
    @change="$emit('change', $event)"
    @keyup="handleKeyUp"
    @keydown="handleKeyDown">
        <template #append>
            <slot name="append" />
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'search-field',
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: 'Cerca'
        },
        'prepend-inner-icon': {
            type: String,
            required: false,
            default: 'search'
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        'full-width': {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: true
        },
        'debounce-delay': {
            type: Number,
            required: false,
            default: 500
        }
    },
    data () {
        return {
            cacheValue: this.value,
            typingTimer: null
        }
    },
    watch: {
        value (newVal) {
            this.cacheValue = newVal
        }
    },
    methods: {
        handleKeyUp (evt) {
            this.$emit('typing')
            clearTimeout(this.typingTimer)
            this.typingTimer = setTimeout(() => this.updateValue(evt.target.value), this.debounceDelay)
        },
        handleKeyDown (evt) {
            clearTimeout(this.typingTimer)
        },
        updateValue (v) {
            console.log('updateValue', v)
            this.$emit('input', v)
        }
    }
}
</script>
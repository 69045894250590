<template>
    <div
    :key="key"
    class="work boxed">
        <formizer
        ref="formizer"
        model="Work"
        undo="/works"
        :id="$route.params.id"
        @draw-completed="drawComplete"
        @submit="submit">
            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #header-toolbar="{ doc, method }">
                <div class="d-flex align-start">
                    <v-btn
                    icon
                    class="mr-2"
                    @click="$router.go(-1)">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>

                    <h2
                    v-if="method === 'post'"
                    class="mb-0">Aggiungi opera</h2>
                    <h2
                    v-else
                    class="mb-0">{{ doc.title }}</h2>
                </div>
            </template>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #header-menu="{ method, doc }">
                <span v-if="method === 'put'">
                    <v-list-item to="/works/new">
                        <v-list-item-icon>
                            <v-icon>add</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Aggiungi nuova</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                    to="/works/new"
                    @click="duplicate">
                        <v-list-item-icon>
                            <v-icon>content_copy</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Duplica</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                    v-if="$api.auth.is_dev"
                    :to="`/works/${doc.id}/sheet`">
                        <v-list-item-icon>
                            <v-icon>open_in_new</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Visualizza scheda</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </span>
            </template>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                body
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <template #body="{ fields, card, doc, rules }">
                <v-alert
                v-if="errors"
                type="error"
                border="left"
                min-height="80"
                text
                outlined>
                    <div class="text-h6">Si è verificato 1 errore:</div>
                    <div>{{ errors }}</div>
                </v-alert>

                <v-row>
                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        col l
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="8">
                        <div class="d-flex flex-nowrap mb-2">
                            <uploader
                            ref="uploaderFrontImg"
                            v-model="fields.front_img.model"
                            label="Immagine fronte"
                            class="mr-1" />

                            <uploader
                            ref="uploaderBackImg"
                            v-model="fields.back_img.model"
                            label="Immagine retro"
                            class="ml-1" />
                        </div>

                        <uploader
                        ref="uploader"
                        label="Immagini particolari"
                        v-model="fields.imgs.model"
                        multiple
                        class="mb-6" />

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            base
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Informazioni base</h3>
                            </div>

                            <v-textarea
                            v-model="fields.title.model"
                            label="* Titolo"
                            auto-grow
                            clearable
                            no-resize
                            dense
                            outlined
                            counter
                            rows="3"
                            :rules="[rules.required]" />

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="fields.numero.model"
                                    label="Numero / ID"
                                    dense
                                    outlined />
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field
                                    v-model="fields.anno.model"
                                    label="Anno"
                                    dense
                                    outlined />
                                </v-col>
                            </v-row>

                            <v-combobox
                            v-model="fields.tecnica.model"
                            :items="$api.enums.work_tecniche"
                            dense
                            outlined
                            label="Tecnica" />

                            <v-combobox
                            v-model="fields.supporto.model"
                            :items="$api.enums.work_supporti"
                            :return-object="false"
                            dense
                            outlined
                            label="Supporto" />

                            <v-text-field
                            v-model="fields.soggetto.model"
                            dense
                            outlined
                            label="Soggetto" />
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            codes
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <!-- <v-card v-bind="card">
                            <div class="mb-8">
                                <h2>Codici</h2>
                            </div>

                            <v-text-field
                            v-model="fields.numero.model"
                            label="Numero / ID"
                            single-line
                            dense
                            outlined />

                            <v-text-field
                            v-model="fields.anno.model"
                            label="Anno"
                            single-line
                            dense
                            outlined />
                        </v-card> -->

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            dimensioni
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Dimensioni</h3>
                            </div>

                            <v-row>
                                <v-col cols="6">
                                    <currency-field
                                    v-model="fields.dimensioni.height.model"
                                    :currency="null"
                                    label="Altezza"
                                    suffix="Cm"
                                    dense
                                    outlined />
                                </v-col>

                                <v-col cols="6">
                                    <currency-field
                                    v-model="fields.dimensioni.width.model"
                                    :currency="null"
                                    label="Larghezza"
                                    suffix="Cm"
                                    dense
                                    outlined />
                                </v-col>

                                <v-col cols="6">
                                    <currency-field
                                    v-model="fields.dimensioni.depth.model"
                                    :currency="null"
                                    label="Profondità"
                                    suffix="Cm"
                                    dense
                                    outlined />
                                </v-col>

                                <v-col cols="6">
                                    <currency-field
                                    v-model="fields.dimensioni.weight.model"
                                    :currency="null"
                                    label="Peso"
                                    suffix="Kg"
                                    dense
                                    outlined />
                                </v-col>
                            </v-row>
                        </v-card>

                        <!--  ------------------------------------------------------------------------------------------------------------------------------------------------
                            taxonomies
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Organizzazione</h3>
                            </div>

                            <v-row>
                                <v-col cols="6">
                                    <v-combobox
                                    v-model="fields.categoria.model"
                                    :items="$api.enums.work_categorie"
                                    chips
                                    small-chips
                                    deletable-chips
                                    :return-object="false"
                                    :multiple="false"
                                    clearable
                                    outlined
                                    dense
                                    label="Categoria" />
                                </v-col>

                                <v-col>
                                    <v-combobox
                                    v-model="fields.tipologia.model"
                                    :items="$api.enums.work_tipologie"
                                    outlined
                                    dense
                                    label="Tipologia" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-combobox
                                    v-model="fields.collezione.model"
                                    :items="$api.enums.work_collezioni"
                                    outlined
                                    dense
                                    label="Collezione" />
                                </v-col>

                                <v-col cols="6">
                                    <v-combobox
                                    v-model="fields.collezione_desunta.model"
                                    :items="$api.enums.work_collezioni_desunte"
                                    outlined
                                    dense
                                    label="Collezione desunta" />
                                </v-col>
                            </v-row>
                        </v-card>

                        <!--  ------------------------------------------------------------------------------------------------------------------------------------------------
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Valori</h3>
                            </div>

                            <v-row>
                                <v-col cols="6">
                                    <currency-field
                                    v-model="fields.valore_opera.model"
                                    label="Valore opera"
                                    dense
                                    outlined
                                    suffix="€" />
                                </v-col>

                                <v-col cols="6">
                                    <currency-field
                                    v-model="fields.valore_assicurativo.model"
                                    label="Valore assicurativo"
                                    dense
                                    outlined
                                    suffix="€" />
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            cassa
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card
                        v-bind="card"
                        class="px-0">
                            <div>
                                <div class="bxs-card-px">
                                    <div class="mb-8">
                                        <h3>Collocazione e cassa</h3>
                                    </div>

                                    <div>
                                        <v-text-field
                                        v-model="fields.collocazione.model"
                                        outlined
                                        dense
                                        label="Collocazione" />

                                        <v-text-field
                                        v-model="fields.tipo_imballo.model"
                                        dense
                                        outlined
                                        label="Tipo imballo" />

                                        <v-checkbox
                                        v-model="fields.cassa.è_presente.model"
                                        label="Opera con una o più casse"
                                        single-line
                                        class="ma-0 pa-0 mt-4" />
                                    </div>
                                </div>

                                <v-divider
                                v-if="fields.cassa.è_presente.model"
                                class="my-4"></v-divider>
                            </div>

                            <div
                            v-if="fields.cassa.è_presente.model"
                            class="bxs-card-px">
                                <work-cassa-field v-model="fields.cassa.value.model" />
                            </div>
                        </v-card>

                        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                            autentica
                        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                        <v-card
                        v-bind="card"
                        class="px-0">
                            <div>
                                <div class="bxs-card-px">
                                    <div class="mb-8">
                                        <h3>Autentica</h3>
                                    </div>

                                    <div>
                                        <v-checkbox
                                        v-model="fields.autentica.è_presente.model"
                                        label="Opera autenticata"
                                        single-line
                                        class="ma-0 pa-0" />
                                    </div>
                                </div>

                                <v-divider
                                v-if="fields.autentica.è_presente.model"
                                class="my-4"></v-divider>
                            </div>

                            <div
                            v-if="fields.autentica.è_presente.model"
                            class="bxs-card-px">
                                <uploader
                                ref="uploader_autentica"
                                label="Immagini"
                                v-model="fields.autentica.imgs.model"
                                multiple
                                class="mb-6" />

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                        v-model="fields.autentica.richiedente.model"
                                        dense
                                        outlined
                                        label="Richiedente" />
                                    </v-col>

                                    <v-col cols="6">
                                        <v-text-field
                                        v-model="fields.autentica.data_rilascio.model"
                                        dense
                                        outlined
                                        label="Data rilascio Pistoletto" />
                                    </v-col>
                                </v-row>

                                <v-textarea
                                v-model="fields.autentica.info.model"
                                dense
                                outlined
                                label="Note autentica" />
                            </div>
                        </v-card>

                        <v-card v-bind="card">
                            <div class="mb-8">
                                <h3>Extra</h3>
                            </div>

                            <v-textarea
                            v-model="fields.iscrizioni_su_retro.model"
                            outlined
                            dense
                            label="Iscrizioni su retro" />

                            <v-textarea
                            v-model="fields.bibliografia.model"
                            outlined
                            dense
                            label="Bibliografia" />

                            <v-textarea
                            v-model="fields.note.model"
                            outlined
                            dense
                            label="Note"
                            class="mb-0" />
                        </v-card>

                        <!-- <v-card v-bind="card">
                            <rapid-movs
                            v-if="doc && doc.id"
                            :work-id="doc.id" />

                            <div
                            v-else
                            class="text-center">
                                <v-icon size="80" class="mb-6">animation</v-icon>
                                <p>
                                    <span v-if="method === 'post'">Salva l'opera prima di creare movimenti</span>
                                    <span v-else>
                                        <span>Salva l'opera prima di creare movimenti</span>
                                        <bxs-btn
                                        v-if="!!fields.title.model"
                                        color="primary"
                                        class="ml-3"
                                        small
                                        @click="$refs.formizer.submit()">salva</bxs-btn>
                                    </span>
                                </p>
                            </div>
                        </v-card> -->
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                        aside
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="4">
                        <div class="sticky sticky-spacer-top background">
                            <!--  -->
                            <page-details
                            :src="doc && doc.front_img ? doc.front_img : null"
                            :title="doc ? doc.title : null"
                            :description="doc ? doc.description : null" />

                            <v-card class="mb-4">
                                <div>
                                    <p class="bxs-input-label">Numero / ID</p>
                                    <v-text-field
                                    v-model="fields.numero.model"
                                    single-line
                                    dense
                                    outlined />
                                </div>

                                <div>
                                    <p class="bxs-input-label">Anno</p>
                                    <v-text-field
                                    v-model="fields.anno.model"
                                    single-line
                                    dense
                                    outlined />
                                </div>

                                <div>
                                    <p class="bxs-input-label">Numero quaderno serigrafie</p>
                                    <qty-field
                                    v-model="fields.numero_quaderno_serigrafie.model"
                                    single-line
                                    dense
                                    outlined
                                    hide-details
                                    class="mb-0" />
                                </div>
                            </v-card>

                            <v-card class="mb-4">
                                <v-row>
                                    <v-col cols="6">
                                        <div>
                                            <p class="bxs-input-label">Altezza</p>
                                            <currency-field
                                            v-model="fields.dimensioni.height.model"
                                            :currency="null"
                                            label="Altezza"
                                            suffix="Cm"
                                            dense
                                            single-line
                                            outlined />
                                        </div>
                                    </v-col>

                                    <v-col cols="6">
                                        <div>
                                            <p class="bxs-input-label">Larghezza</p>
                                            <currency-field
                                            v-model="fields.dimensioni.width.model"
                                            :currency="null"
                                            label="Lunghezza"
                                            suffix="Cm"
                                            dense
                                            single-line
                                            outlined />
                                        </div>
                                    </v-col>

                                    <v-col cols="6">
                                        <div>
                                            <p class="bxs-input-label">Profondità</p>
                                            <currency-field
                                            v-model="fields.dimensioni.depth.model"
                                            :currency="null"
                                            label="Profondità"
                                            suffix="Cm"
                                            dense
                                            single-line
                                            outlined
                                            class="mb-0" />
                                        </div>
                                    </v-col>

                                    <v-col cols="6">
                                        <div>
                                            <p class="bxs-input-label">Peso</p>
                                            <currency-field
                                            v-model="fields.dimensioni.weight.model"
                                            :currency="null"
                                            label="Peso"
                                            suffix="Kg"
                                            single-line
                                            outlined
                                            hide-details
                                            class="mb-0" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-card>
                                <div class="d-flex align-center mb-2">
                                    <p class="bxs-input-label ma-0 pa-0">Quaderno veline</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.in_quaderno_veline.model"
                                    inset
                                    dense
                                    single-line
                                    hide-details
                                    class="d-inline ma-0 pa-0" />
                                </div>

                                <div class="d-flex align-center mb-2">
                                    <p class="bxs-input-label ma-0 pa-0">Quaderno serigrafie</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.in_quaderno_serigrafie.model"
                                    inset
                                    dense
                                    single-line
                                    hide-details
                                    class="d-inline ma-0 pa-0" />
                                </div>

                                <div class="d-flex align-center mb-2">
                                    <p class="bxs-input-label ma-0 pa-0">Firmata</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.è_firmata.model"
                                    inset
                                    dense
                                    single-line
                                    hide-details
                                    class="d-inline ma-0 pa-0" />
                                </div>

                                <div class="d-flex align-center">
                                    <p class="bxs-input-label ma-0 pa-0">Multiplo</p>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                    v-model="fields.è_multiplo.model"
                                    inset
                                    dense
                                    single-line
                                    hide-details
                                    class="d-inline ma-0 pa-0" />
                                </div>
                            </v-card>
                        </div>

                        <!-- <document-history
                        v-if="method === 'put'"
                        model="Work"
                        :id="id" /> -->
                    </v-col>
                </v-row>
            </template>
        </formizer>
    </div>
</template>

<script>
// import RapidMovs from '@/components/RapidMovs.vue'
import WorkCassaField from '@/components/WorkCassaField.vue'

export default {
    name: 'work',
    metaInfo: {
      title: 'Opera'
    },
    components: {
        // 'rapid-movs': RapidMovs,
        'work-cassa-field': WorkCassaField
    },
    data () {
        return {
            key: 1,
            errors: null,
            doc: null
        }
    },
    watch: {
        tab (newVal) {
            // this.$router.push({ hash: '#' + newVal }).catch(() => {})
        }
    },
    mounted () {
        this.$nextTick(() => {
            window.scrollTo(0, 0)

            this.$el.addEventListener('click', (evt) => {
                console.log('click page', evt)
                this.last_clicked_el = evt.target
            })
        })
    },
    methods: {
        drawComplete (method, fieldObj, doc, next) {
            this.doc = doc
            if (doc) this.imgs = doc.imgs

            this.$nextTick(() => {
                if (this.last_clicked_el) {
                    this.last_clicked_el.scrollIntoView({
                        block: 'center',
                        inline: 'center',
                        behavior: 'smooth'
                    })
                } else {
                    window.scrollTo(0, 0)
                }
            })

            next()
        },
        async duplicate () {
            const item = JSON.parse(JSON.stringify(this.doc))
            delete item.id
            delete item.movs
            item.title = item.title + ' - duplicato'

            try {
                const doc = await this.$api.works.create(item)
                this.$router.push('/works/' + doc.id)
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        async submit (method, model, body, id, is_undo, undo) {
            this.errors = null
            this.$store.commit('loading', true)

            try {
                const doc_id = await this.$api.works.save(id, body)

                if (is_undo) {
                    if (undo) return this.$router.push(undo)
                    else return this.$router.go(-1)
                }

                if (method === 'post') {
                    this.$router.push(`/works/${doc_id}`)
                } else {
                    this.key++
                }
            } catch (err) {
                this.errors = err
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.scrollable {
    height: 50vh;
}
</style>
<template>
    <div class="users">
        <div class="mb-6">
            <h1>Utenti</h1>
        </div>

        <bxs-data-table
        ref="table"
        id="page_users"
        resource="users"
        model-name="User"
        hide-default-delete-item
        :headers="[
            { value: 'img' },
            { value: 'name.last' },
            { value: 'name.first' },
            { value: 'email' },
            { value: 'username' },
            { value: 'roles' }
        ]"
        :static-query="{
            spread: 'img'
        }"
        :show-select="false"
        :remove-fields="[
            'password',
            'pp_accepted',
            'cgv_accepted',
            'reset_password_code',
            'tokens.reset_password'
        ]">
            <template #toolbar>
                <bxs-btn
                icon
                tooltip="Aggiungi nuovo utente"
                @click="setForm(null)">
                    <v-icon>add</v-icon>
                </bxs-btn>
            </template>

            <template #item.img="{ item, value }">
                <div class="py-2">
                    <v-avatar
                    size="40"
                    color="secondary">
                        <v-img
                        v-if="value"
                        :src="value.url" />

                        <span
                        v-else
                        class="text-uppercase">{{ item.name.first[0] }}</span>
                    </v-avatar>
                </div>
            </template>

            <template #item-actions="{ item, id }">
                <v-list-item
                :disabled="$api.auth.me.level < 5 && item.roles.includes('super_5')"
                @click="setForm(id)">
                    <v-list-item-title>Modifica</v-list-item-title>
                </v-list-item>
            </template>
        </bxs-data-table>

        <!-- contact --------------------------------------------------------------------------------------------------------------- -->
        <bxs-modal
        v-model="form.on"
        title="Utente">
            <user
            ref="user"
            v-if="form.on"
            :id="form.id"
            hide-header
            hide-footer
            @submit="submit" />

            <template #actions>
                <bxs-btn
                color="primary"
                @click="$refs.user.submit()">Salva</bxs-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import User from '@/components/forms/User.vue'

export default {
    name: 'users',
    metaInfo: {
      title: 'Utenti'
    },
    components: {
        user: User
    },
    data () {
        return {
            form: {
                on: null,
                id: null
            }
        }
    },
    methods: {
        setForm (id) {
            this.form.id = id
            this.form.on = !this.form.on
        },
        async submit (method, model, body, id, isUndo, undo) {
            this.$store.commit('loading', true)

            try {
                await this.$api.users.save(id, {
                    ...body,
                    roles: ['employee_4']
                })
                this.form.on = false
                this.$refs.table.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.$store.commit('loading', false)
            }
        }
    }
}
</script>
<template>
    <v-app-bar
    app
    :color="$vuetify.theme.themes[$store.state.theme].navbar"
    fixed
    clipped-right
    clipped-left
    height="60"
    class="px-2">
        <!-- <v-app-bar-nav-icon
        class="mr-2"
        @click="$store.commit('set_is_open_menu')"></v-app-bar-nav-icon> -->

        <v-img
        src="/img/icons/android-chrome-192x192.png"
        max-width="30" />

        <p class="mb-0 pb-0 ml-2">Archivio MP</p>

        <v-spacer></v-spacer>

        <v-switch
        v-if="$api.auth.is_admin"
        v-model="show_all"
        dense
        hide-details
        single-line
        label="Vedi monografia"
        class="d-inline ma-0 pa-0" />

        <span
        v-if="$api.auth.is_dev"
        class="d-flex mr-3">
            <v-icon>dark_mode</v-icon>

            <v-switch
            v-model="$vuetify.theme.isDark"
            :disabled="!$api.is_super"
            dense
            hide-details
            single-line
            class="d-inline ma-0 pa-0 ml-2"
            @change="toggleTheme" />
        </span>

        <bxs-btn
        disabled
        tooltip="Notifiche"
        icon
        class="mr-3">
            <v-icon>notifications</v-icon>
        </bxs-btn>

        <!-- menu -->
        <!-- :close-on-click="false"
        :close-on-content-click="false" -->
        <v-menu
        offset-y
        disable-keys
        nudge-bottom="10">
            <template #activator="{ on, attrs }">
                <v-avatar
                v-on="on"
                v-bind="attrs"
                color="primary"
                size="40"
                class="text-uppercase white--text">
                    <v-img v-if="$api.auth.me.img" :src="$api.auth.me.img" />
                    <span
                    v-else
                    style="font-size: 1rem;">{{ $api.auth.me ? $api.auth.me.email[0] : '' }}</span>
                </v-avatar>
            </template>

            <v-list
            nav
            dense>
                <v-list-item
                to="/settings/me"
                exact
                dense>
                    <v-list-item-content>
                        <v-list-item-title>Account</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                to="/settings"
                exact
                dense>
                    <v-list-item-content>
                        <v-list-item-title>Impostazioni</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="$api.auth.is_dev">
                    <v-list-item-icon>
                        <v-icon>dark_mode</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Tema colori scuri</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-switch
                        v-model="$vuetify.theme.isDark"
                        dense
                        hide-details
                        single-line
                        class="d-inline ma-0 pa-0"
                        @change="toggleTheme" />
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                dense
                @click="signout">
                    <v-list-item-content>
                        <v-list-item-title>Esci</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: 'navbar',
    props: {
        notifications: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            show_all: false
        }
    },
    watch: {
        show_all (newVal) {
            if (newVal) localStorage.setItem('showall', true)
            else localStorage.removeItem('showall')
        }
    },
    mounted () {
        this.$nextTick(() => {
            if (localStorage.getItem('showall')) {
                this.show_all = true
            }
        })
    },
    methods: {
        toggleTheme () {
            localStorage.setItem('fpl-manager-theme', this.$vuetify.theme.isDark ? 'dark' : 'light')
            this.$store.commit('set_theme', this.$vuetify.theme.isDark ? 'dark' : 'light')
        },
        async signout () {
            this.$store.commit('loading', true)

            try {
                await this.$api.auth.signout()
                this.$router.go()
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>
<template>
    <div>
        <v-autocomplete
        v-model="selected"
        :disabled="disabled"

        :item-text="itemText"
        :item-value="itemValue"
        :cache-items="false"
        :items="items"
        return-object

        :search-input.sync="keywords"

        :label="required ? '* ' + label : label"
        :placeholder="placeholder"

        :menu-props="{
            closeOnClick: false,
            closeOnContentClick: false,
            disableKeys: true,
            openOnClick: false,
            maxHeight: 200,
            offsetY: true,
            offsetOverflow: true,
            transition: false,
            top: false,
            bottom: true
        }"

        :hide-selected="false"
        :hide-no-data="false"
        :no-filter="true"
        :hide-details="hideDetails"

        :loading="loading"
        allow-overflow
        :clearable="clearable"
        dense
        outlined
        :rules="rules"

        @change="$emit('change', $event)">
            <template #item="{ item }">
                <span
                class="d-flex align-center"
                style="width: 100%;">
                    <span>{{ item[itemText] }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                    icon
                    plain
                    x-small
                    @click="setForm(item.id)">
                        <v-icon>edit</v-icon>
                    </v-btn>
                </span>
            </template>

            <template #append>
                    <v-btn
                    icon
                    @click="setForm()">
                        <v-icon>add</v-icon>
                    </v-btn>
            </template>
        </v-autocomplete>

        <bxs-modal
        v-model="contact.on"
        width="768"
        :title="dialogTitle">
            <contact
            :key="contact.on"
            ref="contact"
            :id="contact.id"
            hide-header
            hide-footer
            @submit="submitContact" />

            <template #actions>
                <bxs-btn
                color="primary"
                @click="$refs.contact.submit()">Salva</bxs-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import Contact from '@/components/forms/Contact.vue'

export default {
    name: 'customer-field',
    components: {
        contact: Contact
    },
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        'item-text': {
            type: String,
            required: false,
            default: 'nome'
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        'dialog-title': {
            type: String,
            required: false,
            default: 'Nuovo contatto'
        }
    },
    data () {
        return {
            selected: null,
            items: [],
            keywords: null,
            loading: false,

            contact: {
                on: null,
                id: null
            }
        }
    },
    watch: {
        keywords (newVal) {
            this.getItems()
        },
        selected () {
            this.$emit('input', this.selected ? this.selected.id : null)
        }
    },
    created () {
        this.start()
    },
    methods: {
        async start () {
            if (this.$api.status.contact_count <= 80) {
                this.items = await this.$api.contacts.list({
                    pagination: false,
                    sort: { nome: 1 },
                    limit: 80
                })
            }

            if (this.value) {
                const doc = await this.$api.contacts.doc(this.value)

                if (doc) {
                    this.selected = doc
                    this.items = [this.selected]
                }
            }
        },
        async getItems () {
            if (
                !this.keywords ||
                !this.keywords.length ||
                // (this.keywords && this.keywords.length <= 1) ||
                (this.selected && this.keywords === this.selected[this.itemText])
            ) {
                return
            }

            this.items = []
            this.loading = true
            const filters = {
                keywords: this.keywords
            }

            // if (this.type) {
            //     filters.type = '=|' + this.type
            // }

            try {
                this.items = await this.$api.contacts.list({
                    pagination: false,
                    sort: { nome: 1 },
                    filters: filters
                })
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        setForm (id) {
            this.contact.id = id || null
            this.contact.on = !this.contact.on
        },
        async submitContact (method, model, body, id) {
            try {
                const docId = await this.$api.contacts.save(id, body)
                const doc = await this.$api.contacts.doc(docId)

                if (doc) {
                    this.selected = doc
                    this.items = [this.selected]
                }

                this.contact.id = null
                this.contact.on = false
                this.$notify('Op completata')
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
</style>
<template>
    <div class="contact boxed">
        <formizer
        ref="formizer"
        model="Contact"
        undo="/contacts"
        :id="id"
        :hide-header="hideHeader"
        :hide-footer="hideFooter"
        @draw-completed="drawCompleted"
        @submit="intSubmit">
            <template #header-toolbar="{ fields }">
                <v-btn
                text
                small
                plain
                to="/contacts">
                    <v-icon left>chevron_left</v-icon>
                    <span>Contatti</span>
                </v-btn>

                <h1>{{ fields.nome.model || 'Contatto' }}</h1>
            </template>

            <template #header-menu>
                <v-list-item to="/contacts/new">Nuovo</v-list-item>
            </template>

            <template #body="{ fields, card }">
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h3>Informazioni base</h3>
                    </div>

                    <v-combobox
                    v-model="fields.type.model"
                    :items="$api.enums.contact_tipologie"
                    label="Tipologia"
                    dense
                    outlined />

                    <v-text-field
                    v-model="fields.nome.model"
                    label="Nome e cognome / Ragione sociale"
                    dense
                    outlined />

                    <v-text-field
                    v-model="fields.email.model"
                    label="Email"
                    dense
                    outlined />

                    <v-combobox
                    v-model="fields.indirizzo.model"
                    label="Indirizzo"
                    dense
                    outlined
                    :suggestions="$api.enums.contacts_addresses" />

                    <v-combobox
                    v-model="fields.settore.model"
                    label="Settore"
                    dense
                    outlined
                    :suggestions="$api.enums.contacts_settori"
                    class="mb-0" />
                </v-card>

                <v-card v-bind="card">
                    <div class="mb-8">
                        <h3>Dati aziendali</h3>
                    </div>

                    <v-text-field
                    v-model="fields.nome.model"
                    label="Ragione sociale"
                    dense
                    outlined />

                    <contact-field
                    v-model="fields.azienda.model"
                    label="Azienda primaria"
                    dialog-title="Azienda primaria" />

                    <v-text-field
                    v-model="fields.piva.model"
                    label="P.Iva"
                    dense
                    outlined />

                    <v-text-field
                    v-model="fields.codice_fiscale.model"
                    label="Codice fiscale"
                    dense
                    outlined />

                    <v-text-field
                    v-model="fields.codice_fiscale.model"
                    label="PEC"
                    dense
                    outlined />

                    <v-text-field
                    v-model="fields.codice_fiscale.model"
                    label="Codice SDI"
                    dense
                    outlined />
                </v-card>

                <v-card v-bind="card">
                    <v-textarea
                    v-model="fields.email.model"
                    label="Note"
                    dense
                    outlined />

                    <!-- <comma-to-list-field
                    v-model="fields.categories.model"
                    label="Categorie"
                    :suggestions="$api.enums.contacts_categories"
                    dense
                    outlined /> -->

                    <!-- <comma-to-list-field
                    v-model="fields.tags.model"
                    label="Tags"
                    :suggestions="$api.enums.contacts_tags"
                    dense
                    outlined
                    class="mb-0" /> -->
                </v-card>
            </template>
        </formizer>
    </div>
</template>

<script>
export default {
    name: 'customer',
    props: {
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: [String, Number, Boolean],
            required: false,
            default: null
        }
    },
    methods: {
        drawCompleted (method, fieldObj, doc, next) {
            next()
        },
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>
<template>
    <div class="work-sheet">
        <div
        v-if="!item"
        class="boxed text-center">
            <loading />
        </div>

        <div v-else>
            <section>
                <div
                class="d-flex align-center py-4 sticky background"
                style="top: 60px !important;">
                    <div>
                        <h1 class="mute--text">
                            <v-btn
                            icon
                            @click="$router.go(-1)">
                                <v-icon>arrow_back</v-icon>
                            </v-btn>

                            Scheda opera
                        </h1>

                        <h2>{{ item.title }}</h2>
                    </div>

                    <v-spacer></v-spacer>

                    <v-btn
                    color="primary"
                    :to="`/works/${item.id}`"
                    class="bxs-mr-ui">Modifica opera</v-btn>

                    <bxs-btn
                    color="secondary"
                    @click="print">Stampa scheda</bxs-btn>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <v-row
                class="mb-12"
                no-gutters>
                    <v-col
                    cols="6"
                    class="pr-12">
                        <div class="list-item-box">
                            <div class="list-item">
                                <p>Titolo</p>
                                <p>{{ item.title || '--' }}</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box list-item-box-inline">
                            <div class="list-item">
                                <p>Numero</p>
                                <p>{{ item.numero || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Anno</p>
                                <p>{{ item.anno || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Tecnica</p>
                                <p>{{ item.tecnica || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Supporto</p>
                                <p>{{ item.supporto || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Altezza</p>
                                <p>{{ item.dimensioni.height || '--' }} cm</p>
                            </div>

                            <div class="list-item">
                                <p>Larghezza</p>
                                <p>{{ item.dimensioni.width || '--' }} cm</p>
                            </div>

                            <div class="list-item">
                                <p>Profondità</p>
                                <p>{{ item.dimensioni.depth || '--' }} cm</p>
                            </div>

                            <div class="list-item">
                                <p>Peso</p>
                                <p>{{ item.dimensioni.weight || '--' }} kg</p>
                            </div>

                            <div class="list-item">
                                <p>Tipo imballo</p>
                                <p>{{ item.tipo_imballo || '--' }}</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box list-item-box-inline">
                            <div class="list-item">
                                <p>Categoria/e</p>
                                <p>{{ item.categoria || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Tipologia</p>
                                <p>{{ item.tipologia || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Collezione</p>
                                <p>{{ item.collezione || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Collezione desunta</p>
                                <p>{{ item.collezione_desunta || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Collocazione</p>
                                <p>{{ item.collocazione || '--' }}</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box list-item-box-inline">
                            <div class="list-item">
                                <p>Autentica</p>
                                <p>{{ item.autentica.è_presente ? 'Si' : 'No' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Autentica richiedente</p>
                                <p>{{ item.autentica.richiedente || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Autentica data rilascio</p>
                                <p v-if="item.autentica.data_rilascio">{{ item.autentica.data_rilascio | toHumanDate('LL') }}</p>
                                <p v-else>--</p>
                            </div>

                            <div class="list-item">
                                <p>Valore opera</p>
                                <p>{{ item.valore_opera | toHumanPrice }} €</p>
                            </div>

                            <div class="list-item">
                                <p>Valore assicurativo</p>
                                <p>{{ item.valore_assicurativo | toHumanPrice }}</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box list-item-box-inline">
                            <div class="list-item">
                                <p>Quaderno veline</p>
                                <p>{{ item.in_quaderno_veline ? 'Si' : 'No' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Quaderno serigrafie</p>
                                <p>{{ item.in_quaderno_serigrafie ? 'Si' : 'No' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Firmata</p>
                                <p>{{ item.è_firmata ? 'Si' : 'No' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Multiplo</p>
                                <p>{{ item.è_multiplo ? 'Si' : 'No' }}</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box list-item-box-inline">
                            <div class="list-item">
                                <p>Iscrizioni su retro</p>
                                <p>{{ item.iscrizioni_su_retro || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Bibliografia</p>
                                <p>{{ item.bibliografia || '--' }}</p>
                            </div>

                            <div class="list-item">
                                <p>Note</p>
                                <p>{{ item.note || '--' }}</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box">
                            <div class="list-item">
                                <p>Cassa/e</p>

                                <v-row v-if="item.cassa.value.length > 0">
                                    <v-col
                                    v-for="(cassa, i) in item.cassa.value"
                                    :key="i"
                                    cols="2">
                                        <v-img
                                        :src="cassa.img ? cassa.img.url : '/img/no-photo.png'"
                                        class="mb-2" />

                                        <div class="mb-2">
                                            <p class="mute--text">Codice</p>
                                            <p>{{ cassa.numero_riferimento || '--' }}</p>
                                        </div>

                                        <div class="mb-2">
                                            <p class="mute--text">Titolo</p>
                                            <p>{{ cassa.titolo || '--' }}</p>
                                        </div>

                                        <div>
                                            <p class="mute--text">Peso</p>
                                            <p>{{ cassa.peso || '--' }} kg</p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <p v-else>--</p>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <div class="list-item-box">
                            <div class="list-item">
                                <div class="list-item">
                                    <p>Autentica immagini</p>

                                    <v-row v-if="item.autentica.imgs.length > 0">
                                        <v-col
                                        v-for="(img, i) in item.autentica.imgs"
                                        :key="i"
                                        cols="1">
                                            <v-img :src="img.url" />
                                        </v-col>
                                    </v-row>
                                    <p v-else>--</p>
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                    ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                    <v-col cols="6">
                        <ul>
                            <li
                            v-if="item.front_img"
                            class="mb-4">
                                <div class="mb-4">
                                    <h4>Immagine fronte</h4>
                                </div>

                                <v-row>
                                    <v-col cols="7">
                                        <v-img :src="item.front_img.url" />
                                    </v-col>

                                    <v-col cols="5">
                                        <div class="list-item">
                                            <p>Data scatto</p>
                                            <p>{{ item.front_img.data_scatto || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Luogo scatto</p>
                                            <p>{{ item.front_img.luogo_scatto || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Fotografo</p>
                                            <p>{{ item.front_img.fotografo || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Note</p>
                                            <p>{{ item.front_img.note || '--' }}</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </li>

                            <li
                            v-if="item.back_img"
                            class="mb-4">
                                <div class="mb-4">
                                    <h4>Immagine retro</h4>
                                </div>

                                <v-row>
                                    <v-col cols="7">
                                        <v-img :src="item.back_img.url" />
                                    </v-col>

                                    <v-col cols="5">
                                        <div class="list-item">
                                            <p>Data scatto</p>
                                            <p>{{ item.back_img.data_scatto || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Luogo scatto</p>
                                            <p>{{ item.back_img.luogo_scatto || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Fotografo</p>
                                            <p>{{ item.back_img.fotografo || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Note</p>
                                            <p>{{ item.back_img.note || '--' }}</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </li>

                            <li
                            v-for="(img, i) in item.imgs"
                            :key="i"
                            class="mb-4">
                                <v-row>
                                    <v-col cols="7">
                                        <v-img :src="img.url" />
                                    </v-col>

                                    <v-col cols="5">
                                        <div class="list-item">
                                            <p>Data scatto</p>
                                            <p>{{ img.data_scatto || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Luogo scatto</p>
                                            <p>{{ img.luogo_scatto || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Fotografo</p>
                                            <p>{{ img.fotografo || '--' }}</p>
                                        </div>

                                        <div class="list-item">
                                            <p>Note</p>
                                            <p>{{ img.note || '--' }}</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </section>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            ------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <section>
                <bxs-data-table
                ref="table"
                id="page_work_sheet"
                resource="movs"
                model-name="Mov"
                :show-select="false"
                hide-id-item
                :remove-fields="[
                    'date.from.on',
                    'date.on',
                    'date.to.on',
                    'location.from.on',
                    'location.on',
                    'location.to.on',
                    'riferimento.from.on',
                    'riferimento.on',
                    'riferimento.to.on',
                    'valore.from.on',
                    'valore.on',
                    'valore.to.on',
                    'works'
                ]"
                :static-filters="{
                    works: '=|' + item.id
                }" />
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'work_sheet',
    metaInfo: {
      title: 'Scheda opera'
    },
    data () {
        return {
            item: null,
            loading: true
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            this.item = await this.$api.works.doc(this.$route.params.id, {
                populate: 'imgs front_img back_img autentica.imgs'
            })

            if (this.item.cassa.value.length > 0) {
                this.item.cassa.value.forEach((item) => {
                    if (item.img) {
                        this.$api.files.doc(item.img, {
                            select: 'url'
                        }).then((doc) => {
                            item.img = doc
                        })
                    }
                })
            }

            this.loading = false
        },
        print () {
            this.$nextTick(() => {
                this.$refs.pdf.generatePdf()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.list-item-box {
    margin: 1.25rem 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.list-item-box-inline {
        .list-item {
            display: inline-block;
            margin: 0 2rem 1rem 0;
        }
    }
}

.list-item {
    margin: 0 0 1rem 0;
    font-size: 1.1rem;

    p {
        &:first-child {
            color: var(--v-mute-base);
            margin-bottom: 0.15rem !important;
            // text-transform: uppercase;
        }
    }
}
</style>
<template>
    <div class="contact boxed">
        <formizer
        ref="formizer"
        model="Mov"
        undo="/movs"
        :id="id"
        :hide-header="hideHeader"
        :hide-footer="hideFooter"
        @draw-completed="drawCompleted"
        @submit="intSubmit">
            <template #header-toolbar="{ fields }">
                <v-btn
                text
                small
                plain
                to="/movs">
                    <v-icon left>chevron_left</v-icon>
                    <span>Movimenti</span>
                </v-btn>

                <h1>{{ fields.nome.model || 'Movimento' }}</h1>
            </template>

            <template #header-menu>
                <v-list-item to="/movs/new">Nuovo</v-list-item>
            </template>

            <template #body="{ fields, rules, card }">
                <v-card v-bind="card">
                    <div class="mb-8">
                        <h4>Informazioni base</h4>
                    </div>

                    <v-combobox
                    v-model="fields.tipo.model"
                    :items="$api.enums.mov_tipologie"
                    label="Tipologia"
                    :return-object="false"
                    outlined
                    dense />

                    <v-text-field
                    v-model="fields.title.model"
                    label="* Titolo"
                    outlined
                    dense
                    :rules="[rules.required]" />

                    <v-textarea
                    v-model="fields.descrizione.model"
                    label="Descrizione"
                    outlined
                    dense
                    class="mb-0" />
                </v-card>

                <v-card v-bind="card">
                    <div class="mb-8">
                        <h4>Opere</h4>
                    </div>

                    <select-order-field
                    v-if="!hideWork"
                    v-model="fields.works.model"
                    resource="works"
                    item-text="title"
                    :query-sort="{ title: 1 }"
                    label="Seleziona le opere" />

                    <v-textarea
                    v-model="fields.note.model"
                    label="Note"
                    outlined
                    dense
                    class="mb-0" />
                </v-card>

                <v-card v-bind="card">
                    <div class="mb-8">
                        <h4>Location</h4>
                    </div>

                    <v-row>
                        <v-col>
                            <v-combobox
                            v-model="fields.location.from.model"
                            :items="$api.enums.locations_from"
                            label="Location da"
                            outlined
                            dense />
                        </v-col>

                        <v-col>
                            <v-combobox
                            v-model="fields.location.to.model"
                            label="Location a"
                            :items="$api.enums.locations_to"
                            outlined
                            dense />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <date-field
                            v-model="fields.date.from.model"
                            label="Data da"
                            outlined
                            dense />
                        </v-col>

                        <v-col>
                            <date-field
                            v-model="fields.date.to.model"
                            label="Data a"
                            outlined
                            dense />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <contact-field
                            v-model="fields.riferimento.from.model"
                            label="Contatto da"
                            outlined
                            dense
                            class="mb-0" />
                        </v-col>

                        <v-col>
                            <contact-field
                            v-model="fields.riferimento.to.model"
                            label="Contatto a"
                            outlined
                            dense
                            class="mb-0" />
                        </v-col>
                    </v-row>
                </v-card>
            </template>
        </formizer>
    </div>
</template>

<script>
import DateField from '../DateField.vue'
export default {
  components: { DateField },
    name: 'mov',
    props: {
        'hide-header': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: [String, Number, Boolean],
            required: false,
            default: null
        },
        'hide-work': {
            type: Boolean,
            required: false,
            default: false
        },
        'disable-types': {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            on: false
        }
    },
    methods: {
        drawCompleted (method, fieldObj, doc, next) {
            next()
        },
        submit () {
            return this.$refs.formizer.submit()
        },
        async intSubmit (method, model, body, id, isUndo, undo) {
            this.$emit('submit', method, model, body, id, isUndo, undo)
        }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"disabled":_vm.disabled,"item-text":_vm.itemText,"item-value":_vm.itemValue,"cache-items":false,"items":_vm.items,"return-object":"","search-input":_vm.keywords,"label":_vm.required ? '* ' + _vm.label : _vm.label,"placeholder":_vm.placeholder,"menu-props":{
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 200,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
        top: false,
        bottom: true
    },"hide-selected":false,"hide-no-data":false,"no-filter":true,"hide-details":_vm.hideDetails,"loading":_vm.loading,"allow-overflow":"","clearable":_vm.clearable,"dense":"","outlined":"","rules":_vm.rules},on:{"update:searchInput":function($event){_vm.keywords=$event},"update:search-input":function($event){_vm.keywords=$event},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(_vm._s(item[_vm.itemText]))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","plain":"","x-small":""},on:{"click":function($event){return _vm.setForm(item.id)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}},{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setForm()}}},[_c('v-icon',[_vm._v("add")])],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('bxs-modal',{attrs:{"width":"768","title":_vm.dialogTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('bxs-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.contact.submit()}}},[_vm._v("Salva")])]},proxy:true}]),model:{value:(_vm.contact.on),callback:function ($$v) {_vm.$set(_vm.contact, "on", $$v)},expression:"contact.on"}},[_c('contact',{key:_vm.contact.on,ref:"contact",attrs:{"id":_vm.contact.id,"hide-header":"","hide-footer":""},on:{"submit":_vm.submitContact}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
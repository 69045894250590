<template>
    <div :class="['select-order-field mb-4', { 'select-order-field-outlined': outlined }]">
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            toolbar
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div
        v-if="resource"
        class="select-order-field--header">
            <div class="d-flex align-center">
                <h4
                v-if="label"
                class="mb-0">{{ label }}</h4>

                <slot name="toolbar" />

                <v-spacer />

                <search-field
                v-model="filters.keywords"
                dense
                hide-details
                single-line
                solo
                flat
                clearable
                style="max-width: 200px;"
                class="ma-0 pa-0" />
            </div>

            <!--  -->
            <div>
                <bxs-btn
                v-if="!!formComponent"
                text
                tooltip="Aggiungi nuovo elemento"
                color="info"
                x-small
                class="bxs-mr-ui"
                @click="setForm()">Aggiungi nuovo</bxs-btn>

                <!-- v-if="!!formComponent" -->
                <bxs-btn
                text
                color="info"
                x-small
                class="bxs-mr-ui"
                @click="selectAll">Seleziona tutti</bxs-btn>

                <!-- v-if="!!formComponent" -->
                <bxs-btn
                text
                color="info"
                x-small
                @click="deselectAll">Deseleziona tutti</bxs-btn>
            </div>
        </div>

        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            items
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div>
            <v-progress-linear
            v-if="loading"
            color="primary"
            indeterminate
            height="6" />

            <v-row
            no-gutters
            style="margin: 0 !important;"
            class="ma-0 pa-0">
                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                    items.finded
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <v-col class="scrollable-block pa-0 ma-0">
                    <ul v-if="cacheItemsFiltered.length > 0">
                        <li
                        v-for="(item, i) in cacheItemsFiltered"
                        :key="item.id"
                        class="pointer"
                        @click="setItem(item)">
                            <div class="d-flex py-3 pr-3 pl-3">
                                <div
                                v-if="!$scopedSlots.item"
                                class="truncated">
                                    <div v-if="item.img || (item.imgs && item.imgs.length > 0)">
                                        <v-img
                                        v-if="item.img && item.img.url"
                                        :src="item.img.url | toThumb"
                                        lazy-src="/img/loader.gif"
                                        max-width="44" />

                                        <v-img
                                        v-else-if="item.imgs && item.imgs.length > 0"
                                        :src="item.imgs[0].url | toThumb"
                                        lazy-src="/img/loader.gif"
                                        max-width="44" />

                                        <v-img
                                        v-else
                                        src='/img/no-photo.png'
                                        lazy-src="/img/loader.gif"
                                        max-width="44" />
                                    </div>

                                    <p
                                    v-html="dot.pick(itemText, item)"
                                    class="mt-1 truncated"></p>
                                </div>

                                <div
                                v-else
                                class="truncated">
                                    <slot
                                    name="item"
                                    :item="item" />
                                </div>

                                <v-spacer />

                                <v-btn
                                icon
                                small>
                                    <v-icon>chevron_right</v-icon>
                                </v-btn>
                            </div>

                            <v-divider v-if="i < cacheItemsFiltered.length - 1" />
                        </li>
                    </ul>

                    <div
                    v-else
                    class="grey--text ma-0 pa-2 text-center">Nessun elemento disponibile</div>
                </v-col>

                <v-divider
                vertical
                class="ma-0 pa-0" />

                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
                    items.selected
                ------------------------------------------------------------------------------------------------------------------------------------------------ -->
                <v-col class="scrollable-block ma-0 pa-0">
                    <draggable
                    v-if="cacheSelected.length > 0"
                    v-model="cacheSelected"
                    ghost-class="draggable-ghost"
                    :disabled="!sortable || cacheSelected.length <= 1"
                    @change="changePos">
                        <div
                        v-for="(item, i) in cacheSelected"
                        :key="item.id"
                        :class="{
                            'drag-item': sortable && cacheSelected.length > 1,
                            'dragging': sortable && cacheSelected.length > 1
                        }">
                            <div class="d-flex py-3 pr-3 pl-3">
                                <div
                                class="d-flex flex-nowrap align-start truncated"
                                style="flex: 1;">
                                    <v-icon
                                    v-if="sortable && cacheSelected.length > 1"
                                    size="15"
                                    class="mr-2">drag_indicator</v-icon>

                                    <div
                                    v-if="!$scopedSlots.item"
                                    class="truncated">
                                        <div v-if="item.img || (item.imgs && item.imgs.length > 0)">
                                            <v-img
                                            v-if="item.img && item.img.url"
                                            :src="item.img.url | toThumb"
                                            lazy-src="/img/loader.gif"
                                            max-width="44" />

                                            <v-img
                                            v-else-if="item.imgs && item.imgs.length > 0"
                                            :src="item.imgs[0].url | toThumb"
                                            lazy-src="/img/loader.gif"
                                            max-width="44" />

                                            <v-img
                                            v-else
                                            src='/img/no-photo.png'
                                            lazy-src="/img/loader.gif"
                                            max-width="44" />
                                        </div>

                                        <p
                                        v-html="dot.pick(itemText, item)"
                                        class="mt-1 truncated"></p>
                                    </div>

                                    <div
                                    v-else
                                    class="truncated">
                                        <slot
                                        name="item"
                                        :item="item" />
                                    </div>
                                </div>

                                <!-- style="flex: 1;" -->
                                <div class="text-right pl-4">
                                    <slot
                                    name="item-actions"
                                    :item="item" />

                                    <v-btn
                                    icon
                                    x-small
                                    @click="setForm(item.id)">
                                        <v-icon size="15">edit</v-icon>
                                    </v-btn>

                                    <!-- class="d-inline-flex flex-column flex-nowrap" -->
                                    <!-- <span v-if="sortable && cacheSelected.length > 1">
                                        <v-btn
                                        v-if="i > 0"
                                        icon
                                        x-small
                                        @click="moveItem(i, i - 1)">
                                            <v-icon size="15">expand_less</v-icon>
                                        </v-btn>

                                        <v-btn
                                        v-if="i < cacheSelected.length - 1"
                                        icon
                                        x-small
                                        @click="moveItem(i, i + 1)">
                                            <v-icon size="15">expand_more</v-icon>
                                        </v-btn>
                                    </span> -->

                                    <v-btn
                                    icon
                                    x-small
                                    @click="setItem(item)">
                                        <v-icon size="15">close</v-icon>
                                    </v-btn>
                                </div>
                            </div>

                            <v-divider v-if="i < cacheSelected.length - 1" />
                        </div>
                    </draggable>

                    <div
                    v-else
                    class="grey--text ma-0 pa-2 text-center">Nessun elemento disponibile</div>
                </v-col>
            </v-row>

            <v-divider class="ma-0 pa-0" />
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            footer
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <div class="select-order-field--footer d-flex mt-1">
            <span>Elementi: {{ cacheItemsFiltered.length }}</span>
            <v-spacer />
            <span>Elementi selezionati: {{ selectedCounts }}</span>
        </div>

        <!--  -->
        <bxs-modal
        v-if="formComponent"
        v-model="form.on"
        :title="!form.id ? 'Nuova/o ' + formTitle : 'Modifica ' + formTitle">
            <component
            :is="formComponent"
            ref="form"
            v-bind="formComponentProps"
            :key="form.on"
            :id="form.id"
            hide-header
            hide-footer
            fluid
            @submit="submit" />

            <template #actions>
                <v-btn
                color="primary"
                @click="$refs.form.submit()">Salva</v-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import dot from 'dot-object'
// import { eachSeries } from 'async'

export default {
    name: 'select-order-field',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        resource: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        'form-component': {
            type: String,
            required: false,
            default: null
        },
        'form-component-props': {
            type: Object,
            required: false,
            default: null
        },
        'form-title': {
            type: String,
            required: false,
            default: ''
        },
        'item-text': {
            type: String,
            required: false,
            default: 'text'
        },
        'item-populate': {
            type: [String, Array],
            required: false,
            default: null
        },
        'item-values': {
            type: Array,
            required: false,
            default: () => ['id']
        },
        'auto-select-all': {
            type: Boolean,
            required: false,
            default: false
        },
        sortable: {
            type: Boolean,
            required: false,
            default: true
        },
        'query-sort': {
            type: Object,
            required: false,
            default: null
        },
        'query-filters': {
            type: Object,
            required: false,
            default: () => {}
        },
        'query-select': {
            type: String,
            required: false,
            default: null
        },
        immediate: {
            type: Boolean,
            required: false,
            default: true
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        exclude: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        counts () {
            return this.cacheItems.length
        },
        selectedCounts () {
            return this.selectedIds.length
        },
        selectedIds () {
            return this.cacheSelected.map((i) => i.id)
        },
        selectedItemsModel () {
            return this.cacheSelected.map((val) => {
                if (this.itemValues.length === 1 && this.itemValues[0] === 'id') return val.id

                const v = {}
                this.itemValues.forEach((k) => {
                    v[k] = this.dot.pick(val, k)
                })

                return this.dot.object(v)
            })
        },
        cacheItemsFiltered () {
            return this.cacheItems.filter((item) => {
                return !this.selectedIds.includes(item.id)
            })
        }
    },
    data () {
        return {
            dot,
            cacheItems: [],
            showFilters: false,
            cacheSelected: [],
            oldSelected: this.value,
            filters: {
                keywords: null
            },
            loading: true,
            form: {
                on: null,
                id: null
            }
        }
    },
    async created () {
        if (this.immediate && this.dot.pick(this.resource, this.$api)) {
            const filters = {
                ...this.queryFilters
            }

            if (this.exclude && this.exclude.length > 0) {
                filters.id = '<>|' + this.exclude.join(',')
            }

            this.cacheItems = await this.dot.pick(this.resource, this.$api).list({
                pagination: false,
                spread: this.itemPopulate,
                select: this.querySelect || null,
                sort: this.querySort || { [this.itemText]: 1 },
                filters: filters
            })
        }

        await this.setStartItems()

        this.loading = false
    },
    watch: {
        filters: {
            immediate: false,
            deep: true,
            handler (newVal) {
                console.log('select order field watch filters')

                if (newVal.keywords && newVal.keywords.length > 0) {
                    this.getItems()
                } else {
                    this.cacheItems = []
                }
            }
        },
        queryFilters: {
            immediate: false,
            deep: true,
            handler (newVal) {
                console.log('select order field watch queryFilters')

                this.getItems()
            }
        },
        cacheSelected: {
            immediate: false,
            deep: true,
            handler (newVal) {
                this.updateValue()
            }
        },
        value: {
            deep: true,
            immediate: false,
            handler () {
                // this.setStartItems()
            }
        }
    },
    methods: {
        hasScopeSlot (key) {
            return key in this.$scopedSlots
        },
        stop () {
            this.clear()
        },
        async setStartItems () {
            if (this.value && this.value.length > 0) {
                this.loading = true

                // get items
                const items = await this.dot.pick(this.resource, this.$api).list({
                    pagination: false,
                    select: this.querySelect || null,
                    spread: this.itemPopulate,
                    sort: this.querySort || { [this.itemText]: 1 },
                    filters: {
                        id: '[]|' + this.value.join(',')
                    }
                })

                // select items
                items.forEach(item => this.cacheSelected.push(JSON.parse(JSON.stringify(item))))

                // reorder items
                const arr = []

                this.oldSelected.forEach((id, i) => {
                    const item = this.cacheSelected.find(i => i.id === id)
                    if (item) arr.push(JSON.parse(JSON.stringify(item)))
                })

                this.cacheSelected = arr

                // !!! each series for right order
                // await eachSeries(this.value, async (id, cb) => {
                //     const item = await this.dot.pick(this.resource, this.$api).doc(id)
                //     if (item) this.cacheSelected.push(JSON.parse(JSON.stringify(item)))
                //     cb()
                // })
            }

            return true
        },
        async getItems () {
            // this.cacheItems = []
            this.loading = true
            const filters = {
                keywords: this.filters.keywords,
                ...this.queryFilters
            }

            if (this.exclude.length > 0) {
                filters.id = '<>|' + this.exclude.join(',')
            }

            this.cacheItems = await this.dot.pick(this.resource, this.$api).list({
                pagination: false,
                select: this.querySelect || null,
                sort: this.querySort || { [this.itemText]: 1 },
                spread: this.itemPopulate,
                filters: filters
            })

            this.loading = false
        },
        //
        clear () {
            this.cacheItems = []
            this.filters.keywords = null
            this.cacheSelected = []
            this.showFilters = false
            this.updateValue()
        },
        setItem (item) {
            item = JSON.parse(JSON.stringify(item))

            if (this.cacheSelected.some((i) => i.id === item.id)) {
                const i = this.cacheSelected.findIndex((i) => i.id === item.id)
                this.cacheSelected.splice(i, 1)
                this.$emit('remove-item', item, JSON.parse(JSON.stringify(this.cacheSelected)))
            } else {
                this.cacheSelected.push(JSON.parse(JSON.stringify(item)))
                this.$emit('add-item', item, JSON.parse(JSON.stringify(this.cacheSelected)))
            }

            this.updateValue()

            this.$emit('change', item)
        },
        moveItem (from, to) {
            // remove `from` item and store it
            const f = this.cacheSelected.splice(from, 1)[0]
            // insert stored item into position `to`
            this.cacheSelected.splice(to, 0, f)
        },
        selectAll () {
            this.cacheItems.forEach(this.setItem)
        },
        deselectAll () {
            this.cacheSelected = []
            this.updateValue()
        },
        changePos () {
            this.updateValue()
            this.$emit('change:pos-item')
        },
        updateValue () {
            this.$emit('input', this.selectedItemsModel)
        },
        setForm (id) {
            this.form.id = id || null
            this.form.on = !this.form.on
        },
        async submit (method, model, body, id, undo, undoTo) {
            let spread = null

            if (this.itemPopulate && this.itemPopulate.length > 0) {
                spread = Array.isArray(this.itemPopulate) ? this.itemPopulate : this.itemPopulate.split(',')
            }

            try {
                const doc_id = await dot.pick(this.resource, this.$api).save(id, body)
                const doc = await dot.pick(this.resource, this.$api).doc(doc_id, {
                    populate: spread
                })

                if (doc) {
                    // await this.getItems()
                    this.cacheItems.push(doc)
                }

                this.setForm()
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.select-order-field {
    user-select: none;

    &.select-order-field-outlined {
        border: 1px solid rgba(#000, 0.25);
        border-radius: 0.25rem;

        > .select-order-field--header,
        > .select-order-field--footer {
            padding: 0.5rem 1rem;
        }
    }

    &--header {
        background-color: rgb(#000, 0.05);
    }

    &--footer {}
}

.scrollable-block {
    width: 100%;
    height: 100%;
    max-height: 240px !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden;
    overflow-y: scroll;

    &.border-bottom {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.drag-item {
    cursor: grab;
    user-select: none;
}
</style>
<template>
    <div class="works">
        <div class="mb-6">
            <h1>Logs</h1>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------
            TABLE
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-data-table
        ref="table"
        id="page_logs"
        resource="history"
        show-select
        model-name="History">
            <template #item.user="{ value }">{{ value.username }}</template>
        </bxs-data-table>
    </div>
</template>

<script>
export default {
    name: 'logs'
}
</script>
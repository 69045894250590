<template>
    <div class="bxs-data-table">
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            TOOLBAR
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <v-toolbar flat>
            <span class="ml-1"></span>
            <slot name="prepend-toolbar" />

            <bxs-btn
            v-if="toCreateItem"
            :disabled="computed_has_selected"
            :to="toCreateItem"
            icon
            tooltip="Aggiungi nuovo elemento"
            class="mnk-mr-ui"
            @click="$emit('click:toolbar-new')">
                <v-icon>add</v-icon>
            </bxs-btn>

            <bxs-btn
            v-if="!hideDefaultDeleteItem"
            tooltip="Elimina selezionati"
            :disabled="!computed_has_selected"
            icon
            class="mnk-mr-ui"
            @click="deleteDocs()">
                <v-icon>delete</v-icon>
            </bxs-btn>

            <bxs-btn
            icon
            tooltip="Compatta tabella"
            class="mnk-mr-ui"
            @click="compact">
                <v-icon>view_compact_alt</v-icon>
            </bxs-btn>

            <!-- <bxs-btn
            tooltip="Gestisci i campi visibili della tabella"
            icon
            class="mnk-mr-ui"
            @click="show_order_fields = true">
                <v-icon>low_priority</v-icon>
            </bxs-btn> -->

            <!-- <bxs-btn
            tooltip="Ripristina campi iniziali"
            icon
            class="mnk-mr-ui"
            @click="restartFields">
                <v-icon>restart_alt</v-icon>
            </bxs-btn> -->

            <bxs-dot-menu>
                <!-- <v-list-item
                v-if="selected_fields.length > 1"
                @click="show_order_fields = true">
                    <v-list-item-icon>
                        <v-icon>low_priority</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Gestici i campi della tabella</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <!-- <v-list-item
                v-if="selected_fields.length > 1"
                @click="restartFields">
                    <v-list-item-icon>
                        <v-icon>restart_alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Ripristina campi visibili suggeriti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <slot
                name="toolbar-menu"
                :value="computed_selected_ids"
                :getItems="getItems" />

                <v-list-item @click="createView()">
                    <v-list-item-icon>
                        <v-icon>view_column</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Aggiungi vista tabella</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                v-if="!hideDefaultDeleteItem"
                :disabled="!computed_has_selected"
                @click="deleteDocs()">
                    <v-list-item-icon>
                        <v-icon>delete</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Elimina selezionati</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </bxs-dot-menu>

            <v-select
            v-if="view.items.length > 0"
            v-model="view.selected"
            :items="view.items"
            label="Vista"
            placeholder="Seleziona vista"
            item-text="name"
            item-value="id"
            dense
            hide-details
            solo
            :menu-props="{ top: false, bottom: true, offsetY: true }"
            style="max-width: 160px;"
            class="mb-0 mnk-ml-ui"
            @change="setViewFields">
                <template #prepend-item>
                    <v-list-item
                    dense
                    ripple
                    class="mute--text"
                    @mousedown.prevent
                    @click="createView()">
                        <v-list-item-content>
                            <v-list-item-title>Aggiungi vista</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon>add</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>

                <template #item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-icon @click="createView(item)">edit</v-icon>
                    </v-list-item-action>
                </template>
            </v-select>

            <v-chip
            v-if="view.selected"
            small
            class="ml-4"
            @click="deselectView()">
                <span>Deseleziona vista</span>
                <v-icon right>close</v-icon>
            </v-chip>

            <v-chip
            v-if="computed_query_filters && Object.keys(computed_query_filters).length > 0"
            small
            class="ml-3"
            @click="clearFilters">
                <span>{{ Object.keys(computed_query_filters).length }} {{ Object.keys(computed_query_filters).length < 1 ? 'filtro' : 'filtri' }} applicati</span>
                <v-icon right>close</v-icon>
            </v-chip>

            <slot name="append-toolbar" />

            <!-- fields -->
            <v-spacer></v-spacer>

            <search-field
            v-model="local_filters.keywords"
            prepend-icon="search"
            label="Cerca"
            solo
            flat
            clearable
            hide-details
            single-line
            outlined
            class="ml-6 mb-0"
            style="max-width: 240px" />
        </v-toolbar>

        <v-divider></v-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
            TABLE
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <!-- :key="computed_headers.length" -->
        <v-data-table
        ref="table"
        v-model="selected"
        fixed-header
        :headers="computed_headers"
        :height="height"
        :items="local_items"
        :items-per-page.sync="options.itemsPerPage"
        :item-key="itemKey"
        :footer-props="footerProps"
        :loading="loading"
        :multi-sort="options.multiSort"
        :must-sort="options.mustSort"
        :options.sync="options"
        :server-items-length="pagination.total"
        :show-select="showSelect && local_items.length > 0"
        :class="classes"
        @click:row="$emit('click:row', $event)"
        @dblclick:row="$emit('dblclick:row', $event)">
            <template #no-data>
                <span v-if="!selected_fields.length">Seleziona i campi da visualizzare</span>
                <span v-else>Nessun elemento disponibile</span>
            </template>

            <!--  -->
            <template
            v-if="computed_has_headers && computed_has_filters"
            #header="{ props }">
                <thead class="v-data-table-header mnk-table-header-filters">
                    <tr class="pa-0 ma-0">
                        <th
                        v-for="v in props.headers"
                        :key="v.value"
                        :class="v.class"
                        :width="v.width"
                        class="v-data-table__divider px-0">
                            <span v-if="v.filterable && v.value !== 'id' && v.value !== 'imgs' && v.value !== 'img' && v.value !== 'img_picker' && computed_filters[v.value]">
                                <bxs-date-field
                                v-if="computed_filters[v.value].type === 'date'"
                                v-model="computed_filters[v.value].model"
                                dense
                                flat
                                :outlined="false"
                                solo
                                clearable
                                full-width
                                hide-details
                                :prepend-inner-icon="null"
                                class="mb-0"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template #append>
                                        <bxs-btn
                                        x-small
                                        @click="changeOperatorItemFiter(computed_filters[v.value])">{{ computed_filters[v.value].operators[computed_filters[v.value].operator_index] }}</bxs-btn>
                                    </template>
                                </bxs-date-field>

                                <v-combobox
                                v-else-if="computed_filters[v.value].type === 'array'"
                                v-model="computed_filters[v.value].model"
                                :items="computed_filters[v.value].items"
                                :item-text="computed_filters[v.value].item_text || 'text'"
                                :item-value="computed_filters[v.value].item_value || 'value'"
                                clearable
                                multiple
                                solo
                                flat
                                :outlined="false"
                                hide-details
                                dense
                                deletable-chips
                                full-width
                                small-chips
                                chips
                                :return-object="false"
                                class="mb-0"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template #selection="{ index }">
                                        <v-chip
                                        v-if="!index"
                                        color="primary"
                                        x-small>{{ computed_filters[v.value].model.length }}</v-chip>
                                    </template>
                                </v-combobox>

                                <v-select
                                v-else-if="computed_filters[v.value].type === 'boolean'"
                                v-model="computed_filters[v.value].model"
                                :items="[{ text: 'Si', value: 'true' }, { text: 'No', value: 'false' }]"
                                :item-text="computed_filters[v.value].item_text || 'text'"
                                :item-value="computed_filters[v.value].item_value || 'value'"
                                clearable
                                solo
                                :outlined="false"
                                flat
                                hide-details
                                dense
                                full-width
                                :return-object="false"
                                class="mb-0"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template #selection="{ index }">
                                        <v-chip
                                        v-if="!index"
                                        x-small>{{ computed_filters[v.value].model === 'true' ? 'Si' : 'No' }}</v-chip>
                                    </template>
                                </v-select>

                                <currency-field
                                v-else-if="computed_filters[v.value].type === 'number'"
                                v-model="computed_filters[v.value].model"
                                :emit-immediately="false"
                                :currency="null"
                                dense
                                :outlined="false"
                                flat
                                hide-details
                                :suffix="null"
                                clearable
                                solo
                                class="mb-0"
                                @clear="clearItemFilterValue(computed_filters[v.value])"
                                @input="handleInputFilter">
                                    <template #append>
                                        <bxs-btn
                                        x-small
                                        @click="changeOperatorItemFiter(computed_filters[v.value])">{{ computed_filters[v.value].operators[computed_filters[v.value].operator_index] }}</bxs-btn>
                                    </template>
                                </currency-field>

                                <search-field
                                v-else
                                v-model="computed_filters[v.value].model"
                                :type="computed_filters[v.value].input_type"
                                dense
                                flat
                                :outlined="false"
                                :label="null"
                                solo
                                clearable
                                full-width
                                hide-details
                                :prepend-inner-icon="null"
                                class="mb-0"
                                style="font-size: 1rem; font-weight: 400;"
                                @input="handleInputFilter">
                                    <template
                                    v-if="computed_filters[v.value].operators.length > 1"
                                    #append>
                                        <bxs-btn
                                        small
                                        @click="changeOperatorItemFiter(computed_filters[v.value])">{{ computed_filters[v.value].operators[computed_filters[v.value].operator_index] }}</bxs-btn>
                                    </template>
                                </search-field>
                            </span>

                            <!-- <div
                            v-else
                            v-tooltip="'Filtro non attivo'"
                            style="display: block;"
                            class="text-center">
                                <v-icon size="20">block</v-icon>
                            </div> -->
                        </th>
                    </tr>
                </thead>
            </template>

            <!--  -->
            <template
            v-for="v in computed_headers"
            :slot="'item.' + v.value"
            slot-scope="{ item, value }">
                <slot
                :name="'item.' + v.value"
                :value="value"
                :item="item">
                    <span
                    :key="v.value"
                    v-tooltip="{
                        content: $t(String(value)),
                        placement: 'top',
                        delay: {
                            show: 200,
                            hide: 0
                        }
                    }">{{ $t(value) }}</span>
                </slot>
            </template>

            <template #item.created_at="{ item, value }">
                <slot
                v-if="$scopedSlots['item.created_at']"
                name="item.created_at"
                :item="item"
                :value="value" />

                <span v-else>{{ value | toHumanDate('l') }}</span>
            </template>

            <template #item.updated_at="{ item, value }">
                <slot
                v-if="$scopedSlots['item.updated_at']"
                name="item.updated_at"
                :item="item"
                :value="value" />

                <span v-else>{{ value | toHumanDate('l') }}</span>
            </template>

            <template #item.imgs="{ item, value }">
                <div class="py-1">
                    <slot
                    v-if="$scopedSlots['item.imgs']"
                    name="item.imgs"
                    :item="item"
                    :value="value" />

                    <v-avatar
                    v-else
                    rounded
                    size="40"
                    @click="$emit('click:item-imgs', item)">
                        <v-img
                        v-if="value && value.length > 0"
                        :src="value[0].url | toThumb"
                        lazy-src="/img/logo-s.png"
                        aspect-ratio="1" />

                        <v-img
                        v-else
                        src="/img/no-photo.png"
                        aspect-ratio="1" />
                    </v-avatar>
                </div>
            </template>

            <template #item.img="{ item, value }">
                <v-avatar
                size="40"
                rounded
                class="pointer py-1"
                @click="$emit('click:item-img', item)">
                    <v-img
                    v-if="value && value.url"
                    :src="value.url | toThumb"
                    lazy-src="/img/logo-s.png"
                    aspect-ratio="1" />

                    <v-img
                    v-else
                    src="/img/no-photo.png"
                    aspect-ratio="1" />
                </v-avatar>
            </template>

            <template #item.id="{ item, value }">
                <bxs-dot-menu
                v-if="!hideItemMenu"
                left>
                    <!-- <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ item.name.it || item.title.it }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="ma-0 pa-0"></v-divider> -->

                    <v-list-item
                    v-if="toUpdateItem"
                    :to="`${toUpdateItem}/${value}`">
                        <v-list-item-icon>
                            <v-icon>edit</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Modifica</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <slot
                    name="item-actions"
                    :item="item"
                    :id="value"
                    :deleteDocs="deleteDocs" />

                    <v-list-item
                    v-if="!hideDefaultDuplicateItem"
                    @click="duplicate(value)">
                        <v-list-item-icon>
                            <v-icon>content_copy</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Duplica</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                    v-if="!hideDefaultDeleteItem"
                    @click="deleteDocs(value)">
                        <v-list-item-icon>
                            <v-icon>delete</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </bxs-dot-menu>
            </template>
        </v-data-table>

        <!-- ------------------------------------------------------------------------------------------------------------
            MODAL ORDER TABLE FIELDS
        ------------------------------------------------------------------------------------------------------------ -->
        <bxs-modal
        v-model="view.on"
        :title="`${view.model.id ? 'Modifica' : 'Aggiungi'} vista tabella`"
        width="1024">
            <template #append-header>
                <v-text-field
                v-model="view.model.name"
                label="Nome vista"
                outlined
                dense
                hide-datails
                class="mb-0 mt-4" />
            </template>

            <div class="pa-4">
                <div class="d-flex mb-6">
                    <v-chip
                    outlined
                    class="mr-1"
                    @click="selected_fields = []">Deseleziona tutti</v-chip>
                </div>

                <div>
                    <div>
                        <h4 class="mb-1">Campi selezionati</h4>
                        <p
                        v-if="!view.model.fields.length"
                        class="mute--text">Nessun campo selezionato</p>
                    </div>

                    <draggable
                    v-if="view.model.fields.length > 0"
                    v-model="view.model.fields"
                    ghost-class="draggable-ghost"
                    tag="ul"
                    class="d-flex flex-wrap mt-4">
                        <li
                        v-for="(v, i) in view.model.fields"
                        :key="v"
                        class="dragging mr-2 mb-2">
                            <v-chip class="dragging">
                                <v-icon small>drag_indicator</v-icon>
                                <span class="mx-2">{{ $t(v) }}</span>
                                <v-icon
                                small
                                @click="view.model.fields.splice(i, 1)">close</v-icon>
                            </v-chip>
                        </li>
                    </draggable>
                </div>

                <v-divider class="my-4"></v-divider>

                <div>
                    <div class="mb-4">
                        <h4 class="mb-1">Campi disponibili</h4>
                        <p class="mb-3">Seleziona i campi da visualizzare nella tabella</p>

                        <v-text-field
                        v-model="fields_search"
                        single-line
                        hide-details
                        prepend-inner-icon="search"
                        solo
                        flat
                        dense
                        clearable
                        label="Cerca" />
                    </div>

                    <v-chip-group column>
                        <v-chip
                        v-for="(v, i) in computed_schema_arr"
                        :key="i"
                        :value="v.value"
                        :disabled="view.model.fields.includes(v.value)"
                        @click="view.model.fields.push(v.value)">{{ v.text }}</v-chip>
                    </v-chip-group>
                </div>
            </div>

            <template #actions>
                <v-btn
                v-if="view.id"
                color="error"
                outlined
                @click="deleteView(id)">Elimina</v-btn>

                <v-btn
                color="primary"
                @click="saveView">Salva</v-btn>
            </template>
        </bxs-modal>
    </div>
</template>

<script>
import dot from 'dot-object'

export default {
    name: 'bxs-data-table',
    props: {
        resource: {
            type: String,
            required: false,
            default: null
        },
        schema: {
            type: Object,
            required: false,
            default: null
        },
        height: {
            type: [String, Number],
            required: false,
            default: '60vh'
        },
        'to-create-item': {
            type: String,
            required: false,
            default: null
        },
        'to-update-item': {
            type: String,
            required: false,
            default: null
        },
        'item-key': {
            type: String,
            required: false,
            default: 'id'
        },
        'hide-filters': {
            type: Array,
            required: false,
            default: () => []
        },
        'hide-default-delete-item': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-default-duplicate-item': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-item-menu': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-id-item': {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        'col-width': {
            type: String,
            required: false,
            default: '160px'
        },
        'model-name': {
            type: String,
            required: false,
            default: null
        },
        'sort-by': {
            type: Array,
            required: false,
            default: () => ['created_at']
        },
        'sort-dir': {
            type: Array,
            required: false,
            default: () => [true]
        },
        'static-query': {
            type: Object,
            required: false,
            default: () => {}
        },
        'static-filters': {
            type: Object,
            required: false,
            default: () => {}
        },
        'show-select': {
            type: Boolean,
            required: false,
            default: true
        },
        headers: {
            type: Array,
            required: false,
            default: () => []
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        'items-per-page': {
            type: [String, Number],
            required: false,
            default: 40
        },
        filters: {
            type: Array,
            required: false,
            default: () => []
        },
        'remove-fields': {
            type: Array,
            required: false,
            default: () => ['__v']
        }
    },
    data() {
        return {
            // items
            local_resource: this.resource,
            local_items: [],
            selected: [],
            local_filters: {
                keywords: null
            },
            // fields
            local_schema: {},
            selected_fields: [],
            show_order_fields: false,
            type_to_input: {
                String: 'text',
                Number: 'number',
                Date: 'date'
            },
            fields_search: null,
            computed_headers: [],
            computed_filters: {},
            view: {
                on: false,
                id: null,
                items: [],
                selected: null,
                model: {
                    name: null,
                    fields: []
                }
            },
            views: [],
            // table
            classes: {
                'bxs-table-dense': false
            },
            col: {
                width: this.colWidth
            },
            loading: true,
            options: {
                itemsPerPage: this.itemsPerPage,
                multiSort: true,
                mustSort: false, // ordinamento, passerà sempre tra crescente e decrescente
                page: 1,
                sortBy: this.sortBy,
                sortDesc: this.sortDir
            },
            footerProps: {
                'items-per-page-options': [10, 20, this.itemsPerPage],
                showFirstLastPage: true
            },
            pagination: {
                total: 0
            }
        }
    },
    computed: {
        // fields
        computed_schema_arr () {
            let v = []

            const a = [...new Set([
                ...this.headers.map(i => i.value).filter(i => i in this.local_schema),
                ...Object.keys(this.local_schema)
            ])]

            v = a.map((v) => {
                return {
                    text: this.getLabelFromLocalHeaders(v),
                    value: v
                }
            }).sort((a, b) => {
                return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : b.text.toLowerCase() > a.text.toLowerCase() ? -1 : 0
            })

            if (this.fields_search && this.fields_search.length > 0) {
                v = v.filter((i) => {
                    return i.text.toLowerCase().includes(this.fields_search) || i.text.toUpperCase().includes(this.fields_search)
                })
            }

            return v
        },
        computed_has_headers () {
            return this.computed_headers.length > 0
        },
        computed_has_filters () {
            return !!this.computed_filters && Object.keys(this.computed_filters).length > 0
        },
        computed_has_selected_all_fields () {
            return this.selected_fields.length === this.computed_schema_arr.length
        },
        computed_has_selected_fields () {
            return this.selected_fields.length > 0
        },
        computed_icon_select_fields () {
            let v = 'check_box_outline_blank'

            if (this.selected_fields.length >= 1 && this.selected_fields.length < this.computed_schema_arr.length) {
                v = 'indeterminate_check_box'
            }

            if (this.computed_has_selected_all_fields) {
                v = 'disabled_by_default'
            }

            return v
        },
        // query
        computed_query_filters () {
            const v = {}

            if (this.computed_filters && Object.keys(this.computed_filters).length > 0) {
                for (const k in this.computed_filters) {
                    const item = this.computed_filters[k]

                    if (item.model && String(item.model).length > 0) {
                        v[item.field] = item.operators[item.operator_index] + '|' + item.model
                    }
                }
            }

            return v
        },
        computed_has_query_filters () {
            return !!this.computed_query_filters && Object.keys(this.computed_query_filters).length > 0
        },
        computed_sort() {
            const v = {}

            this.options.sortBy.forEach((key, index) => {
                // const field = this.local_schema[key] || {}
                v[key] = this.options.sortDesc[index] === true ? -1 : 1
            })

            return v
        },
        // items
        computed_has_selected() {
            return this.computed_selected_ids.length > 0
        },
        computed_selected_ids() {
            return this.selected.map(item => item.id)
        }
    },
    created () {
        this.start()
    },
    mounted () {
        this.$nextTick(this.play)
    },
    watch: {
        schema: {
            deep: true,
            immediate: false,
            handler(newVal) {
                console.log('table watch schema')
                this.getSchema()
            }
        },
        options: {
            deep: true, // Questo farà sapere a Vue di guardare all'interno dell'array
            immediate: false, // Si attiva non appena il componente viene creato
            handler(newVal) {
                console.log('table watch options')
                this.getItems()
            }
        },
        'local_filters.keywords' (newVal, oldVal) {
            console.log('table watch filters.keywords')
            this.getItems()
        },
        selected_fields (newVal) {
            console.log('table watch selected_fields')

            // localStorage.setItem((this.id || this.resource) + '.table_fields', newVal)
            this.computed_headers = this.genHeaders()
            this.computed_filters = this.genFilters()
        },
        staticFilters () {
            console.log('table watch local filters')
            this.getItems()
        },
        'view.on' (newVal) {
            if (!newVal) {
                this.view.id = null
                this.view.model = {
                    name: null,
                    fields: []
                }
            }
        }
    },
    methods: {
        async start () {
            console.log('table start')

            await this.getSchema()

            if (this.computed_has_query_filters) {
                this.getItems()
            }
        },
        play () {
            console.log('table play', this)
            this.classes['mnk-table-link'] = 'click:row' in this.$listeners
        },
        // fields
        async getSchema () {
            console.log('table getSchema')

            const schema = {}

            if (this.modelName && this.modelName.length > 0) {
                // const paths = await this.$api.getSchema(this.modelName)
                const paths = this.$api.enums.app.schemes[this.modelName]
                for (const k in paths) {
                    schema[k] = {
                        query_key: paths[k].path,
                        instance: paths[k].instance.toLowerCase()
                    }
                }
            }

            if (!Object.keys(schema).length) {
                return alert('Schema is required!')
            }

            if (this.schema) {
                for (const k in this.schema) {
                    schema[k] = this.schema[k]
                }
            }

            if (this.removeFields.length > 0) {
                this.removeFields.forEach((v) => {
                    if (schema[v]) delete schema[v]
                })
            }

            this.local_schema = schema

            // set initial fields
            // const is_first_time = !localStorage.getItem((this.id || this.resource) + '.table_fields.fallback')
            // if (is_first_time) localStorage.setItem((this.id || this.resource) + '.table_fields.fallback', true)

            try {
                await this.setViewFields()
            } catch (err) {
                console.log(err)
            }

            return schema
        },
        genHeaders () {
            if (!this.computed_has_selected_fields) return []

            console.log('table genHeaders', this.selected_fields)

            const h = JSON.parse(JSON.stringify(this.selected_fields))
            const v = []

            h.forEach((i) => {
                const fieldFromHeaders = this.headers.find(h => h.value === i)

                const i_f = {
                    text: this.getLabelFromLocalHeaders(i),
                    value: i,
                    filterable: true,
                    sortable: true,
                    class: 'truncated',
                    cellClass: 'truncated'
                }

                if (fieldFromHeaders) {
                    if ('filterable' in fieldFromHeaders) {
                        i_f.filterable = fieldFromHeaders.filterable
                    }

                    if ('sortable' in fieldFromHeaders) {
                        i_f.sortable = fieldFromHeaders.sortable
                    }

                    if ('class' in fieldFromHeaders) {
                        i_f.class = fieldFromHeaders.class
                    }

                    if ('cellClass' in fieldFromHeaders) {
                        i_f.cellClass = fieldFromHeaders.cellClass
                    }
                }

                //
                if (v.some(m => m.value === i_f.value)) return

                const is_img = i_f.value === 'img' || i_f.value === 'imgs'
                let width = i_f.width || this.col.width

                if (is_img) {
                    i_f.cellClass += ' mnk-table-cell-img'
                    i_f.class += ' mnk-table-cell-img'
                    width = '1%'
                    i_f.filterable = false
                    i_f.sortable = false
                }

                v.push({
                    width: width,
                    divider: i_f.divider || false,
                    ...i_f
                })
            })

            if (!this.hideIdItem) {
                v.push({
                    text: '',
                    value: 'id',
                    width: '1%',
                    cellClass: 'mnk-table-cell-cta text-center sticky-right',
                    class: 'mnk-table-cell-cta sticky-right',
                    sortable: false,
                    filterable: false
                })
            }

            return v
        },
        genFilters () {
            if (!this.computed_has_selected_fields) return {}

            console.log('table genFilters', this.selected_fields)

            const items = {}
            const keys = JSON.parse(JSON.stringify(this.selected_fields))
            const storage_v = localStorage.getItem((this.id || this.resource) + '.table_filters')
            const storage_items = storage_v && storage_v.length > 0 ? JSON.parse(storage_v) : null

            keys.forEach((k) => {
                const field = this.local_schema[k] || this.headers.find(i => i.value === k)

                if (field) {
                    let operators = ['=']
                    let operator = '='

                    if (field.instance === 'number' || field.instance === 'date') {
                        operators = ['=', '<', '<=', '>', '>=']
                    } else if (field.instance === 'array') {
                        operators = ['[]']
                        operator = '[]'
                    } else if (field.instance === 'boolean') {
                        operators = ['=']
                        operator = '='
                    }

                    items[k] = {
                        label: this.getLabelFromLocalHeaders(k),
                        field: field.query_key || k,
                        operators: field.operators || operators,
                        operator: field.operator || operator,
                        operator_index: field.operator_index || 0,
                        model: 'model' in field ? field.model : null,
                        type: field.instance || 'string',
                        input_type: this.type_to_input[field.instance] || 'text',
                        items: field.items || []
                    }

                    if (storage_items && Object.keys(storage_items).length > 0) {
                        if (k in storage_items) {
                            const spl_val = storage_items[k].split('|')
                            items[k].operator = spl_val[0]
                            items[k].operator_index = items[k].operators.findIndex(_v => _v === items[k].operator)
                            items[k].model = items[k].operator === '[]' ? spl_val[1].split(',') : spl_val[1]
                        }
                    }
                }
            })

            return items
        },
        // ------------------------------------------------------------------------------------------------
        async setViewFields (id) {
            console.log('table setViewFields()', id)

            // if (!!this.view.selected && !!id && this.view.selected === id) {
            //     return
            // }

            await this.getViews()

            let fields = []
            const view_id = id || localStorage.getItem((this.id || this.resource) + '.table_fields')
            console.log('view_id', view_id, this.view.items)

            if (view_id) {
                this.view.selected = view_id
                localStorage.setItem((this.id || this.resource) + '.table_fields', view_id)
                const view = this.view.items.find(i => i.id === view_id)
                console.log('view', view)
                if (view) fields = view.fields
            } else {
                localStorage.removeItem((this.id || this.resource) + '.table_fields')
            }

            // is_first_time
            if (!fields.length) {
                fields = this.headers.map(i => i.value)
            }

            this.selected_fields = fields // .filter(i => i in this.local_schema) => rimuove campi che non sono in schema

            return fields
        },
        deselectView () {
            this.view.selected = null
            localStorage.removeItem((this.id || this.resource) + '.table_fields')
            this.setViewFields()
        },
        async getViews () {
            const docs = await this.$api.users_tables.list({
                pagination: false,
                filters: {
                    model: this.modelName,
                    user: this.$api.auth.me.id
                }
            })

            this.view.items = docs
            return docs
        },
        createView (item) {
            console.log('table createView()', item)

            if (item) {
                this.view.id = item.id
                this.view.model.name = item.name
                this.view.model.fields = item.fields
            }

            this.view.on = true
        },
        async saveView () {
            this.$store.commit('loading', true)

            try {
                const id = await this.$api.users_tables.save(this.view.id, {
                    ...this.view.model,
                    model: this.modelName,
                    user: this.$api.auth.me.id
                })

                this.setViewFields(id)
                this.view.on = false

                this.$toast.success('Operazione completata')
            } catch (err) {
                throw new Error(err.message || err.name || err)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        async deleteView (id) {
            console.log('table deleteView()', id)

            this.$store.commit('loading', true)

            try {
                await this.$api.users_tables.remove(this.view.id)
                this.view.on = false
                let view_id = null

                if (this.view.items.length > 0) {
                    view_id = this.view.items[0].id
                }

                this.setViewFields(view_id)

                this.$toast.success('Operazione completata')
            } catch (err) {
                throw new Error(err.message || err.name || err)
            } finally {
                this.$store.commit('loading', false)
            }
        },
        // ------------------------------------------------------------------------------------------------
        getLabelFromLocalHeaders (k) {
            let label = k
            const ih = this.headers.find(_i => _i.value === k)

            if (ih && ih.text) label = ih.text
            else label = this.$t(k)

            return label
        },
        restartFields () {
            console.log('table restartFields')

            if (this.headers.length > 0) {
                this.selected_fields = this.headers.map(i => i.value)
            }
        },
        // filters
        clearItemFilterValue (item) {
            item.operator_index = 0
            item.operator = item.operators[0]
        },
        changeOperatorItemFiter (item) {
            console.log('table changeOperatorItemFiter')

            if (item.operator_index >= item.operators.length - 1) item.operator_index = 0
            else item.operator_index += 1

            this.handleInputFilter()
        },
        handleInputFilter (val) {
            console.log('table handleInputFilter', val)

            // const v_alt = {}
            // this.selected_fields.forEach((v) => {
            //     const field = this.local_schema[v]
            //     console.log('field', field, v)
            //     if (field && ('field.query_key' in this.computed_query_filters || v in this.computed_query_filters)) {
            //         v_alt[v] = this.computed_query_filters[field.query_key || v]
            //     }
            // })
            // localStorage.setItem((this.id || this.resource) + '.table_filters', JSON.stringify(v_alt))

            this.getItems()
        },
        // table
        compact () {
            this.classes['bxs-table-dense'] = !this.classes['bxs-table-dense']
            this.col.width = this.classes['bxs-table-dense'] ? '60px' : this.colWidth
            this.computed_headers = this.genHeaders()
        },
        clearFilters () {
            for (const k in this.computed_filters) {
                this.computed_filters[k].model = null
            }
        },
        // data
        async getItems() {
            this.loading = true
            console.log('table getItems', this.computed_query_filters)

            try {
                const docs = await dot.pick(this.resource, this.$api).list({
                    limit: this.options.itemsPerPage,
                    page: this.options.page,
                    sort: this.computed_sort,
                    ...this.staticQuery,
                    filters: {
                        ...this.computed_query_filters,
                        ...this.local_filters,
                        ...this.staticFilters
                    }
                })

                this.local_items = docs.docs || docs
                this.pagination.total = docs.pagination ? docs.pagination.total_docs : this.local_items.length

                return docs
            } catch (err) {
                throw new Error(err)
            } finally {
                this.loading = false
            }
        },
        async deleteDocs(ids, title, text) {
            try {
                this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                    title: title || 'Attenzione',
                    text: text || 'Vuoi eliminare questa risorsa?',
                    next: async () => {
                        this.loading = true
                        await dot.pick(this.resource, this.$api).remove(ids || this.computed_selected_ids)
                        this.loading = false
                        this.getItems()
                    }
                })
            } catch (err) {
                throw new Error(err.message || err.name)
            }
        },
        async duplicate (id) {
            this.loading = true

            try {
                await this.$api.duplicate(this.modelName, id)
                this.getItems()
            } catch (err) {
                throw new Error(err.message || err.name)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
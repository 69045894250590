<template>
    <v-row no-gutters>
        <v-col
        :cols="selectTime ? cols : 12"
        :class="{ 'pr-2': selectTime && cols < 12 }">
            <v-menu
            v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y>
                <template #activator="{ on, attrs }">
                    <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedVal"
                    :solo="solo"
                    :filled="filled"
                    :flat="flat"
                    :disabled="disabled"
                    :label="required ? '* ' + label : label"
                    :placeholder="placeholder"
                    :dense="dense"
                    :outlined="outlined"
                    :prepend-inner-icon="prependInnerIcon"
                    :hide-details="hideDetails"
                    readonly
                    clearable
                    :rules="computedRules"
                    @click:clear="clear">
                        <template #append>
                            <slot name="append" />
                        </template>
                    </v-text-field>
                </template>

                <v-date-picker
                v-model="cacheValue"
                :min="min"
                :max="max"
                no-title
                @input="handleInput" />
            </v-menu>
        </v-col>

        <v-col
        v-if="selectTime"
        :cols="cols">
            <v-menu
            ref="time"
            v-model="showTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y>
                <template #activator="{ on, attrs }">
                    <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="time"
                    :label="labelTime"
                    :solo="solo"
                    :filled="filled"
                    :flat="flat"
                    :outlined="outlined"
                    placeholder="Orario"
                    prepend-inner-icon="schedule"
                    readonly
                    :hide-details="hideDetails"
                    dense
                    clearable />
                </template>

                <v-time-picker
                v-if="showTime"
                v-model="time"
                :min="minTime"
                ampm-in-title
                format="24hr"
                @click:minute="$refs.time.save(time)"
                @change="handleInput" />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'bxs-date-field',
    props: {
        value: {
            type: [String, Date, Array],
            required: false,
            default: null
        },
        min: {
            type: [String, Date],
            required: false,
            default: null
        },
        max: {
            type: [String, Date],
            required: false,
            default: null
        },
        'min-time': {
            type: [String, Array],
            required: false,
            default: null
        },
        'prepend-inner-icon': {
            type: String,
            required: false,
            default: 'calendar_month'
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        'label-time': {
            type: String,
            required: false,
            default: 'Orario'
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        'select-time': {
            type: Boolean,
            required: false,
            default: false
        },
        cols: {
            type: [Number, String],
            required: false,
            default: 6
        },
        'value-type': {
            type: String,
            required: false,
            default: 'date',
            validator: v => [
                'schedule',
                'date'
            ].indexOf(v) !== -1
        }
    },
    computed: {
        formattedVal () {
            if (!this.cacheValue) return null

            let date = this.value

            if (this.valueType === 'schedule') {
                date = new Date(...this.value)
            }

            return this.$moment(date).format('l')
        },
        computedRules () {
            let val = [v => !!v || 'Campo richiesto']
            if (!this.required) val = []
            return val
        }
    },
    data () {
        return {
            show: false,
            cacheValue: this.value,
            showTime: false,
            time: null
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.start()
        })
    },
    methods: {
        start () {
            // this.handleInput()

            if (this.selectTime) {
                if (this.value) {
                    const d = this.$moment(this.value)
                    this.time = d.format('hh:mm')
                }
            }
        },
        handleInput () {
            this.show = false

            let val = this.$moment(new Date(this.cacheValue)).toDate()
            console.log('handleInput', val)

            if (this.valueType === 'schedule') {
                val = this.$moment(new Date(this.cacheValue)).format('YYYY-M-DD')
                val = val.split('-')

                val = [
                    Number(val[0]),
                    Number(val[1] - 1),
                    Number(val[2])
                ]

                if (this.time) {
                    val = [
                        ...val,
                        Number(this.time.split(':')[0]), Number(this.time.split(':')[1])
                    ]
                }
            } else {
                if (this.selectTime && this.time) {
                    val.setHours(Number(this.time.split(':')[0]))
                    val.setMinutes(Number(this.time.split(':')[1]))
                } else {
                    val.setHours(new Date().getHours())
                    val.setMinutes(new Date().getMinutes())
                }
            }

            this.$emit('input', val)
        },
        clear () {
            this.cacheValue = null
            this.$emit('input', null)
        }
    }
}
</script>
<template>
    <div :class="['qty-field-comp', {
        'qty-field-comp-hide-details': hideDetails,
        'qty-field-comp-block': block
    }]">
        <v-text-field
        ref="field"
        v-model.number="formattedValue"
        type="text"
        :step="step"
        :label="label"
        :placeholder="placeholder"
        :required="required"
        :rules="required ? [rules.required] : []"
        :persistent-hint="hint !== null"
        :hint="hint"
        :solo="solo"
        :suffix="suffix"
        :outlined="outlined"
        :name="name"
        :disabled="disabled"
        :single-line="singleLine"
        :filled="filled"
        :hide-details="hideDetails"
        :dense="dense"
        :flat="flat"
        :background-color="bgColor"
        @keyup="handleKeyPress"
        @input="handleInput"
        @change="handleChange">
            <template #append>
                <div
                v-if="!hideActions && !disabled"
                class="qty-field-comp--actions d-flex flex-nowrap align-center pa-1">
                    <v-icon
                    :disabled="formattedValue === max"
                    :class="['qty-field-comp-cta-icon', 'grey', 'lighten-3', { disabled: formattedValue === max }]"
                    @click.prevent.stop="increment">expand_less</v-icon>

                    <v-icon
                    :disabled="formattedValue === min"
                    :class="['qty-field-comp-cta-icon', 'grey', 'lighten-3', { disabled: formattedValue === min }]"
                    @click.prevent.stop="decrement">expand_more</v-icon>
                </div>
            </template>
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: 'quantity-field',
    props: {
        value: {
            type: Number,
            required: false,
            default: 0
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        min: {
            type: Number,
            required: false,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        hint: {
            type: String,
            required: false,
            default: null
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            default: null
        },
        'bg-color': {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        },
        'single-line': {
            type: Boolean,
            required: false,
            default: false
        },
        block: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-actions': {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        flat: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    model: {
        value: 'value',
        event: 'update-value'
    },
    data () {
        return {
            formattedValue: this.value,
            rules: {
                required: (v) => !!v || 'Campo richiesto'
            }
        }
    },
    created () {
        if (this.min !== null && this.formattedValue < this.min) {
            this.formattedValue = this.min
        }

        if (this.value === null) {
            this.formattedValue = 0
            this.updateValue()
        }
    },
    watch: {
        value (newVal, oldVal) {
            if (this.value !== null && newVal !== oldVal) {
                this.formattedValue = newVal
                if (this.min !== null && this.formattedValue < this.min) this.formattedValue = this.min
            }
        },
        min () {
            this.handleChange()
        },
        max () {
            this.handleChange()
        }
    },
    methods: {
        handleKeyPress (evt) {
            const followKeys = [8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]

            if (!followKeys.includes(evt.which)) return evt.preventDefault()

            if (evt.target.value && evt.target.value.length > 0) {
                evt.target.value = parseInt(evt.target.value)
            } else if (Math.sign(evt.target.value) < 0) {
                evt.target.value = 0
            }

            // this.updateValue()

            return true
        },
        handleChange () {
            this.updateValue()
            this.$emit('change', parseInt(this.formattedValue || 0))
        },
        handleInput () {
            this.$emit('input', parseInt(this.formattedValue || 0))
        },
        updateValue () {
            if (this.min !== null && this.formattedValue < this.min) this.formattedValue = this.min
            if (this.max !== null && this.formattedValue >= this.max) this.formattedValue = this.max

            // if (this.formattedValue)
            this.$emit('update-value', parseInt(this.formattedValue || 0))
        },
        increment () {
            if (this.disabled) return
            this.formattedValue += this.step
            this.handleChange()
        },
        decrement () {
            if (this.disabled) return
            this.formattedValue -= this.step
            this.handleChange()
        }
    }
}
</script>
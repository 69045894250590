<template>
    <div class="storage pa-0">
        <div
        v-if="!preloaded"
        class="pa-6 boxed">
            <loading />
        </div>

        <!-- content -->
        <div
        v-else
        class="storage--main">
            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                toolbar
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <div class="storage--header">
                <div
                v-if="!hideTitle"
                class="mute--text mb-0 mr-6">
                    <p>Storage</p>
                </div>

                <span class="mr-6">
                    <a
                    role="button"
                    text
                    class="info--text mb-0"
                    @click="selectAll">
                        <span>Seleziona tutto</span>
                        <span v-if="selected.length > 0" class="ml-1">({{ selected.length }})</span>
                    </a>

                    <a
                    role="button"
                    text
                    class="info--text mb-0 ml-4"
                    @click="deselectAll">
                        <span>Deseleziona tutto</span>
                    </a>

                    <a
                    v-if="selected_ids.length > 0"
                    role="button"
                    class="ml-4 mb-0 error--text"
                    @click="deleteDocs(selected_ids)">
                        <span>Elimina selezionati</span>
                    </a>
                </span>

                <v-spacer></v-spacer>

                <v-slide-x-reverse-transition>
                    <v-progress-circular
                    v-if="uploader.on"
                    :rotate="-90"
                    :size="50"
                    :width="6"
                    :value="uploader.progress"
                    color="success"
                    class="mr-2">{{ uploader.progress }}%</v-progress-circular>
                </v-slide-x-reverse-transition>

                <v-btn-toggle
                rounded
                class="mr-6">
                    <v-btn @click="$refs.uploader.click()">
                        <v-icon left>upload_file</v-icon>
                        <span>Carica</span>
                    </v-btn>

                    <!-- <v-btn
                    disabled
                    @click="view = 'table'">
                        <v-icon left>table_view</v-icon>
                        <span>Table view</span>
                    </v-btn> -->

                    <!-- <v-btn v-if="selected_ids.length > 0">
                        <v-icon left>public</v-icon>
                        <span>Pubblica</span>
                    </v-btn> -->

                    <!-- <v-btn v-if="selected_ids.length > 0">
                        <v-icon left>lock</v-icon>
                        <span>Nascondi</span>
                    </v-btn> -->

                    <!-- :color="selected_ids.length > 0 ? 'error' : null" -->
                    <v-btn
                    v-if="selected_ids.length > 0"
                    @click="deleteDocs(selected_ids)">
                        <v-icon left>delete</v-icon>
                        <span>Elimiina {{ selected_ids.length }} selezionati</span>
                    </v-btn>
                </v-btn-toggle>

                <v-spacer></v-spacer>

                <v-select
                v-model="selected_sort"
                :items="sort_items"
                label="Ordina per"
                hide-details
                single-line
                multiple
                solo
                dense
                flat
                :menu-props="{ top: false, bottom: true, offsetY: true }"
                class="ma-0 pa-0 mr-4"
                style="max-width: 200px;">
                    <template #selection="{ index }">
                        <v-chip v-if="!index" small>Ordine - {{ selected_sort.length }} selezionati</v-chip>
                    </template>
                </v-select>

                <search-field
                v-model="keywords"
                append-icon="search"
                label="Cerca"
                solo
                flat
                dense
                clearable
                hide-details
                single-line
                style="max-width: 240px"
                class="ma-0 pa-0" />

                <!-- <v-progress-linear
                v-if="uploader.on"
                :buffer-value="uploader.progress"
                color="success"
                stream
                value="0"
                absolute
                height="100%"
                class="disabled" /> -->

                <v-progress-linear
                v-if="items.is_loading"
                indeterminate
                absolute
                height="4"
                bottom />
            </div>

            <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                body
            ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
            <div
            ref="body"
            :class="['storage--body', {
                'storage--body-dragover': dragandrop.on,
                'storage--body-no-results': !items.pagination.total_docs
            }]"
            @dragover="handleDragAndDrop"
            @dragleave="handleDragAndDrop"
            @drop="handleDragAndDrop">
                <uploader
                ref="uploader"
                multiple
                class="hidden"
                @before-add="handleUploaderBeforeAdd"
                @completed="handleUploaderCompleted"
                @added="handleUploaderAdded" />

                <v-row
                v-if="!dragandrop.on"
                class="storage--body--row">
                    <v-col
                    ref="scroller"
                    :cols="expand.on && !hideDetails ? 8 : 12"
                    id="storage-scroller"
                    class="storage--body--row--col-scroller">
                        <!-- ------------------------------------------------------------------
                            sx
                        ------------------------------------------------------------------ -->
                        <div
                        v-if="!items.pagination.total_docs"
                        class="text-center">
                            <p class="mb-1">Nessun file trovato</p>
                            <p v-if="keywords"><span class="mb-1">per la ricerca <b>"{{ keywords }}"</b>,</span> prova a effettuare nuovamente la ricerca</p>
                        </div>

                        <draggable
                        v-else
                        v-model="items.docs"
                        tag="v-row"
                        ghost-class="draggable-ghost"
                        class="pa-4"
                        @start="is_dragging_item = true"
                        @end="is_dragging_item = false"
                        @change="handleChangePos">
                            <v-col
                            v-for="file in items.docs"
                            :key="file.id"
                            v-bind="cols"
                            class="mb-4">
                                <!--
                                    v-tooltip.top="'Clicca per selezionare il file'"
                                -->
                                <v-card
                                hover
                                link
                                class="overflow-hidden pa-0">
                                    <div class="d-flex flex-no-wrap">
                                        <!-- v-tooltip.left-start="'Clicca per espandere il file'" -->
                                        <v-avatar
                                        :size="!expand.on ? '120' : '100%'"
                                        tile
                                        @click="setExpand(file)">
                                            <!-- lazy-src="/img/infinite.gif" -->
                                            <v-img
                                            :src="file.url | toThumb"
                                            aspect-ratio="1"
                                            height="120" />
                                        </v-avatar>

                                        <div
                                        v-if="!expand.on"
                                        class="flex-1 truncate d-flex flex-column"
                                        @click="select(file)">
                                            <div
                                            class="text-right"
                                            style="position: absolute; top: 1px; right: 1px; z-index: 1;">
                                                <bxs-dot-menu>
                                                    <v-list-item dense>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ file.name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-divider></v-divider>

                                                    <v-list-item @click="setExpand(file)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>Apri</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>chevron_right</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-list-item @click="select(file)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ !selected_ids.includes(file.id) ? 'Seleziona' : 'Deseleziona' }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>{{ !selected_ids.includes(file.id) ? 'radio_button_checked' : 'radio_button_unchecked' }}</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-divider></v-divider>

                                                    <v-list-item @click="$api.files.modify(file.id, { status: file.status === 'public' ? 'private' : 'public'  }).then(() => file.status = file.status === 'public' ? 'private' : 'public')">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ file.status === 'public' ? 'Nascondi' : 'Pubblica' }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>{{ file.status === 'public' ? 'lock' : 'public' }}</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-list-item @click="fullscreen.on = true">
                                                        <v-list-item-content>
                                                            <v-list-item-title>Fullscreen view</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>fullscreen</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-list-item @click="clipboardUrl(file.url)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>Copia link alta definizione</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>link</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-list-item @click="share(file.id)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>Ricevi e copia link pubblico alta definizione</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>share</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-list-item
                                                    :href="`${$api.config.base_url}/v1/files/${file.id}/download`"
                                                    download>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Scarica alta definizione</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>download</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>

                                                    <v-list-item @click="deleteDocs(file.id)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>Elimina</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-icon>delete</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </bxs-dot-menu>
                                            </div>

                                            <div class="flex-1 pt-3 pl-4 pr-8">
                                                <v-card-subtitle class="mb-0 pa-0 truncate">{{ file.name }}</v-card-subtitle>

                                                <v-card-subtitle class="mb-0 pa-0 mute--text truncate">{{ file.size.human }} - {{ file.created_at | toHumanDate('l') }}<span
                                                v-if="file.dimension.w"
                                                class="mb-0 pa-0 mute--text">- {{ file.dimension.w }} x {{ file.dimension.h }}</span></v-card-subtitle>

                                                <v-card-subtitle
                                                v-if="file.tags.length"
                                                class="mb-0 pa-0 info--text">
                                                    <span
                                                    v-for="(v, i) in file.tags"
                                                    :key="i"
                                                    class="mr-1">#{{ v }}</span>
                                                </v-card-subtitle>
                                            </div>

                                            <!--  style="position: absolute; bottom: 5px; left: 0; z-index: 1;" -->
                                            <div class="d-flex px-4 pb-2">
                                                <v-icon
                                                v-tooltip="file.status === 'public' ? 'File pubblico' : 'File privato'"
                                                size="15"
                                                color="grey"
                                                class="mr-1">{{ file.status === 'public' ? 'public' : 'lock' }}</v-icon>

                                                <v-icon
                                                v-if="file.note"
                                                v-tooltip="file.note"
                                                size="15"
                                                color="grey"
                                                class="mr-1">notes</v-icon>

                                                <v-icon
                                                v-if="file.fotografo"
                                                v-tooltip="file.fotografo"
                                                size="15"
                                                color="grey"
                                                class="mr-1">photo_camera</v-icon>

                                                <v-icon
                                                v-if="file.luogo_scatto"
                                                v-tooltip="file.luogo_scatto"
                                                size="15"
                                                color="grey"
                                                class="mr-1">location_on</v-icon>

                                                <v-icon
                                                v-if="file.data_scatto"
                                                v-tooltip="file.data_scatto"
                                                size="15"
                                                color="grey"
                                                class="mr-1">calendar_month</v-icon>

                                                <v-spacer></v-spacer>

                                                <v-icon
                                                v-if="selected_ids.includes(file.id)"
                                                v-tooltip="'File selezionato'"
                                                size="18"
                                                color="info">{{ 'radio_button_checked' }}</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </draggable>

                        <div
                        v-if="!items.is_busy && items.pagination.has_next_page"
                        class="text-center py-6">
                            <div>
                                <small>... loading ...</small>
                            </div>

                            <v-btn
                            v-if="items.pagination.has_next_page"
                            text
                            small
                            @click="getMoreItems(items.pagination.next_page)">load more</v-btn>
                        </div>
                    </v-col>

                    <!-- ------------------------------------------------------------------
                        dx
                    ------------------------------------------------------------------ -->
                    <v-col
                    v-if="expand.on && !hideDetails"
                    class="storage--body--row--col-detail"
                    cols="4">
                        <div class="d-flex pa-4 white sticky">
                            <v-btn
                            fab
                            x-small
                            @click="$api.files.modify(expand.item.id, { status: expand.item.status === 'public' ? 'private' : 'public' }).then(() => expand.item.status = expand.item.status === 'public' ? 'private' : 'public')">
                                <v-icon
                                v-tooltip="expand.item.status === 'public' ? 'File pubblico' : 'File privato'"
                                color="grey">{{ expand.item.status === 'public' ? 'public' : 'lock' }}</v-icon>
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                            v-tooltip="'Elimina'"
                            fab
                            x-small
                            class="mr-2"
                            @click="deleteDocs(expand.item.id)">
                                <v-icon>delete</v-icon>
                            </v-btn>

                            <v-btn
                            v-tooltip="'Fullscreen view'"
                            fab
                            x-small
                            class="mr-2"
                            @click="fullscreen.on = true">
                                <v-icon>fullscreen</v-icon>
                            </v-btn>

                            <v-btn
                            v-tooltip="'Copia link alta definizione'"
                            fab
                            x-small
                            class="mr-2"
                            @click="clipboardUrl(expand.item.url)">
                                <v-icon>link</v-icon>
                            </v-btn>

                            <v-btn
                            v-tooltip="'Ricevi e copia link pubblico alta definizione'"
                            fab
                            x-small
                            class="mr-2"
                            @click="share(expand.item.id)">
                                <v-icon>share</v-icon>
                            </v-btn>

                            <v-btn
                            v-tooltip="'Scarica alta definizione'"
                            fab
                            x-small
                            :href="`${$api.config.base_url}/v1/files/${expand.item.id}/download`"
                            download
                            class="mr-2">
                                <v-icon>download</v-icon>
                            </v-btn>

                            <v-btn
                            v-tooltip="'Elimina'"
                            fab
                            x-small
                            @click="setExpand(null, false)">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>

                        <!--  -->
                        <v-slide-x-reverse-transition mode="out-in">
                            <img
                            :key="expand.item.id"
                            :src="expand.item.url | toThumb(1024)"
                            class="px-4"
                            style="width: 100%; max-width: 100%; height: auto;" />
                        </v-slide-x-reverse-transition>

                        <div class="pa-4">
                            <v-text-field
                            v-model="expand.item.name"
                            outlined
                            dense
                            hide-details
                            label="Nome file"
                            @change="$api.files.modify(expand.item.id, { name: $event })" />

                            <v-combobox
                            v-model="expand.item.tags"
                            label="Tags"
                            outlined
                            dense
                            chips
                            small-chips
                            deletable-chips
                            hide-details
                            multiple
                            @change="$api.files.modify(expand.item.id, { tags: $event })" />

                            <!-- <v-select
                            v-model="expand.item.types"
                            :items="$api.enums.file.types"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            outlined
                            dense
                            label="Tipologia"
                            @change="$api.files.modify(expand.item.id, { types: $event })" /> -->

                            <v-combobox
                            v-model="expand.item.fotografo"
                            :items="$api.enums.fotografi"
                            outlined
                            dense
                            label="Fotografo"
                            @change="$api.files.modify(expand.item.id, { fotografo: $event })" />

                            <v-combobox
                            v-model="expand.item.luogo_scatto"
                            :items="$api.enums.luoghi_scatto"
                            outlined
                            dense
                            label="Luogo scatto"
                            @change="$api.files.modify(expand.item.id, { luogo_scatto: $event })" />

                            <v-text-field
                            v-model="expand.item.data_scatto"
                            outlined
                            dense
                            label="Data scatto"
                            @input="$api.files.modify(expand.item.id, { data_scatto: $event })" />

                            <v-text-field
                            v-model="expand.item.info"
                            outlined
                            dense
                            label="Info"
                            @change="$api.files.modify(expand.item.id, { info: $event })" />

                            <v-textarea
                            v-model="expand.item.note"
                            outlined
                            dense
                            label="Note"
                            hide-details
                            @change="$api.files.modify(expand.item.id, { note: $event })" />
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="storage--footer d-flex align-center">
                <!-- <v-spacer></v-spacer>

                <v-alert
                type="info"
                dense
                text
                border="left"
                class="ma-0">Attendi la fine del caricamento dei file prima di cambiare pagina</v-alert>

                <v-spacer></v-spacer> -->

                <p>{{ items.docs.length }} / {{ items.pagination.total_docs }}</p>
            </div>

            <!--  -->
            <bxs-modal
            v-if="expand.item"
            v-model="fullscreen.on"
            :title="expand.item.name"
            fullscreen
            transition="dialog-top-transition">
                <img
                :src="expand.item.url"
                style="width: 100%; max-width: 100%; height: auto;" />
            </bxs-modal>
        </div>
    </div>
</template>

<script>
// xs   < 600px
// sm   600px > < 960px
// md   960px > < 1264px
// lg   1264px > < 1904px
// xl   > 1904px

export default {
    name: 'storage',
    props: {
        value: {
            type: [String, Array],
            required: false,
            default: null
        },
        'item-value': {
            type: String,
            required: false,
            default: 'id'
        },
        'return-object': {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-title': {
            type: Boolean,
            required: false,
            default: false
        },
        cols: {
            type: Object,
            required: false,
            default: () => {
                return {
                    cols: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                    xl: 2
                }
            }
        },
        limit: {
            type: [String, Number],
            required: false,
            default: 30
        },
        'hide-details': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    metaInfo: {
      title: 'Storage'
    },
    data () {
        return {
            expand: {
                on: false,
                item: null
            },
            fullscreen: {
                on: false
            },
            uploader: {
                on: false,
                progress: 0
            },
            items: {
                docs: [],
                is_loading: false,
                is_busy: false,
                pagination: {
                    page: 1,
                    limit: this.limit,
                    totalPages: 0
                }
            },
            dragandrop: {
                on: false
            },
            is_dragging_item: false,
            view: null,
            sort_items: [
                { text: 'Nome file A-Z', value: 'name:1' },
                { text: 'Nome file Z-A', value: 'name:-1' },
                { text: 'Data caricamento', value: 'created_at:-1' },
                { text: 'Data ultimo aggiormaneto', value: 'updated_at:-1' },
                { text: 'Posizione', value: 'positions.storage:-1' }
            ],
            selected_sort: ['positions.storage:-1', 'created_at:-1'],
            selected: [],
            preloaded: false,
            loading: false,
            keywords: null
        }
    },
    computed: {
        selected_ids () {
            return this.selected.map(i => i.id)
        },
        query_sort () {
            const v = {}

            this.selected_sort.forEach((s) => {
                const _s = s.split(':')
                v[_s[0]] = _s[1]
            })

            return v
        }
    },
    mounted () {
        this.start()
    },
    watch: {
        keywords () {
            this.getItems()
        },
        query_sort (newVal) {
            if (Object.keys(newVal).length > 0) {
                this.getItems()
            }
        }
    },
    methods: {
        // --------------------------------------------------------------------------------------------------------------------------------------------
        async start () {
            await this.getItems()
            this.preloaded = true

            this.$nextTick(() => {
                this.scroller_el = this.$refs.scroller
                this.scroller_el.addEventListener('scroll', this.handleScroll)
                this.resize()
            })
        },
        stop () {
            this.scroller_el.removeEventListener('scroll', this.handleScroll)
        },
        resize () {
            this.handleScroll()
        },
        // handlers --------------------------------------------------------------------------------------------------------------------------------------------
        handleScroll () {
            const cond = this.scroller_el.scrollTop + this.scroller_el.clientHeight >= (this.scroller_el.scrollHeight / 1.5)

            if (cond) {
                if (this.items.pagination.has_next_page && !this.items.is_busy) {
                    this.getMoreItems(this.items.pagination.page += 1)
                }
            }

            return cond
        },
        handleUploaderBeforeAdd () {
            this.uploader.on = true
            this.$toast.info('Attendi il completamento del caricamento prima di chiudere o ricaricare la pagina')
        },
        handleUploaderCompleted () {
            console.log('storage handleUploaderCompleted()')

            this.uploader.on = false
            this.uploader.progress = 0
            this.getItems()
        },
        handleUploaderAdded (item, progress) {
            console.log('storage handleUploaderAdded()', item, progress)

            this.uploader.progress = progress
        },
        handleChangePos ({ moved }) {
            this.$api.files.modify(moved.element.id, { 'positions.storage': moved.newIndex + 1 })
        },
        handleDragAndDrop (evt) {
            if (this.is_dragging_item) return

            evt.preventDefault()

            console.log(evt.type)

            this.dragandrop.on = evt.type === 'dragover'

            if (evt.type === 'drop') {
                if (evt.dataTransfer.files.length > 0) {
                    this.$refs.uploader._handleFileInput({ target: { files: evt.dataTransfer.files } })
                }
            }
        },
        // actions --------------------------------------------------------------------------------------------------------------------------------------------
        setExpand (item, on = true) {
            // if (!!this.expand.item && this.expand.item.id === item.id) {
            //     on = false
            // }

            this.expand.on = on
            this.expand.item = item || null
        },
        select (item) {
            if (this.selected_ids.includes(item.id)) {
                const i = this.selected.findIndex(i => i.id === item.id)
                this.selected.splice(i, 1)
            } else {
                this.selected.push(
                    JSON.parse(JSON.stringify(
                        item
                    ))
                )
            }
        },
        selectAll () {
            this.items.docs.forEach(this.select)
        },
        deselectAll () {
            this.selected = []
        },
        // handleCtxMenu (file, evt) {
        //     evt.preventDefault()
        //     alert('success!')
        //     return false
        // },
        async share (id) {
            this.$store.commit('loading', true)
            const url = await this.$api.files.share(id)
            this.$store.commit('loading', false)
            this.clipboardUrl(url)
        },
        clipboardUrl (val) {
            navigator.clipboard.writeText(val)
            this.$toast.success('Url copiato')
        },
        // http --------------------------------------------------------------------------------------------------------------------------------------------
        async getItems (page) {
            this.items.is_loading = true
            this.items.pagination.page = 1

            const {
                pagination,
                docs
            } = await this.$api.files.list({
                page: page || this.items.pagination.page,
                limit: this.items.pagination.limit,
                spread: ['works'],
                sort: this.query_sort,
                filters: {
                    keywords: this.keywords || null
                }
            })

            this.items.pagination = pagination
            this.items.docs = docs
            this.items.is_loading = false

            if (this.preloaded) {
                this.handleScroll()
            }
        },
        async getMoreItems (page) {
            this.items.is_loading = true
            this.items.is_busy = true

            const {
                pagination,
                docs
            } = await this.$api.files.list({
                page: page || this.items.pagination.page,
                limit: this.items.pagination.limit,
                spread: ['works'],
                sort: this.query_sort,
                filters: {
                    keywords: this.keywords || null
                }
            })

            this.items.pagination = pagination
            this.items.docs.push(...docs)

            this.items.is_loading = false
            this.items.is_busy = false
        },
        async deleteDocs(ids, title, text) {
            try {
                this.$store.commit('SET_APP_DIALOG_CONFIRM', {
                    title: title || 'Attenzione!',
                    text: text || 'Vuoi eliminare questa risorsa?',
                    next: async () => {
                        this.$store.commit('loading', true)
                        this.items.is_loading = true
                        await this.$api.files.remove(ids)
                        this.items.is_loading = false
                        this.$store.commit('loading', false)

                        this.selected = []
                        this.expand.on = false

                        this.getItems()
                    }
                })
            } catch (err) {
                console.error(err)
                alert(err.message || err.name)
            }
        }
    },
    beforeDestroy () {
        this.stop()
    }
}
</script>
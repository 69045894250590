<template>
    <div>
        <div
        v-if="!hideLabel"
        class="text-center mb-2">
            <p>Elaborazione</p>
        </div>

        <div>
            <v-progress-linear
            indeterminate
            height="6" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'bxs-loading',
    props: {
        'hide-label': {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
<template>
    <v-btn
    v-on="$listeners"
    v-bind="$attrs"
    v-tooltip="{
        content: tooltip,
        placement: 'top',
        delay: {
            show: 200,
            hide: 0
        }
    }"

    :block="block"
    :to="to"
    :fab="fab"
    :icon="icon"
    :tile="tile"
    depressed
    :height="height"
    :text="text"
    :disabled="disabled"
    :dark="dark"
    :light="light"
    :width="width"
    :min-height="minHeight"
    :min-width="minWidth"
    :small="small"
    :x-small="xSmall"
    :color="color"
    elevation="0">
        <slot />
    </v-btn>
</template>

<script>
export default {
    name: 'bxs-btn',
    props: {
        'tooltip-props': {
            type: Object,
            required: false,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltip: {
            type: String,
            required: false,
            default: null
        },
        block: {
            type: Boolean,
            required: false,
            default: false
        },
        fab: {
            type: Boolean,
            required: false,
            default: false
        },
        tile: {
            type: Boolean,
            required: false,
            default: false
        },
        icon: {
            type: Boolean,
            required: false,
            default: false
        },
        light: {
            type: Boolean,
            required: false,
            default: false
        },
        dark: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: [String, Object],
            required: false,
            default: null
        },
        height: {
            type: [String, Number],
            required: false,
            default: null
        },
        width: {
            type: [String, Number],
            required: false,
            default: null
        },
        'min-height': {
            type: [String, Number],
            required: false,
            default: null
        },
        'min-width': {
            type: [String, Number],
            required: false,
            default: null
        },
        text: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: null
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        'x-small': {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'

import Api from './assets/libs/Api'
import translate from './assets/libs/translate'
import './components'
import './filters'

const api = new Api({
    app_id: 'laci',
    prefix: 'fpl'
})

window.$api = api
Vue.use(VueMeta)
Vue.prototype.$api = api
Vue.prototype.$t = translate
Vue.prototype.$eventHub = new Vue()
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
<template>
    <v-app
    sticky-container
    :style="{ backgroundColor: $vuetify.theme.themes[$store.state.theme].background }">
        <c-navbar v-if="preloaded && !!$api.auth.me && $route.name !== 'signin'" />

        <c-sidebar v-if="preloaded && !!$api.auth.me && $route.name !== 'signin'" />

        <!--  -->
        <v-main>
            <v-container :class="{
                'container-signin': $route.name === 'signin' || $route.name === 'signin_recovery' || $route.name === 'signin_reset',
                'container-storage': $route.name === 'storage'
            }">
                <router-view
                v-if="preloaded"
                :key="$route.fullPath"
                class="app--page"
                @signin="start" />
            </v-container>
        </v-main>

        <!-- signin -->
        <bxs-modal
        v-if="preloaded && $api.auth.me"
        v-model="sign.on"
        persistent
        hide-close
        body-height="280px">
            <template #title>
                <h2 class="mb-1">Autenticazione scaduta!</h2>
                <p class="mb-0">Conferma la tua identità o ricarica la pagina</p>
            </template>

            <div class="py-8">
                <div class="d-flex flex-nowrap align-center mb-6">
                    <v-avatar
                    color="primary"
                    size="40"
                    class="text-uppercase white--text">
                        <v-img v-if="$api.auth.me.img" :src="$api.auth.me.img" />
                        <span v-else>{{ $api.auth.me ? $api.auth.me.email[0] : '' }}</span>
                    </v-avatar>

                    <span class="ml-2">{{ $api.auth.me.name }} - {{ $api.auth.me.role }} {{ $api.auth.me.level }}</span>
                </div>

                <v-text-field
                v-model="sign.email"
                :disabled="sign.loading"
                dense
                autofocus
                outlined
                label="Email" />

                <v-text-field
                v-model="sign.password"
                :disabled="sign.loading"
                dense
                outlined
                autofocus
                label="Password"
                :append-icon="showPass ? 'visibility_off' : 'visibility'"
                :type="showPass ? 'text' : 'password'"
                @click:append="sign.show_pwd = !sign.show_pwd"/>

                <v-alert
                v-if="sign.error"
                border="left"
                type="error"
                dense text>{{ sign.error }}</v-alert>
            </div>

            <template #actions>
                <v-spacer></v-spacer>

                <v-btn
                :disabled="sign.loading"
                color="primary"
                @click="signin">Accedi</v-btn>
            </template>
        </bxs-modal>

        <!-- LOADING -->
        <v-overlay
        :value="loading || !preloaded"
        z-index="9999"
        opacity="0.8">
            <v-row
            class="fill-height"
            align-content="center"
            no-gutters
            justify="center">
                <v-col
                class="mb-2"
                cols="12">
                    <p>Elaborazione</p>
                </v-col>

                <v-col cols="6">
                    <v-progress-linear
                    indeterminate
                    height="6" />
                </v-col>
            </v-row>
        </v-overlay>

        <!-- DIALOG CONFIRM -->
        <bxs-modal
        v-model="confirm.show"
        :title="confirm.title"
        hide-close
        body-height="160px">
            <div
            v-if="confirm.text"
            v-html="confirm.text"
            class="pa-4"
            style="font-size: 16px !important;"></div>

            <template #actions>
                <v-spacer></v-spacer>

                <v-btn
                :color="confirm.btnNextColor"
                @click="handleDialogConfirm(true)">{{ confirm.btnNextText }}</v-btn>

                <v-btn
                :color="confirm.btnUndoColor"
                @click="handleDialogConfirm(false)">{{ confirm.btnUndoText }}</v-btn>
            </template>
        </bxs-modal>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
    name: 'App',
    components: {
        'c-navbar': Navbar,
        'c-sidebar': Sidebar
    },
    computed: {
        ...mapState({
            preloaded: (state) => state.preloaded,
            loading: (state) => state.loading,
            confirm: (state) => state.dialog
        })
    },
    errorCaptured (err) {
        console.error('errorCaptured', err)

        if (this.$route.name !== 'signin' && err) {
            this.$toast.error(
                String(err.message || err.code || err.name || err || 'Ops, qualcosa è andato storto, riprova tra poco o contatta l\'assistenza')
            )
        }

        return false
    },
    data () {
        return {
            sign: {
                on: false,
                email: null,
                password: null,
                loading: false,
                error: null
            },
            showPass: false
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            console.log(this.$api)
            console.log(this.$vuetify)

            window.addEventListener('resize', () => {
                this.$store.commit('set_app_is_mobile')
                this.$store.commit('set_app_is_touch_device')
            })

            await this.$api.auth.getAccess()

            if (this.$api.auth.me) {
                await this.$api.getEnums()
                await this.$router.push(localStorage.getItem('fp-app_last_page') || '/').catch(() => {})
                this.addEvents()
            }

            this.$store.commit('SET_APP_PRELOADED', true)
        },
        async signin () {
            console.log('App signin()')

            this.sign.loading = true
            this.sign.error = null
            this.$store.commit('loading', true)

            try {
                await this.$api.auth.signInWithEmailAndPassword(
                    this.sign.email,
                    this.sign.password
                )
                await this.$api.auth.getAccess()
                await this.getEnums()
                this.sign.on = false
            } catch (err) {
                this.sign.error = err.message || err.name
            } finally {
                this.sign.loading = false
                this.$store.commit('loading', false)
            }
        },
        addEvents () {
            // this.$api.activityWatcher((is_inactivity) => {
            //     if (is_inactivity) {
            //         this.sign.on = true
            //     }
            // })

            this.$api.on('change-connection', (evt, val) => {
                const condition = navigator.onLine ? 'bxs-app-online' : 'bxs-app-offline'
                document.body.classList.toggle(condition)

                if (condition === 'bxs-app-online') {
                    this.$toast.success('Sei online', { timeout: 12000 })
                } else {
                    this.$toast.error('Sei offline. Le modifiche apportate ora non verranno salvate.', { timeout: 12000 })
                }
            })

            this.$api.on('signin', (evt, token) => {
                console.log('App on signin')
                this.sign.on = false
            })

            this.$api.on('access-token-refreshing', (evt) => {
                console.log('App on access-token-refreshing')
                this.$store.commit('loading', true)
            })

            this.$api.on('access-token-refreshed', (evt, token) => {
                console.log('App on access-token-refreshed')
                this.$store.commit('loading', false)
            })

            this.$api.on('access-token-expired', (evt, val) => {
                console.log('App on access-token-expired')
                this.sign.email = this.$api.auth.me.email
                this.sign.on = true
                this.$store.commit('loading', false)
            })

            // this.$api.on('permission-denied', (evt, val) => {
            //     alert('PERMESSO NEGATO!')
            //     this.$store.commit('loading', true)
            //     location.reload()
            // })

            this.$api.on([
                'http-post-success',
                'http-put-success',
                'http-patch-success',
                'http-delete-success'
            ], (evt, val) => {
                // console.log('App on', evt, val)
                if (!val) return

                if (
                    val.config.url === 'v1/auths/access/refresh' ||
                    val.config.url === 'v1/auths/signout' ||
                    val.config.url === 'v1/auths/signin' ||
                    (evt === 'http-post-success' && val.config.url === '/v1/files')
                ) {
                    return
                }

                this.$toast.success('Operazione completata')
            })
        },
        handleDialogConfirm (value) {
            if (value === true && this.confirm.next && typeof this.confirm.next === 'function') {
                this.confirm.next()
            }

            if (value === false && this.confirm.undo && typeof this.confirm.undo === 'function') {
                this.confirm.undo()
            }

            this.$store.commit('CLEAR_APP_DIALOG_CONFIRM')
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/app.scss';
</style>
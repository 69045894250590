<template>
    <!-- color="rgba(0, 0, 0, 0)" -->
    <v-navigation-drawer
    v-model="$store.state.is_open_menu"
    app
    :color="$vuetify.theme.themes[$store.state.theme].aside"
    clipped
    floating
    permanent
    width="240"
    class="app--sidebar">
        <!-- <template #prepend>
            <v-list-item
            style="height: 60px;"
            class="my-0 py-0">
                <v-avatar
                size="30"
                color="grey"
                class="mr-4 white--text">{{ $api.auth.me.name.first[0] }}</v-avatar>

                <v-list-item-content class="truncated">
                    <v-list-item-title class="mb-0">{{ $api.auth.me.name.first }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $api.auth.me.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-divider class="ma-0 pa-0"></v-divider> -->

        <v-list
        nav
        dense>
            <v-list-item-group
            v-model="selected"
            color="primary">
                <!-- home -->
                <v-list-item
                dense
                to="/">
                    <v-list-item-icon>
                        <v-icon>dashboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                exact
                dense
                to="/works"
                color="primary">
                    <v-list-item-icon>
                        <v-icon>category</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Opere</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- products -->
                <v-list-item
                exact
                dense
                to="/movs"
                color="primary">
                    <v-list-item-icon>
                        <v-icon>trolley</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Movimenti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item
                exact
                to="/users"
                color="primary">
                    <v-list-item-icon>
                        <v-icon>home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Utenti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->

                <v-list-item
                exact
                dense
                to="/contacts"
                color="primary">
                    <v-list-item-icon>
                        <v-icon>contacts</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Contatti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                exact
                dense
                to="/storage"
                color="primary">
                    <v-list-item-icon>
                        <v-icon>cloud_circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Storage</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- history -->
                <v-list-item
                v-if="$api.auth.is_dev"
                to="/history"
                dense
                exact>
                    <v-list-item-icon>
                        <v-icon>history</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Logs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                to="/settings"
                exact>
                    <v-list-item-icon>
                        <v-icon>settings</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Impostazioni</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'sidebar',
    data () {
        return {
            selected: null
        }
    }
}
</script>
import Auth from './Auth'
import Work from './Work'
import File from './File'
import User from './User'
import UserTable from './UserTable'
import Contact from './Contact'
import Mov from './Mov'
import History from './History'
import Setting from './Setting'

export const resources = {
    auth: {
        v: 'v1',
        path: 'auths',
        class: Auth
    },
    works: {
        v: 'v1',
        path: 'works',
        class: Work
    },
    files: {
        v: 'v1',
        path: 'files',
        class: File
    },
    users: {
        v: 'v1',
        path: 'users',
        class: User
    },
    users_tables: {
        v: 'v1',
        path: 'users_tables',
        class: UserTable
    },
    contacts: {
        v: 'v1',
        path: 'contacts',
        class: Contact
    },
    movs: {
        v: 'v1',
        path: 'movs',
        class: Mov
    },
    history: {
        v: 'v1',
        path: 'history',
        class: History
    },
    settings: {
        v: 'v1',
        path: 'settings',
        class: Setting
    }
}

export function genResources(config, tools) {
    for (const key in resources) {
        const resource = resources[key]
        const Class = resource.class
        resources[key] = new Class(config, tools, resource.v, resource.path)
    }

    return resources
}